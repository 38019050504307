<template lang="pug">
    .row
        .col-xs-12
            OptiTaskDetailsOffer(v-if="!!offer"
                :offer="offer"
                :task="task"
                :rate-save="task.rate_save"

                :rate="task.rate"

                @update-offer="o => $emit('update-offer', o)"
                @refresh="incrementUp"
                @reload-history="$emit('register-change')"
                @rate-save="$emit('rate-save')")

            h4.page-header(style="margin-top:0px")
                .row(style="display:flex;align-items:flex-end")
                    .col-xs-5
                        span.mr3 {{'tasks.FORPATIENT'|translate}}
                        a.link(:title="task.patientName" @click="show_patient_name = !show_patient_name") \#{{task.patientId}}
                        OptiPatient.ml3(v-show="show_patient_name" :data="task.patient")

                    .col-xs-2.text-center
                        OptiTaskIcon(v-for="(bool, attribute) in task.attributes" :key="attribute"
                            :name="attribute" :sc-id="task.fromServiceContract.id")
                        OptiTaskIcon(v-show="task.hasServiceContract" name="sc")
                        OptiTaskIcon(v-show="task.packId" name="pakiet")
                        OptiTaskIcon(v-show="task.satisfaction_guarantee" name="satisfaction")
                        OptiTaskIcon(style="font-size:22px" v-show="notes_length" name="notes")
                        OptiTaskIcon(style="font-size:22px" v-show="task.product.type === 0" name="sun")
                        i.fa.fa-crop.warn(style="font-size:22px" :title="'tasks.attributes.NOSHAPE'|translate"
                            v-show="task.dbl === 0")

                    .col-xs-2.text-center(style="padding-bottom:4px;font-size:14px")
                        slot

                    .col-xs-3.text-right
                        span {{'tasks.breadcrumb.' + (!!offer ? 'OFFER_TASK' : 'TASK')|translate}} \#{{task.id}}
                        small.text-muted(style="display:block") {{tab[tabActive]|translate}}

        div(style="padding-right:10px;padding-left:10px"
            :class="{'col-xs-9': tabActive !== 'summary', 'col-lg-10': tabActive !== 'summary', 'col-xs-12': tabActive === 'summary'}")
            nav(style="position:relative" :class="{'is-offer': !!offer}")
                ul.nav.nav-tabs.nav-justified.icons-header-tab
                    li(:class="{'active': tabActive === 'summary'}" v-if="showSummary")
                        a(:title="tab.summary|translate" @click="setActive('summary')")
                            i.visible-xs-inline.fa-lg.fa.fa-sliders
                            i.hidden-xs.fa.fa-sliders
                            span.visible-xs-inline.tab-title {{tab.summary|translate}}
                    li(:class="{'active': tabActive === 'frame'}")
                        a(:title="tab.frame|translate" @click="setActive('frame')")
                            i.visible-xs-inline.fa-lg.fa.fa-crop
                            i.hidden-xs.fa.fa-crop
                            span.visible-xs-inline.tab-title {{tab.frame|translate}}
                    li.lenses-main(:class="{'active': tabActive === 'lenses'}")
                        a(:title="tab.lenses|translate" @click="setActive('lenses')")
                            i.visible-xs-inline.fa-lg.fa.fa-eye
                            i.hidden-xs.fa.fa-eye
                            span.visible-xs-inline.tab-title {{tab.lenses|translate}}
                            i.fa.fa-star.ml3(style="color:orange;font-size:20px" v-if="!!offer")
                    li(:class="{'active': tabActive === 'lenses_to_compare'}" v-if="!!offer")
                        a(:title="tab.lenses_to_compare|translate" @click="setActive('lenses_to_compare')")
                            i.visible-xs-inline.fa-lg.fa.fa-eye
                            i.hidden-xs.fa.fa-eye
                            span.visible-xs-inline.tab-title {{tab.lenses_to_compare|translate}}
                    li(:class="{'active': tabActive === 'payment'}")
                        a(:title="tab.payment|translate" @click="setActive('payment')")
                            i.visible-xs-inline.fa-lg.fa.fa-money
                            i.hidden-xs.fa.fa-money
                            span.visible-xs-inline.tab-title {{tab.payment|translate}}
                    li(:class="{'active': tabActive === 'previouslenses'}")
                        a(:title="tab.previouslenses|translate" @click="setActive('previouslenses')")
                            i.visible-xs-inline.fa-lg.fa.fa-history
                            i.hidden-xs.fa.fa-history
                            span.visible-xs-inline.tab-title {{tab.previouslenses|translate}}
                            span.badge.ml3(v-show="task.patient.previouslenses.length + task.patient.contactlenses.length"
                                ) {{task.patient.previouslenses.length + task.patient.contactlenses.length}}
                    li.ipad(:class="{'active': tabActive === 'ipad'}")
                        a(:title="tab.ipad|translate" @click="setActive('ipad')")
                            i.visible-xs-inline.fa-lg.fa.fa-tablet
                            i.hidden-xs.fa.fa-tablet
                            span.visible-xs-inline.tab-title {{tab.ipad|translate}}
                            i.fa.fa-star.ml3(style="color:orange;font-size:20px" v-if="!!offer")
                    li(:class="{'active': tabActive === 'history'}")
                        a(:title="tab.history|translate" @click="setActive('history')")
                            i.visible-xs-inline.fa-lg.fa.fa-book
                            i.hidden-xs.fa.fa-book
                            span.visible-xs-inline.tab-title {{tab.history|translate}}
                            i.fa.fa-exclamation-circle.text-warning.ml3(style="font-size:25px" v-show="changeLogs.info")
                    li(:class="{'active': tabActive === 'notes'}")
                        a(:title="tab.notes|translate" @click="setActive('notes')")
                            i.visible-xs-inline.fa-lg.fa.fa-comments
                            i.hidden-xs.fa.fa-comments
                            span.visible-xs-inline.tab-title {{tab.notes|translate}}
                            span.badge.ml3(v-show="notes_length") {{notes_length}}

                .tab-content
                    OptiTaskDetailsSummary.tab-pane(:class="{'active': tabActive === 'summary'}"
                        :task="task"
                        :rate-save="rateSave"
                        :offer="offer"

                        :task-duplicate="taskDuplicate"
                        :task-service="taskService"
                        :increment="increment"
                        :saturation-translate="saturationTranslate"
                        :task-extra-us-info="taskExtraUsInfo"
                        :discount-percent-with-shipping="discountPercentWithShipping"
                        :promotions="promotions"
                        :notice="notice"
                        :valid="valid"

                        :block="block"
                        :allow-edit-confirmed="allowEditConfirmed"

                        @set-active="setActive"
                        @go-done="goDone"
                        @summary-was-updated="$emit('save')"
                        @rate-save="$emit('rate-save')")

                    OptiTaskDetailsFrame.tab-pane(:class="{'active': tabActive === 'frame'}"
                        :task="task"
                        :is-offer="!!offer"

                        :shape="shape"

                        :put-back="putBack"
                        :for-sc="forSc"
                        :task-duplicate="taskDuplicate"
                        :task-service="taskService"

                        :block="block"
                        :pending="pending"
                        :allow-edit-confirmed="allowEditConfirmed"

                        @frame-was-updated="$emit('save')")

                    OptiTaskDetailsLenses.tab-pane(:class="{'active': tabActive === 'lenses'}"
                        :task="task"
                        :is-offer="!!offer"
                        :offer-hoya-option="offerHoyaOption"
                        :shape="shape"
                        :increment="increment"

                        :block="block"
                        :allow-edit-confirmed="allowEditConfirmed"
                        :pending="pending"

                        @pending="p => $emit('pending', p)"
                        @increment-up="incrementUp"
                        @register-change="$emit('register-change')"
                        @frame-overwrite-was-updated="p => $emit('frame-overwrite-was-updated', p)"
                        @lenses-was-updated="$emit('save')")

                    OptiTaskDetailsLenses.tab-pane(:class="{'active': tabActive === 'lenses_to_compare'}"
                        v-if="!!offer"

                        :task="task"
                        :is-offer="!!offer"
                        :is-compare="true"
                        :offer-hoya-option="offerHoyaOption"
                        :shape="shape"
                        :increment="increment"

                        :block="block"
                        :allow-edit-confirmed="allowEditConfirmed"
                        :pending="pending"

                        @pending="p => $emit('pending', p)"
                        @increment-up="incrementUp"
                        @register-change="$emit('register-change')"
                        @frame-overwrite-was-updated="p => $emit('frame-overwrite-was-updated', p)"
                        @lenses-was-updated="$emit('save')")

                    OptiTaskDetailsPayment.tab-pane(:class="{'active': tabActive === 'payment'}"
                        :task="task"
                        :is-offer="!!offer"

                        :promotions="promotions"
                        :notice="notice"
                        :valid="valid"
                        :saturation-translate="saturationTranslate"
                        :task-extra-us-info="taskExtraUsInfo"
                        :discount-percent-with-shipping="discountPercentWithShipping"

                        :put-back="putBack"
                        :for-sc="forSc"

                        :block="block"
                        :pending="pending"

                        @invalid="setValid"
                        @pending="p => $emit('update-payment-pending', p)"

                        @go-done="goDone"
                        @payment-was-updated="$emit('save')")

                    OptiPreviousLenses.tab-pane(:class="{'active': tabActive === 'previouslenses'}"
                        mode="task"
                        :data="task.patient.previouslenses"
                        :contact-lenses="task.patient.contactlenses"
                        :patient-id="task.patient.id"
                        :department-id="task.departmentId"
                        :measurement-app="task.patient.department.measurement_app"
                        :checked="task.previous_glasses_checked"
                        :checked-contact="task.previous_contacts_checked"
                        :glasses="task.glasses"
                        :disabled="block || pending"

                        @update-previous-lenses="updatePreviousLenses"
                        @update-previous-lenses-contact="updatePreviousLenses"

                        @update-glasses-checkbox="$emit('save')")

                    OptiTaskDetailsIpad.tab-pane(:class="{'active': tabActive === 'ipad'}"
                        :task="task"

                        :is-offer="!!offer"

                        :task-duplicate="taskDuplicate"
                        :task-service="taskService"

                        :block="block"
                        :pending="pending"

                        @ipad-was-updated="$emit('save')")

                    OptiTaskDetailsLogs.tab-pane(:class="{'active': tabActive === 'history'}"
                        :change-logs="changeLogs"

                        :is-offer="!!offer")

                    OptiTaskDetailsNotes.tab-pane(:class="{'active': tabActive === 'notes'}"
                        :task="task"

                        :is-offer="!!offer"

                        @update-notes-length="n => notes_length = n")

                OptiDimmer.opti-dimmer-task(:active="pending")

        .col-xs-3.col-lg-2(v-show="tabActive !== 'summary'")
            .text-center(style="margin-bottom:10px" v-if="!!offer && !task.rate_save")
                OptiRateWrapper(
                    :header="true"
                    :load="false"
                    :rate="task.rate"
                    :ref-type="2"
                    :ref-id="task.id"

                    @reload="$emit('rate-save')")

            OptiTaskDetailsSidebar(
                :task="task"

                :is-offer="!!offer"
                :show-summary="showSummary"

                @sidebar-was-updated="$emit('save')")
</template>

<script>
    import {ReceiptNotice} from '@/js/app/vue/helpers/ReceiptNotice'
    import validateTask from '@/js/app/vue/components/Tasks/TaskValidator'

    import OptiTaskDetailsOffer from '@/js/app/vue/components/Tasks/Details/OptiTaskDetailsOffer'
    import OptiPatient from '@/js/app/vue/components/Patients/OptiPatient'
    import OptiTaskIcon from '@/js/app/vue/components/Tasks/OptiTaskIcon'
    import OptiTaskDetailsSummary from '@/js/app/vue/components/Tasks/Details/OptiTaskDetailsSummary'
    import OptiTaskDetailsFrame from '@/js/app/vue/components/Tasks/Details/OptiTaskDetailsFrame'
    import OptiTaskDetailsLenses from '@/js/app/vue/components/Tasks/Details/OptiTaskDetailsLenses'
    import OptiTaskDetailsPayment from '@/js/app/vue/components/Tasks/Details/OptiTaskDetailsPayment'
    import OptiPreviousLenses from '@/js/app/vue/components/PreviousLenses/OptiPreviousLenses'
    import OptiTaskDetailsIpad from '@/js/app/vue/components/Tasks/Details/OptiTaskDetailsIpad'
    import OptiTaskDetailsLogs from '@/js/app/vue/components/Tasks/Details/OptiTaskDetailsLogs'
    import OptiTaskDetailsNotes from '@/js/app/vue/components/Tasks/Details/OptiTaskDetailsNotes'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'
    import OptiRateWrapper from '@/js/app/vue/components/Rate/OptiRateWrapper'
    import OptiTaskDetailsSidebar from '@/js/app/vue/components/Tasks/Details/OptiTaskDetailsSidebar'
    import {API} from "@/js/app/vue/api";

    export default {
        name: 'OptiTaskDetails',
        components: {
            OptiTaskDetailsOffer,
            OptiPatient,
            OptiTaskIcon,
            OptiTaskDetailsSummary,
            OptiTaskDetailsFrame,
            OptiTaskDetailsLenses,
            OptiTaskDetailsPayment,
            OptiPreviousLenses,
            OptiTaskDetailsIpad,
            OptiTaskDetailsLogs,
            OptiTaskDetailsNotes,
            OptiDimmer,
            OptiRateWrapper,
            OptiTaskDetailsSidebar
        },
        props: {
            initHash: {
                type: String,
                required: true
            },

            task: {
                type: Object,
                required: true
            },
            rateSave: {
                required: true
            },
            offer: {
                required: true
            },

            changeLogs: {
                type: Object,
                required: true
            },

            pending: {
                type: Boolean,
                required: true
            }
        },
        data() {
            let prefix_card = 'tasks.card.'

            return {
                tab: {
                    summary: prefix_card + 'SUMMARY',
                    powers: prefix_card + 'POWERS',
                    frame: prefix_card + 'FRAME',
                    lenses: prefix_card + 'LENSES',
                    lenses_to_compare: prefix_card + 'LENSES_TO_COMPARE',
                    payment: prefix_card + 'PAYMENT',
                    previouslenses: prefix_card + 'PREVIOUSLENSES',
                    ipad: prefix_card + 'IPAD',
                    left: prefix_card + 'LEFT',
                    history: prefix_card + 'HISTORY',
                    notes: prefix_card + 'NOTES'
                },
                tabActive: null,

                saturationTranslate: {
                    Amber: 'Bursztyn',
                    Gradient: 'Gradalny',
                    Solid: 'Pełny',
                    Fashion: 'Fashion'
                },
                shape: {
                    direction: {
                        frameInfo: '',
                        top: false,
                        bottom: false,
                        temporal: false,
                        nasal: false
                    }
                },
                raw_promotions: [],
                notice: {
                    account: null,
                    rest: null
                },
                valid: {
                    deposit: false,
                    rest: false,
                    bill: false
                },

                increment: 0,
                notes_length: 0,
                show_patient_name: false,
                first_time_save_previous_lenses: true
            }
        },
        mounted() {
            this.$emit('breadcrumb-init', !!this.offer ? [{
                    last: 2
                }, {
                    functions: 'patient',
                    args: [this.task.patient.id, 'state']
                }, {
                    functions: 'offer',
                    args: [this.task.patient.id, this.offer.id, 'state']
                }, {
                    name: 'tasks.breadcrumb.OFFER_TASK',
                    id: this.task.id
                }, {
                    name: 'tasks.card.SUMMARY'
                }] : [{
                    last: 2
                }, {
                    name: 'tasks.breadcrumb.TASKS'
                }, {
                    functions: 'tasks',
                    args: this.task.statusName
                }, {
                    functions: 'task',
                    args: [this.task.id]
                }, {
                    name: 'tasks.card.SUMMARY'
                }])

            if(this.initHash && Object.keys(this.tab).includes(this.initHash)) {
                if(this.initHash === 'summary' && !this.showSummary) {
                    this.setActive('frame')
                } else {
                    this.setActive(this.initHash)
                }
            } else {
                this.setActive(this.showSummary ? 'summary' : 'frame')
            }

            if(this.$state.params.refresh) {
                this.$state.go('app.task.detail', {id: this.task.id, refresh: false}, {notify: false})

                setTimeout(() => {
                    this.$emit('save')

                    this.$notify.error('tasks.action.duplicate.UPDATEPRICES')
                }, 1000)
            }

            API.post('marketing/active', {
                type: 1
            }, API.id(Math.random())).then(res => {
                this.raw_promotions = res.data
            }).catch(() => {
                this.$notify.error('marketing.notify.ERRORLOADDATA')
            })

            ReceiptNotice.get('deposit', this.task.id).then(res => this.notice.account = res).catch(() => {})
            ReceiptNotice.get('rest', this.task.id).then(res => this.notice.rest = res).catch(() => {})
        },
        methods: {
            setActive(hash) {
                this.tabActive = hash

                this.$emit('breadcrumb-change-tab', hash, this.tab[this.tabActive])
            },

            setValid(type, invalid) {
                this.valid[type] = !invalid
            },
            updatePreviousLenses() { //TODO: sprawdzić dlaczego poprzednie okulary robią pustego save'a
                if(!this.first_time_save_previous_lenses) {
                   this.$emit('save')
                }

                this.first_time_save_previous_lenses = false
            },
            incrementUp() {
                this.increment += 1
            },

            goDone() {
                let warnings = validateTask(this.task, 'all')

                if(!warnings.length) {
                    if(confirm(this.$filters.translate('tasks.action.received.confirm.MESSAGE'))) {
                        this.task.status = 10

                        this.$emit('save')
                    }
                } else {
                    warnings.forEach(warning => {
                        this.$notify.error(warning)
                    })
                }
            }
        },
        computed: {
            showSummary() {
                return !!(this.task.frame.partName && (this.task.rightLens.partName || this.task.leftLens.partName))
            },

            block() {
                return this.task.status !== 1
            },
            allowEditConfirmed() {
                // to samo w TasksSaveController.php:151, TasksChangeController.php:889

                return !this.block || (this.task.status === 3 && this.task.order_by_admin && this.$uac.permission('tasks.order_by_admin'))
            },

            putBack() {
                return Object.keys(this.task.attributes).includes('putBack') ||
                    Object.keys(this.task.attributes).includes('greenPutBack')
            },
            forSc() {
                return Object.keys(this.task.attributes).includes('forSc')
            },
            taskDuplicate() {
                return Object.keys(this.task.attributes).includes('taskDuplicate')
            },
            taskService() {
                return Object.keys(this.task.attributes).includes('service')
            },

            taskExtraUsInfo() {
                return !!this.task.services.filter(s => s.id !== 1 && !s.us).length
            },
            discountPercentWithShipping() {
                return this.task.registry.discountType === 'percent' &&
                    !!this.task.services.filter(s => [7, 8, 9].includes(s.id)).length
            },

            promotions() { // to samo w TaskSaveController editLeft
                let promotions = this.raw_promotions.filter(
                    p => [569, 570].includes(this.task.hoya_lens_id) ?
                        [94, 151, 185, 202, 203].includes(p.id) : p.id !== 185)

                return _.orderBy(promotions, ['name'], ['desc'])
            },

            offerHoyaOption() {
                if(this.offer) {
                    let offer_product_pivot = this.offer.products.filter(p => p.pivot.tasks.map(t => t.offer_temporary_task_id).includes(this.task.id))[0].pivot

                    return offer_product_pivot.hoya_option_id ? offer_product_pivot.hoya_option : null
                }

                return null
            }
        }
    }
</script>

<style lang="less" scoped>
    /deep/ .opti-dimmer-task {
        z-index: 100 !important;

        > .loader > .fa {
            font-size: 150px;
        }
    }
</style>
