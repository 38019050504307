angular.module('eOpti.app.settings').config(['$stateProvider', function($stateProvider) {
    $stateProvider.state('app.settings', {
        url: '/settings',
        templateUrl: 'app/views/settings/detail.html',
        controller: 'detailSettingsController',
        params: {
            '#': 'general'
        },
        resolve: {
            settings: ['$http', function($http) {
                return $http.get('api/settings/department')
            }]
        }
    })
}])