<template lang="pug">
    div
        OptiButton(@click.native="dialog = true") {{'catalog.offer.photos.slides.BTN'|translate}}
            template(#icon)
                i.fa.fa-heart

        MountingPortal(v-if="dialog" mountTo="#vue-modal" append)
            OptiCatalogOfferDialogBtnPhotoSlides(@closed="dialog = false")
</template>

<script>
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiCatalogOfferDialogBtnPhotoSlides
        from '@/js/app/vue/components/Catalog/Offer/Dialogs/OptiCatalogOfferDialogBtnPhotoSlides'

    export default {
        name: 'OptiCatalogOfferBtnPhotoSlides',
        components: {
            OptiButton,
            OptiCatalogOfferDialogBtnPhotoSlides,
        },
        data() {
            return {
                dialog: false
            }
        }
    }
</script>