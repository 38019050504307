<template lang="pug">
    .row
        .col-xs-6(v-if="!loading")
            OptiButtonHistory(data="transfer" :id="transfer.id")

        .col-xs-6.text-right(v-if="!loading")
            OptiButtonTransfer(type="return_transfer" :id="transfer.id")

        .col-md-8.col-md-offset-2.col-xs-12.col-xs-offset-0(v-if="!loading")
            .panel-grid
                .panel.panel-primary
                    .panel-heading
                        .pull-left
                            i.fa.fa-navicon.mr3
                            span {{'transfers.details.header.TRANSFER'|translate}}
                        .pull-right \#{{transfer.id}}
                        .clearfix

                    .row
                        .col-sm-6 {{'transfers.details.table.SENDER'|translate}}
                        .col-sm-6 {{transfer.sender.name}}

                    .row
                        .col-sm-6 {{'transfers.details.table.RECEIVER'|translate}}
                        .col-sm-6 {{transfer.receiver.name}}
                    .row
                        .col-sm-6 {{'transfers.details.table.PRODUCTID'|translate}}
                        .col-sm-6
                            OptiProduct(v-if="transfer.productId > 0" :product="transfer.product") {{transfer.productId}}
                            span(v-show="transfer.productId <= 0") {{transfer.productId}}
                    .row
                        .col-sm-6 {{'transfers.details.table.PRODUCTNAME'|translate}}
                        .col-sm-6
                            OptiProduct(v-if="transfer.productId > 0" :product="transfer.product") {{transfer.productName}}
                            span(v-show="transfer.productId <= 0") {{transfer.productName}}
                    .row
                        .col-sm-6 {{'transfers.details.table.QUANTITY'|translate}}
                        .col-sm-6 {{transfer.quantity}}
                    .row
                        .col-sm-6 {{'transfers.details.table.STATUS'|translate}}
                        .col-sm-6 {{transfer.statusName|translate}}
                    .row(v-show="transfer.comments")
                        .col-sm-6 {{'transfers.details.table.COMMENTS'|translate}}
                        .col-sm-6(v-html="$options.filters.nl2br(transfer.comments)")
                    .row
                        .col-sm-6 {{'transfers.details.table.CREATEDAT'|translate}}
                        .col-sm-6 {{transfer.created}}
                    .row(v-show="transfer.status === 3")
                        .col-sm-6 {{'transfers.details.table.RECEIVEDAT'|translate}}
                        .col-sm-6 {{transfer.finished}}
                    .row(v-show="transfer.user.id")
                        .col-sm-6 {{'transfers.details.table.CREATEDBY'|translate}}
                        .col-sm-6 {{transfer.user.firstName}} {{transfer.user.lastName}}
                    .row
                        .col-sm-6 {{'transfers.details.table.REFERENCE'|translate}}
                        .col-sm-6
                            OptiEnum.mr3(enum="TransferDocumentType" :id="transfer.refType")
                            OptiRefId(section="transfer" :type-id="transfer.refType" :id="transfer.refId")

                    .alert.alert-danger(v-show="transfer.task_cancelled")
                        i.fa.fa-exclamation-circle.mr3
                        span {{'transfers.details.notify.TASKCANCELLED'|translate}}

                    .row(v-show="transfer.reason_cancelled")
                        .col-sm-6 {{'transfers.details.table.REASONCANCELLED'|translate}}
                        .col-sm-6(v-html="$options.filters.nl2br(transfer.reason_cancelled)")
                    .row(v-show="shipment_exists")
                        .col-sm-6 {{'shipments.SHIPMENTS'|translate}}
                        .col-sm-6
                            OptiShipmentNotice(:type="13" :id="transfer.id" @shipment-exists="s => shipment_exists = s")

                    .panel-footer(v-opti-empty)
                        OptiButton(type="danger" :title="'transfers.details.action.cancel.BTN'|translate|lower"
                            v-if="$uac.permission('transfers.cancel') && (transfer.status === 1 || transfer.status === 2)"
                            @click.native="dialog_cancel = true") {{'transfers.details.action.cancel.BTN'|translate}}
                            template(#icon)
                                i.fa.fa-remove

                            MountingPortal(v-if="dialog_cancel" mountTo="#vue-modal" append)
                                OptiTransferDetailsDialogReasonCancel(
                                    @confirmed="goCancel"
                                    @closed="dialog_cancel = false")

                        OptiButton.pull-right(type="success" :title="'transfers.details.action.receive.BTN'|translate"
                            v-if="transfer.status === 2 && transfer.destinationSalonId === $uac.user.activeDepartmentId"
                            :disabled="transfer.task_cancelled" text="transfers.details.action.receive.confirm" @confirmed="goReceive"
                            ) {{'transfers.details.action.receive.BTN'|translate}}
                            template(#icon)
                                i.fa.fa-check

            OptiNotes(table="transfer_notes" table-key="transfer_id" :id="transfer.id" :notes="transfer.notes"
                @update-notes="n => transfer.notes = n")

        .col-xs-12
            OptiDimmer(:active="loading")

</template>

<script>
    import {API} from '@/js/app/vue/api'

    import OptiButtonHistory from '@/js/app/vue/components/Button/OptiButtonHistory'
    import OptiButtonTransfer from '@/js/app/vue/components/Button/OptiButtonTransfer'
    import OptiProduct from '@/js/app/vue/components/Warehouse/Products/OptiProduct'
    import OptiEnum from '@/js/app/vue/components/OptiEnum'
    import OptiRefId from '@/js/app/vue/components/OptiRefId'
    import OptiShipmentNotice from '@/js/app/vue/components/Shipping/OptiShipmentNotice'
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiTransferDetailsDialogReasonCancel
        from '@/js/app/vue/components/Warehouse/Transfers/Details/Dialogs/OptiTransferDetailsDialogReasonCancel'
    import OptiNotes from '@/js/app/vue/components/OptiNotes'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiTransferDetails',
        components: {
            OptiButtonHistory,
            OptiButtonTransfer,
            OptiProduct,
            OptiEnum,
            OptiRefId,
            OptiShipmentNotice,
            OptiButton,
            OptiTransferDetailsDialogReasonCancel,
            OptiNotes,
            OptiDimmer
        },
        data() {
            return {
                transfer: {},

                products: [],
                shipment_exists: false,

                dialog_cancel: false,
                loading: true
            }
        },
        mounted() {
            API.get('warehouse/transfer/' + this.$state.params.id).then(res => {
                this.getData(res.data)
            }).finally(() => {
                this.loading = false
            }).catch(() => {
                this.$notify.error('transfers.details.notify.error.LOAD')
            })
        },
        methods: {
            updateBreadcrumb() {
                let change = [],
                    out_come = this.transfer.sourceSalonId === this.$uac.user.activeDepartmentId

                change.push({
                    id: 3,
                    field: 'name',
                    value: 'transfers.breadcrumb.' + (out_come ? 'OUTCOMING' : 'INCOMING')
                }, {
                    id: 4,
                    field: 'functions',
                    value: out_come ? 'outcomingtransfers' : 'incomingtransfers'
                }, {
                    id: 4,
                    field: 'args',
                    value: this.transfer.statusName
                })

                this.$emit('update-breadcrumb', change)
            },
            getData(data) {
                this.transfer = data

                this.updateBreadcrumb()
            },

            goCancel(reason) {
                this.loading = true

                API.post('warehouse/transfer/cancel', {
                    id: this.transfer.id,
                    reason_cancelled: reason
                }).then(res => {
                    this.getData(res.data)

                    this.$notify.success('transfers.details.notify.CANCEL')
                }).finally(() => {
                    this.loading = false
                }).catch(() => {
                    this.$notify.error('transfers.details.notify.error.CANCEL')
                })
            },
            goReceive() {
                this.loading = true

                API.post('warehouse/transfer/receive', {
                    id: this.transfer.id
                }).then(res => {
                    this.getData(res.data)

                    this.$notify.success('transfers.details.notify.RECEIVE')
                }).finally(() => {
                    this.loading = false
                }).catch(() => {
                    this.$notify.error('transfers.details.notify.error.RECEIVE')
                })
            }
        }
    }
</script>