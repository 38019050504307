import {easterDate} from './Utils'

class BudgetHelperClass {
    constructor() {
        // te metody są żywcem przepisywane z BudgetHelper.php

        this.weeks_by_quarter = {
            1: _.range(1, 13 + 1),
            2: _.range(14, 26 + 1),
            3: _.range(27, 39 + 1),
            4: _.range(40, 53 + 1)
        }
    }

    getQuarterByWeek(_moment) {
        let quarter = 0

        _.forEach(this.weeks_by_quarter, (weeks, q) => {
            if(weeks.includes(_moment.isoWeek())) {
                quarter = q
            }
        })

        return parseInt(quarter)
    }

    setQuarter(_moment) {
        let year = _moment.isoWeekYear(),
            week = _moment.isoWeek(),
            new_week = {
                start: 0,
                end: 0
            }

        _.forEach(this.weeks_by_quarter, weeks => {
            if(weeks.includes(week)) {
                new_week.start = weeks[0]
                new_week.end = _.last(weeks)
            }
        })

        if(new_week.end === 53 && !this.isW53(year)) {
            new_week.end = 52
        }

        return {
            start: moment().isoWeekYear(year).week(new_week.start).startOf('w'),
            end: moment().isoWeekYear(year).week(new_week.end).endOf('w')
        }
    }

    changeQuarter(_moment, sign) {
        let year = _moment.isoWeekYear(),
            quarter = this.getQuarterByWeek(_moment),
            _new = {
                year: year,
                quarter: quarter,
                week: {
                    start: 0,
                    end: 0
                }
            }

        if(sign === '+') {
            if(quarter === 4) {
                _new.year = year + 1
                _new.quarter = 1
            } else {
                _new.quarter = quarter + 1
            }
        } else if(sign === '-') {
            if(quarter === 1) {
                _new.year = year - 1
                _new.quarter = 4
            } else {
                _new.quarter = quarter - 1
            }
        }

        _new.week.start = this.weeks_by_quarter[_new.quarter][0]
        _new.week.end = _.last(this.weeks_by_quarter[_new.quarter])

        if(_new.week.end === 53 && !this.isW53(year)) {
            _new.week.end = 52
        }

        return {
            start: moment().isoWeekYear(_new.year).week(_new.week.start).startOf('w'),
            end: moment().isoWeekYear(_new.year).week(_new.week.end).endOf('w')
        }
    }

    getSpecialEvents(_moment) {
        let year = _moment.clone().add(10, 'd').isoWeekYear(),
            easter_monday_week = easterDate(year).add(1, 'd').isoWeek(),
            //Jeśli jest zmiana numeru tygodnia należy zmienić też entriesController.php
            weeks = [2, 8, 15, 21, 28, 34, 41, 47],
            _return = {}

        if(year === 2024) {
            weeks[1] = 7
            weeks[2] = 16
        }

        if (moment(year + 'W' + ('0' + weeks[0]).substr(-2)).format('MM-DD') === '01-06') {
            ++weeks[0]
        }

        weeks.forEach(week => {
            if(easter_monday_week === week) {
                ++week
            }

            _return[moment(year + 'W' + ('0' + week).substr(-2)).format('YYYY-MM-DD')] = 'Konwent'
        })

        return _return
    }

    isW53(year) {
        let date = moment().set({
            year: year,
            month: 2,
            date: 3
        })

        return date.startOf('year').weekday() === 3 || date.endOf('year').weekday() === 3 ||
            (date.isLeapYear() && date.startOf('year').weekday() === 2)
    }
}

export const BudgetHelper = new BudgetHelperClass()