<template lang="pug">
    .panel.panel-primary(style="position:relative")
        .panel-heading
            i.fa.fa-navicon.mr3
            span {{'settings.general.offer.HEADING'|translate}}

        .panel-body
            .form-horizontal
                .form-group
                    label.col-md-4.control-label(for="slide-duration"
                        ) {{'settings.general.offer.slide-duration.HEADING'|translate}}
                    .col-md-6(style="padding-top:7px")
                        RangeSlider(style="width:100%;padding:0"
                            min="1"
                            max="60"
                            step="1"
                            v-model="offer.changed.slide_duration")

                    .col-md-2
                        .text-center(style="padding-top:7px;white-space:nowrap"
                            ) {{offer.changed.slide_duration}} {{'settings.general.offer.slide-duration.UNIT'|translate}}

        .panel-footer.text-right
            OptiButtonSave(:disabled="!changed" @click.native="save")

        OptiDimmer(:active="loading")
</template>

<script>
    import {API} from '@/js/app/vue/api'

    import RangeSlider from 'vue-range-slider'
    import OptiButtonSave from '@/js/app/vue/components/Button/OptiButtonSave'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiSettingsGeneralOffer',
        components: {
            RangeSlider,
            OptiButtonSave,
            OptiDimmer,
        },
        data() {
            return {
                offer: {
                    current: {
                        slide_duration: 0
                    },
                    changed: {
                        slide_duration: 0
                    }
                },

                loading: true
            }
        },
        mounted() {
            API.get('settings/general/offer/get').then(res => {
                this.offer = {
                    current: _.cloneDeep(res.data),
                    changed: _.cloneDeep(res.data)
                }
            }).finally(() => {
                this.loading = false
            }).catch(() => {
                this.$notify.error('settings.general.offer.notify.error.LOAD')
            })
        },
        methods: {
            save() {
                this.loading = true

                API.post('settings/general/offer/save', {
                    offer: this.offer.changed
                }).then(res => {
                    this.offer = {
                        current: _.cloneDeep(res.data),
                        changed: _.cloneDeep(res.data)
                    }

                    this.$notify.success('settings.general.offer.notify.SAVE')
                }).finally(() => {
                    this.loading = false
                }).catch(() => {
                    this.$notify.error('settings.general.offer.notify.error.SAVE')
                })
            }
        },
        computed: {
            changed() {
                return JSON.stringify(this.offer.current) !== JSON.stringify(this.offer.changed)
            }
        }
    }
</script>