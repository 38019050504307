<template lang="pug">
    .row
        .col-sm-7
            OptiSettingsGeneralMain

            OptiSettingsGeneralOffer(v-if="permissions.offer")

            OptiSettingsGeneralInPost

        .col-sm-5
            OptiSettingsGeneralInformation

            OptiSettingsGeneralMeasurementApp
</template>

<script>
    import OptiSettingsGeneralMain from '@/js/app/vue/components/Settings/General/OptiSettingsGeneralMain'
    import OptiSettingsGeneralOffer from '@/js/app/vue/components/Settings/General/OptiSettingsGeneralOffer'
    import OptiSettingsGeneralInPost from '@/js/app/vue/components/Settings/General/OptiSettingsGeneralInPost'
    import OptiSettingsGeneralInformation
        from '@/js/app/vue/components/Settings/General/OptiSettingsGeneralInformation'
    import OptiSettingsGeneralMeasurementApp
        from '@/js/app/vue/components/Settings/General/OptiSettingsGeneralMeasurementApp'

    export default {
        name: 'OptiSettingsGeneral',
        components: {
            OptiSettingsGeneralMain,
            OptiSettingsGeneralOffer,
            OptiSettingsGeneralInPost,
            OptiSettingsGeneralInformation,
            OptiSettingsGeneralMeasurementApp
        },
        data() {
            return {
                permissions: {
                    offer: this.$uac.permission('settings.general.offer')
                }
            }
        }
    }
</script>