<template lang="pug">
    <!-- TODO: OptiTaskDetailsIpad class warunek taki sam jak w OptiTaskDetialsIpad -->
    .task-ipad(:class="{'border-pink': [0, 2].includes(task.measurement_app) || (task.measurement.id && task.measurementJawroVisu.id)}")
        h4.page-header(style="margin-top:10px") VisuOpti

        .row
            .col-xs-12(style="margin-bottom:10px" v-show="pair.measurement_id_1 && pair.measurement_id_2")
                OptiButton.pull-right(type="info" @click.native="dialog_compare = true"
                    ) {{'tasks.measurement.individual.compare.BTN'|translate}}
                    template(#icon)
                        i.fa.fa-image

                MountingPortal(v-if="dialog_compare" mountTo="#vue-modal" append)
                    OptiTaskDetailsIpadJawroVisuDialogCompareImages(
                        :image1="pair.measurement_id_1"
                        :image2="pair.measurement_id_2"
                        :is-offer="isOffer"

                        @closed="dialog_compare = false")

            .col-xs-12
                OptiTaskDetailsIpadJawroVisuMeasurements(
                    :task-id="task.id"
                    :is-offer="isOffer"
                    :status="task.status"
                    :department-id="task.departmentId"
                    :measurement-jawro-visu-id="task.measurement_jawro_visu_id"
                    :frame="task.frame"
                    :reload-measurements="task.reload_measurements.jawro_visu"
                    :timestamp="task.timestamp"

                    :block="block"

                    @update-measurement-jawro-visu="updateMeasurementJawroVisu")

            .col-xs-12.text-center
                a(v-if="pair.measurement_id_1" :href="'upload/jawro_visu/task/' + pair.measurement_id_1 + '.jpg'"
                    target="_blank" :title="'tasks.ipad.printscreen.TITLE'|translate")
                    img(style="width:100%" :src="'upload/jawro_visu/task/' + pair.measurement_id_1 + '.jpg?refresh=' + task.timestamp")
                a(style="display:block;margin-top:15px" v-if="pair.measurement_id_2" :href="'upload/jawro_visu/task/' + pair.measurement_id_2 + '.jpg'"
                    target="_blank" :title="'tasks.ipad.printscreen.TITLE'|translate")
                    img(style="width:100%" :src="'upload/jawro_visu/task/' + pair.measurement_id_2 + '.jpg?refresh=' + task.timestamp")

            .col-xs-12
                .row
                    .col-lg-8.text-center
                        #measurement1(:class="{'pupil-centre': !task.measurement_jawro_visu_id || task.measurementJawroVisu.method}")
                            #measurement1-img
                            #measurement1-points
                                #right {{'prescription.RIGHT'|translate}}
                                #left {{'prescription.LEFT'|translate}}

                                #rePdDist {{task.measurementJawroVisu.pd_r|number(1)}}
                                #lePdDist {{task.measurementJawroVisu.pd_l|number(1)}}

                                #reBt.bt-row
                                    div(:title="btTitle('main', task.measurementJawroVisu, 'r')") {{task.measurementJawroVisu.bt_r|number(1)}}
                                    .text-muted(v-show="task.measurementJawroVisu.bt_r !== task.measurementJawroVisu.raw_bt_r"
                                        :title="btTitle('raw', task.measurementJawroVisu, 'r')"
                                        ) {{task.measurementJawroVisu.raw_bt_r|number(1)}}
                                #leBt.bt-row
                                    div(:title="btTitle('main', task.measurementJawroVisu, 'l')") {{task.measurementJawroVisu.bt_l|number(1)}}
                                    .text-muted(v-show="task.measurementJawroVisu.bt_l !== task.measurementJawroVisu.raw_bt_l"
                                        :title="btTitle('raw', task.measurementJawroVisu, 'l')"
                                        ) {{task.measurementJawroVisu.raw_bt_l|number(1)}}

                                #widthR {{task.measurementJawroVisu.frame_width|number(2)}}
                                #widthL {{task.measurementJawroVisu.frame_width|number(2)}}

                                #heightR {{task.measurementJawroVisu.frame_height|number(2)}}
                                #heightL {{task.measurementJawroVisu.frame_height|number(2)}}

                                #vboxR.oma-measurement(v-show="task.frame.height !== 0") {{task.frame.height|number(2)}}
                                #vboxL.oma-measurement(v-show="task.frame.height !== 0") {{task.frame.height|number(2)}}

                                #hboxR.oma-measurement(v-show="task.frame.width !== 0") {{task.frame.width|number(2)}}
                                #hboxL.oma-measurement(v-show="task.frame.width !== 0") {{task.frame.width|number(2)}}

                                #dbl {{task.measurementJawroVisu.dbl|number(2)}}
                    .col-lg-4.text-center
                        #measurement2
                            #measurement2-img
                            #measurement2-points
                                #wpa {{task.measurementJawroVisu.pa|number(2)}}
                                #cvd
                                    div
                                        span.mr3(v-show="task.measurementJawroVisu.cvd_r !== task.measurementJawroVisu.cvd_l"
                                            ) {{'prescription.RIGHT'|translate}}
                                        span {{task.measurementJawroVisu.cvd_r|number(1)}}

                                    div(v-show="task.measurementJawroVisu.cvd_r !== task.measurementJawroVisu.cvd_l")
                                        span.mr3 {{'prescription.LEFT'|translate}}
                                        span {{task.measurementJawroVisu.cvd_l|number(1)}}

            .col-xs-12
                form#formiPad.form-validate(name="formiPad")
                    .row.text-center.border-top-separate.border-bottom-separate(
                        style="padding:10px 0;border-top-width:1px;border-bottom-width:1px"
                        v-show="task.measurementJawroVisu.measurement_id")
                        .col-xs-12
                            b.mr3 {{'tasks.ipad.CONSTRUCT'|translate}}
                            span {{task.measurementJawroVisu.method_name|translate}}
                    .row
                        .col-md-6(style="padding-left:0;padding-right:0")
                            .table-responsive
                                table.table.table-presenter.table-striped
                                    thead
                                        tr.separate-bottom
                                            th
                                            th {{'prescription.RIGHT'|translate}}
                                            th {{'prescription.LEFT'|translate}}
                                    tbody
                                        tr
                                            td(colspan="3" style="padding:0;border-top:0")
                                                OptiPdCalculate(v-if="task.measurement_app === 1"
                                                    :measurement="task.measurementJawroVisu"
                                                    :task-type="task.type"

                                                    :disabled="block"
                                                    :pending="pending"

                                                    :show-pd-dist="task.type !== 1"
                                                    :pd-dist="task.pdDist"
                                                    :re-pd-dist="task.rightEye.pdDist"
                                                    :le-pd-dist="task.leftEye.pdDist"

                                                    :show-pd-near="task.type !== 2"
                                                    :pd-near="task.pdNear"
                                                    :re-pd-near="task.rightEye.pdNear"
                                                    :le-pd-near="task.leftEye.pdNear"

                                                    :show-bt="true"
                                                    :re-bt="task.rightEye.bt"
                                                    :le-bt="task.leftEye.bt"

                                                    @updated="updatePd")

                                                OptiPdCalculate(v-else
                                                    :measurement="task.measurementJawroVisu"
                                                    :task-type="task.type"

                                                    :disabled="true"
                                                    :pending="pending"

                                                    :show-pd-dist="task.type !== 1"
                                                    :pd-dist="task.measurementJawroVisu.id ? task.measurementJawroVisu.pd_r + task.measurementJawroVisu.pd_l : 0"
                                                    :re-pd-dist="task.measurementJawroVisu.id ? task.measurementJawroVisu.pd_r : 0"
                                                    :le-pd-dist="task.measurementJawroVisu.id ? task.measurementJawroVisu.pd_l : 0"

                                                    :show-pd-near="task.type !== 2"
                                                    :pd-near="task.measurementJawroVisu.id ? task.measurementJawroVisu.pd_r + task.measurementJawroVisu.pd_l - 4 : 0"
                                                    :re-pd-near="task.measurementJawroVisu.id ? Math.round((task.measurementJawroVisu.pd_r - 2) * 100) / 100 : 0"
                                                    :le-pd-near="task.measurementJawroVisu.id ? Math.round((task.measurementJawroVisu.pd_l - 2) * 100) / 100 : 0"

                                                    :show-bt="true"
                                                    :re-bt="task.measurementJawroVisu.id ? Math.round((task.measurementJawroVisu.bt_r - (task.type === 1 ? 2 : 0)) * 100) / 100 : 0"
                                                    :le-bt="task.measurementJawroVisu.id ? Math.round((task.measurementJawroVisu.bt_l - (task.type === 1 ? 2 : 0)) * 100) / 100 : 0"

                                                    @updated="updatePd")

                        .col-md-6(style="padding-left:0;padding-right:0"
                            v-show="task.measurementJawroVisu.measurement_id")
                            .table-responsive
                                table.table.table-presenter.table-striped
                                    tr
                                        td(colspan="3" style="padding:0;border-top:0")
                                            table.table.table-presenter.table-striped
                                                thead.hidden-xs.hidden-sm
                                                    tr
                                                        td(colspan="4") &nbsp;

                                                tbody(v-if="task.measurement_app === 1")
                                                    tr.separate-top
                                                        th {{'tasks.frame.HEIGHT'|translate}}
                                                        td
                                                            .form-control.text-center(disabled) {{task.frame.height|number(1)}}
                                                        th(style="text-align:right !important") {{'tasks.frame.WIDTH'|translate}}
                                                        td
                                                            .form-control.text-center(disabled) {{task.frame.width|number(1)}}
                                                    tr
                                                        th {{'tasks.INCLINATION'|translate}}
                                                        td
                                                            .form-control.text-center(disabled) {{task.inclination|number(1)}}
                                                        th(style="text-align:right !important")
                                                            span {{'tasks.CVD'|translate}}
                                                            sub(v-show="task.cvd !== task.cvdLeft") {{'prescription.RIGHT'|translate}}
                                                        td
                                                            .form-control.text-center(disabled) {{task.cvd|number(1)}}
                                                    tr
                                                        th {{'tasks.FFFA'|translate}}
                                                        td
                                                            .form-control.text-center(disabled) {{task.fffa|number(1)}}
                                                        th(style="text-align:right !important" v-show="task.cvd !== task.cvdLeft")
                                                            span {{'tasks.CVD'|translate}}
                                                            sub {{'prescription.LEFT'|translate}}
                                                        td(v-show="task.cvd !== task.cvdLeft")
                                                            .form-control.text-center(disabled) {{task.cvdLeft|number(1)}}
                                                        td(colspan="2" v-show="task.cvd === task.cvdLeft")

                                                tbody(v-else)
                                                    tr.separate-top
                                                        th {{'tasks.frame.HEIGHT'|translate}}
                                                        td
                                                            .form-control.text-center(disabled) {{task.measurementJawroVisu.frame_height|number(1)}}
                                                        th(style="text-align:right !important") {{'tasks.frame.WIDTH'|translate}}
                                                        td
                                                            .form-control.text-center(disabled) {{task.measurementJawroVisu.frame_width|number(1)}}
                                                    tr
                                                        th {{'tasks.INCLINATION'|translate}}
                                                        td
                                                            .form-control.text-center(disabled) {{task.measurementJawroVisu.pa|number(1)}}
                                                        th(style="text-align:right !important")
                                                            span {{'tasks.CVD'|translate}}
                                                            sub(v-show="task.measurementJawroVisu.cvd_r !== task.measurementJawroVisu.cvd_l"
                                                                ) {{'prescription.RIGHT'|translate}}
                                                        td
                                                            .form-control.text-center(disabled) {{task.measurementJawroVisu.cvd_r|number(1)}}
                                                    tr
                                                        th {{'tasks.FFFA'|translate}}
                                                        td
                                                            .form-control.text-center(disabled) {{task.measurementJawroVisu.fffa|number(1)}}
                                                        th(style="text-align:right !important" v-show="task.measurementJawroVisu.cvd_r !== task.measurementJawroVisu.cvd_l")
                                                            span {{'tasks.CVD'|translate}}
                                                            sub {{'prescription.LEFT'|translate}}
                                                        td(v-show="task.measurementJawroVisu.cvd_r !== task.measurementJawroVisu.cvd_l")
                                                            .form-control.text-center(disabled) {{task.measurementJawroVisu.cvd_l|number(1)}}
                                                        td(colspan="2" v-show="task.measurementJawroVisu.cvd_r === task.measurementJawroVisu.cvd_l")
</template>

<script>
    import {TaskMeasurementTitle} from '@/js/app/vue/components/Tasks/TaskMeasurementTitle'

    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiTaskDetailsIpadJawroVisuDialogCompareImages
        from '@/js/app/vue/components/Tasks/Details/Ipad/JawroVisu/Dialogs/OptiTaskDetailsIpadJawroVisuDialogCompareImages'
    import OptiPdCalculate from '@/js/app/vue/components/Inputs/OptiPdCalculate'
    import OptiTaskDetailsIpadJawroVisuMeasurements
        from '@/js/app/vue/components/Tasks/Details/Ipad/JawroVisu/OptiTaskDetailsIpadJawroVisuMeasurements'

    export default {
        name: 'OptiTaskDetailsIpadJawroVisu',
        components: {
            OptiButton,
            OptiTaskDetailsIpadJawroVisuDialogCompareImages,
            OptiPdCalculate,
            OptiTaskDetailsIpadJawroVisuMeasurements
        },
        props: {
            task: {
                type: Object,
                required: true
            },

            isOffer: {
                type: Boolean,
                required: true
            },

            block: {
                type: Boolean,
                required: true
            },
            pending: {
                type: Boolean,
                required: true
            }
        },
        data() {
            return {
                hover: null,

                pair: {
                    measurement_id_1: 0,
                    measurement_id_2: 0
                },

                dialog_compare: false
            }
        },
        methods: {
            updateMeasurementJawroVisu({measurement_jawro_visu_id, pair}) {
                if(!this.block && this.task.measurement_jawro_visu_id !== measurement_jawro_visu_id) {
                    this.task.measurement_jawro_visu_id = measurement_jawro_visu_id

                    this.$emit('ipad-jawro-visu-was-updated')
                }

                this.pair.measurement_id_1 = pair[0]
                this.pair.measurement_id_2 = pair[1]
            },
            updatePd(changed) {
                _.forEach(changed, (value, key) => {
                    if(key.startsWith('re')) {
                        this.task.rightEye[key[2].toLowerCase() + key.substring(3)] = value
                    } else if(key.startsWith('le')) {
                        this.task.leftEye[key[2].toLowerCase() + key.substring(3)] = value
                    } else {
                        this.task[key] = value
                    }
                })

                this.$emit('ipad-jawro-visu-was-updated')
            },

          btTitle(type, measurement, side) {
            return TaskMeasurementTitle.title('JawroVisu', type, measurement, side)
          }
        }
    }
</script>

<style lang="less" scoped>
    .border-pink {
        margin: 25px -15px 0 -15px;
        padding: 0 15px 0 15px;
        border: 5px solid deeppink;

        > .page-header {
            color: deeppink;
            border-bottom-color: deeppink;
        }
    }
</style>