<template lang="pug">
    .row
        div(:class="{'col-sm-8': column, 'col-md-6 col-md-offset-3': !column}")
            form.form.form-horizontal(name="formRegister")
                .form-group
                    .col-xs-4(style="padding-top:23px")
                        .radio.c-radio.c-radio-nofont
                            label.text-bold
                                input(type="radio" name="type" v-model="search.type" :value="0")
                                span
                                | {{'register.history.RANGE'|translate}}
                    .col-xs-8
                        .form-group
                            label.col-xs-2.control-label(for="date_from") {{'register.history.FROM'|translate}}
                            .col-xs-10
                                OptiDate#date_from(:value="search.date_from" @update-date="updateDateFrom")
                                OptiValidate(f="date_from" :data="$v.search.date_from")
                        .form-group
                            label.col-xs-2.control-label(for="date_to") {{'register.history.TO'|translate}}
                            .col-xs-10
                                OptiDate#date_to(:value="search.date_to" @update-date="updateDateTo")
                                OptiValidate(f="date_to" :data="$v.search.date_to")

                .form-group
                    .col-xs-4
                        .radio.c-radio.c-radio-nofont
                            label.text-bold
                                input(type="radio" name="type" v-model="search.type" :value="3")
                                span
                                | {{'register.history.ONEDAY'|translate}}
                    .col-xs-8
                        .form-group
                            .col-xs-10.col-xs-offset-2
                                OptiDate#date_one(:value="search.one_day" @update-date="updateDateOne")
                                OptiValidate(f="date_one" :data="$v.search.one_day")

                .form-group
                    .col-xs-4(style="padding-top:23px")
                        .radio.c-radio.c-radio-nofont
                            label.text-bold
                                input(type="radio" name="type" v-model="search.type" :value="2")
                                span
                                | {{'register.history.RANGE2'|translate}}
                    .col-xs-8
                        .form-group
                            .col-xs-7.col-xs-offset-2
                                select#month_years.form-control(v-model="search.month.year" @change="updateMonthYear")
                                    option(v-for="y in select.years" :value="y") {{y}}
                            label.col-xs-3.bold(style="padding-top:7px" for="month_years") {{'register.history.range.YEAR'|translate}}
                        .form-group
                            .col-xs-7.col-xs-offset-2
                                select#month_months.form-control(v-model="search.month.month" @change="search.type = 2")
                                    option(v-for="month in select.months" :value="month.id") {{month.name|translate}}
                            label.col-xs-3.bold(style="padding-top:7px" for="month_months") {{'register.history.range.MOUNTH'|translate}}

                .form-group
                    .col-xs-4(style="padding-top:23px")
                        .radio.c-radio.c-radio-nofont
                            label.text-bold
                                input(type="radio" name="type" v-model="search.type" :value="4")
                                span
                                | {{'register.history.WEEKS'|translate}}
                    .col-xs-8
                        .form-group
                            label.col-xs-2.control-label(for="week_date_from") {{'register.history.FROM'|translate}}
                            .col-xs-10
                                OptiSelectSnapshot#week_date_from(type="from" :date="search.week.from"
                                    :opposite="search.week.to" @selected="updateWeekDateFrom")
                        .form-group
                            label.col-xs-2.control-label(for="week_date_to") {{'register.history.TO'|translate}}
                            .col-xs-10
                                OptiSelectSnapshot#week_date_to(type="to" :date="search.week.to"
                                    :opposite="search.week.from" @selected="updateWeekDateTo")

                .form-group
                    .col-xs-4(style="padding-top:23px")
                        .radio.c-radio.c-radio-nofont
                            label.text-bold
                                input(type="radio" name="type" v-model="search.type" :value="5")
                                span
                                | {{'register.history.QUARTER'|translate}}
                    .col-xs-8
                        .form-group
                            .col-xs-7.col-xs-offset-2
                                select#quarter_years.form-control(v-model="search.quarter.year" @change="updateQuarterYear")
                                    option(v-for="y in select.years" :value="y") {{y}}
                            label.col-xs-3.bold(style="padding-top:7px" for="quarter_years") {{'register.history.range.YEAR'|translate}}
                        .form-group
                            .col-xs-7.col-xs-offset-2
                                select#quarter_quarter.form-control(v-model="search.quarter.quarter" @change="search.type = 5")
                                    option(v-for="q in select.quarters" :value="q") {{q}}
                            label.col-xs-3.bold(style="padding-top:7px" for="quarter_quarter") {{'register.history.range.QUARTER'|translate}}

                .form-group
                    .col-xs-4
                        .radio.c-radio.c-radio-nofont
                            label.text-bold
                                input(type="radio" name="type" v-model="search.type" :value="1")
                                span
                                | {{'register.history.LAST'|translate}}
                    .col-xs-8
                        .form-group
                            .col-xs-7.col-xs-offset-2
                                OptiNumber#days(:header="$filters.translate('register.history.LAST') + ' ' + $filters.translate('register.history.DAYS')"
                                    :value="search.days" @confirmed="updateDays" :required="true") {{search.days}}
                            label.col-xs-3.bold(style="padding-top:7px" for="days") {{'register.history.DAYS'|translate}}
                            OptiValidate(f="days" :data="$v.search.days")

                .form-group
                    .col-xs-12
                        OptiButton(style="margin-right:10px" :class="{'active': filter}" v-show="is_central_department"
                            @click.native="filter = !filter") {{'report.tasksale.FILTERS'|translate}}
                            template(#icon)
                                i.fa.fa-filter

                        OptiButton(:class="{'active': column}" @click.native="column = !column") {{'report.tasksale.COLUMNS'|translate}}
                            template(#icon)
                                i.fa.fa-cog

                        OptiButton.pull-right(type="success" :disabled="$v.search.$invalid || loading" @click.native="goGenerate") {{'register.SEARCH'|translate}}
                            template(#icon)
                                i.fa.fa-search

                .form-group(v-show="filter")
                    OptiCheckboxDepartment(
                        :all="true"
                        :recently-closed="true"
                        :ids="search.department_ids"

                        @department-ids="updateDepartmentIds")

        .col-sm-4(style="padding-left:30px" v-show="column")
            .bold(style="font-size:1.2em") {{'report.tasksale.COLUMNS'|translate}}
            div(v-for="column in columns")
                .checkbox.c-checkbox.inline
                    label
                        input(type="checkbox" :id="column.key" :name="column.key" v-model="column.selected")
                        span.fa.fa-check
                label(:for="column.key") {{column.lang|translate}}

        .col-xs-12(v-show="!loading")
            .panel.panel-primary(style="margin-bottom:0")
                .panel-heading
                    i.fa.fa-navicon.mr3
                    span {{'report.tasksale.table.HEADING'|translate}}
                    .pull-right(v-show="from && to") {{from}} - {{to}}

                .table-responsive
                    table.table.table-condensed.table-bordered.table-striped
                        thead
                            tr
                                th.text-right Id
                                th(v-show="is_central_department") {{'report.tasksale.table.DEPARTMENT'|translate}}
                                th(v-show="!is_central_department") {{'report.tasksale.table.USER'|translate}}
                                th(v-show="isColumnActive('default')") {{'report.tasksale.table.DEFAULT'|translate}}
                                th(v-show="isColumnActive('count')") {{'report.tasksale.table.TASKCOUNT'|translate}}
                                th(v-show="isColumnActive('pricing')") {{'report.tasksale.table.TASKVALUE'|translate}}
                                th(v-show="isColumnActive('pricing_avg')") {{'report.tasksale.table.TASKVALUEAVG'|translate}}
                                th(v-show="isColumnActive('count_per_day')") {{'report.tasksale.table.TASKCOUNTDAY'|translate}}
                                th(v-show="isColumnActive('avg_per_day')") {{'report.tasksale.table.TASKVALUEDAY'|translate}}
                                th(v-show="isColumnActive('sale_value')") {{'report.tasksale.table.SALEVALUE'|translate}}
                                th(v-show="isColumnActive('sale_wholesale')") {{'report.tasksale.table.SALEWHOLESALE'|translate}}
                                th(v-show="isColumnActive('saleoffstock_value')") {{'report.tasksale.table.SALEOFFSTOCK'|translate}}
                                th(v-show="isColumnActive('register')")
                                    span {{'report.tasksale.table.REGISTER'|translate}}
                                    small(style="display:block") {{'report.tasksale.table.TODAY'|translate}}
                                th(v-show="isColumnActive('turn_over')") {{'schedule.budget.table.head.TURNOVER'|translate}}

                        tbody.text-right
                            tr(v-for="task in tasks" v-show="task.default || noactive" :class="{'text-muted': !task.default}")
                                td {{task.id}}
                                td.text-left {{task.department}}
                                td(v-show="isColumnActive('default')") {{task.default|currency}}
                                td(v-show="isColumnActive('count')") {{task.count}}
                                td(v-show="isColumnActive('pricing')")
                                    a.link(:href="$state.href('app.tasks.all', {search: ['created:>=:' + from, 'created:<=:' + to, filter_column + ':=:' + task.filter, 'status:>:notaccepted']})"
                                        target="_blank") {{task.pricing|currency}}
                                td(v-show="isColumnActive('pricing_avg')") {{task.pricing_avg|currency}}
                                td(v-show="isColumnActive('count_per_day')") {{task.count_per_day|number(2)}}
                                td(v-show="isColumnActive('avg_per_day')") {{task.avg_per_day|currency}}
                                td(ng-show="isColumnActive('sale_value')")
                                    a.link(:href="$state.href('app.sales', {search: ['date:>=:' + from, 'date:<=:' + to, filter_column + ':=:' + task.filter]})"
                                        target="_blank") {{task.sale_value|currency}}
                                td(v-show="isColumnActive('sale_wholesale')") {{task.sale_wholesale|currency}}
                                td(v-show="isColumnActive('saleoffstock_value')")
                                    a.link(:href="$state.href('app.sales-offstock', {search: ['date:>=:' + from, 'date:<=:' + to, filter_column_offstock + ':=:' + task.filter]})"
                                        target="_blank") {{task.saleoffstock_value|currency}}
                                td(v-show="isColumnActive('register')") {{task.register|currency}}
                                td(v-show="isColumnActive('turn_over')") {{task.turn_over}}
                            tr(v-show="!tasks.length")
                                td.text-center.text-muted(colspan="100")
                                    i.fa.fa-exclamation-circle.mr3
                                    span {{'default.table.EMPTY'|translate}}

                        tfoot.text-right.bold(v-show="tasks.length > 1")
                            tr
                                td
                                td
                                td(v-show="isColumnActive('default')") {{sum.default|currency}}
                                td(v-show="isColumnActive('count')") {{sum.count}}
                                td(v-show="isColumnActive('pricing')") {{sum.pricing|currency}}
                                td(v-show="isColumnActive('pricing_avg')") {{sum.pricing_avg|currency}}
                                td(v-show="isColumnActive('count_per_day')")
                                td(v-show="isColumnActive('avg_per_day')")
                                td(v-show="isColumnActive('sale_value')") {{sum.sale_value|currency}}
                                td(v-show="isColumnActive('sale_wholesale')") {{sum.sale_wholesale|currency}}
                                td(v-show="isColumnActive('saleoffstock_value')") {{sum.saleoffstock_value|currency}}
                                td(v-show="isColumnActive('register')") {{sum.register|currency}}
                                td(v-show="isColumnActive('turn_over')") {{sum.turn_over}}

            div(style="padding-bottom:10px")
                span.text-muted
                    i.fa.fa-exclamation-circle.mr3
                    span {{'report.tasksale.info.TASKS'|translate}}
                .warn.pull-right {{'report.tasksale.notify.CURRENTDATA'|translate}}

        .col-xs-12(v-show="!loading && permission && tasks.length")
            a.link(@click="noactive = true" v-show="is_central_department && !noactive") {{'report.tasksale.table.toggle.SHOW'|translate}}
            a.link(@click="noactive = false" v-show="is_central_department && noactive") {{'report.tasksale.table.toggle.HIDE'|translate}}

            OptiButton.pull-right(style="margin-left:30px" v-show="is_central_department" @click.native="goXLSOptometrist"
                ) {{'report.tasksale.XLSOPT'|translate}}
                template(#icon)
                    i.fa.fa-file-excel-o
            OptiButton.pull-right(style="margin-left:30px" v-show="is_central_department" @click.native="goXLSSalers"
                ) {{'report.tasksale.XLSSALERS'|translate}}
                template(#icon)
                    i.fa.fa-file-excel-o
            OptiButton.pull-right(@click.native="goXLS") {{'report.tasksale.XLS'|translate}}
                template(#icon)
                    i.fa.fa-file-excel-o

        .col-xs-12
            OptiDimmer(:active="loading")
</template>

<script>
    import {API} from '@/js/app/vue/api'
    import {Months} from '@/js/app/vue/helpers/Months'
    import {BudgetHelper} from '@/js/app/vue/helpers/BudgetHelper'
    import {EventBus} from '@/js/app/_bridge/EventBus'
    import {requiredIf} from 'vuelidate/lib/validators'
    import {noZeroIf} from '@/js/vue.validators.js'

    import OptiDate from '@/js/app/vue/components/OptiDate'
    import OptiValidate from '@/js/app/vue/components/OptiValidate'
    import OptiSelectSnapshot from '@/js/app/vue/components/Select/OptiSelectSnapshot'
    import OptiNumber from '@/js/app/vue/components/Inputs/OptiNumber'
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiCheckboxDepartment from '@/js/app/vue/components/Checkbox/OptiCheckboxDepartment'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiReportTasksSales',
        components: {
            OptiDate,
            OptiValidate,
            OptiSelectSnapshot,
            OptiNumber,
            OptiButton,
            OptiCheckboxDepartment,
            OptiDimmer
        },
        props: {

        },
        data() {
            let _lang = 'report.tasksale.table.',
                is_central_department = this.$uac.user.activeDepartmentId === 91

            return {
                is_central_department: is_central_department,

                filter: false,
                column: false,
                noactive: false,

                filter_column: is_central_department ? 'departmentId' : 'employee',
                filter_column_offstock: is_central_department ? 'departmentId' : 'saler',

                columns: [{
                    key: 'default',
                    lang: _lang + 'DEFAULT',
                    selected: true
                }, {
                    key: 'count',
                    lang: _lang + 'TASKCOUNT',
                    selected: true
                }, {
                    key: 'pricing',
                    lang: _lang + 'TASKVALUE',
                    selected: true
                }, {
                    key: 'pricing_avg',
                    lang: _lang + 'TASKVALUEAVG',
                    selected: true
                }, {
                    key: 'count_per_day',
                    lang: _lang + 'TASKCOUNTDAY',
                    selected: true
                }, {
                    key: 'avg_per_day',
                    lang: _lang + 'TASKVALUEDAY',
                    selected: true
                }, {
                    key: 'sale_value',
                    lang: _lang + 'SALEVALUE',
                    selected: true
                }, {
                    key: 'sale_wholesale',
                    lang: _lang + 'SALEWHOLESALE',
                    selected: true
                }, {
                    key: 'saleoffstock_value',
                    lang: _lang + 'SALEOFFSTOCK',
                    selected: true
                }, {
                    key: 'register',
                    lang: _lang + 'REGISTER',
                    selected: true
                }],

                search: {
                    type: 3,
                    date_from: moment().format('YYYY-MM-DD'),
                    date_to: moment().format('YYYY-MM-DD'),
                    one_day: moment().format('YYYY-MM-DD'),
                    month: {
                        year: parseInt(moment().format('YYYY')),
                        month: parseInt(moment().format('M'))
                    },
                    week: {
                        from: {},
                        to: {}
                    },
                    days: 1,
                    quarter: {
                        year: parseInt(moment().isoWeekYear()),
                        quarter: BudgetHelper.getQuarterByWeek(moment())
                    },
                    department_ids: [],

                    xls: ''
                },

                firstYear: 2016,
                startMonth: 1,

                select: {
                    years: [],
                    months: [],
                    quarters: []
                },

                first: {
                    week_date: {
                        from: true,
                        to: true
                    },
                    department_ids: true
                },

                tasks: [],
                sum: [],
                from: '',
                to: '',

                permission: this.$uac.permission('reports.task_sale'),
                loading: false
            }
        },
        validations: {
            search: {
                date_from: {
                    required: requiredIf(search => !search.type)
                },
                date_to: {
                    required: requiredIf(search => !search.type)
                },
                one_day: {
                    required: requiredIf(search => search.type === 3)
                },
                days: {
                    noZero: noZeroIf(search => search.type === 1)
                }
            }
        },
        mounted() {
            this.generateYears()

            if(!this.is_central_department) {
                this.columns.push({
                    key: 'turn_over',
                    lang: 'schedule.budget.table.head.TURNOVER',
                    selected: false
                })
            }
        },
        methods: {
            generateSelectMonths() {
                let m = Months.get(),
                    tmp = []

                if(this.search.month.year === parseInt(moment().isoWeekYear())) {
                    let currentMonths = Months.getToMonth(moment().format('M') === '12' && moment().isoWeek() === 1 ? 1 : moment().format('M'))

                    m.forEach(month => {
                        if(currentMonths.indexOf(month) !== -1) {
                            tmp.push(month)
                        }
                    })

                    m = tmp
                    tmp = []
                }

                if(this.search.month.year === this.firstYear) {
                    let firstYearMonths = Months.getFromMonth(this.startMonth)

                    m.forEach(month => {
                        if(firstYearMonths.indexOf(month) !== -1) {
                            tmp.push(month)
                        }
                    })

                    m = tmp
                }

                if(m.indexOf(this.search.month.month) === -1) {
                    this.search.month.month = m[0]
                }

                this.select.months = Months.parse(m)
            },
            generateSelectQuarters() {
                this.select.quarters = _.range(1, (this.search.quarter.year === parseInt(moment().isoWeekYear()) ?
                    BudgetHelper.getQuarterByWeek(moment()) : 4) + 1)

                if(!this.select.quarters.includes(this.search.quarter.quarter)) {
                    this.search.quarter.quarter = this.select.quarters[0]
                }
            },
            generateSelectYears() {
                for(let y = parseInt(moment().isoWeekYear()); y >= parseInt(this.firstYear); --y) {
                    this.select.years.push(y)
                }

                this.generateSelectMonths()
                this.generateSelectQuarters()
            },
            generateYears() {
                API.get('register/getFirstYear').then(res => {
                    this.firstYear = res.data.firstYear
                    this.startMonth = res.data.startMonth

                    this.generateSelectYears()
                }).catch(() => {
                    console.error('Nie można pobrać roku - weźmie 2006')

                    this.generateSelectYears()
                })
            },

            updateDateFrom(date_from) {
                this.search.date_from = date_from

                this.search.type = 0
            },
            updateDateTo(date_to) {
                this.search.date_to = date_to

                this.search.type = 0
            },
            updateDateOne(date_one) {
                this.search.one_day = date_one

                this.search.type = 3
            },
            updateMonthYear() {
                this.generateSelectMonths()

                this.search.type = 2
            },
            updateWeekDateFrom(week_date_from) {
                this.search.week.from = week_date_from

                if(!this.first.week_date.from) {
                    this.search.type = 4
                } else {
                    this.first.week_date.from = false
                }
            },
            updateWeekDateTo(week_date_to) {
                this.search.week.to = week_date_to

                if(!this.first.week_date.to) {
                    this.search.type = 4
                } else {
                    this.first.week_date.to = false
                }
            },
            updateQuarterYear() {
                this.generateSelectQuarters()

                this.search.type = 5
            },
            updateDays(days) {
                this.search.days = days

                this.search.type = 1
            },
            updateDepartmentIds(ids) {
                this.search.department_ids = ids

                if(this.first.department_ids) {
                    this.first.department_ids = false

                    this.goGenerate()
                }
            },

            goGenerate() {
                this.loading = true

                API.post('reports/task-sale', this.search).then(res => {
                    this.tasks = res.data.data
                    this.sum = res.data.sum
                    this.from = res.data.from
                    this.to = res.data.to
                }).finally(() => {
                    this.loading = false
                }).catch(() => {
                    this.$notify.error('report.tasksale.notify.error.GENERATE')
                })
            },

            isColumnActive(key) {
                let _return = false

                this.columns.forEach(column => {
                    if(column.key === key) {
                        _return = column.selected
                    }
                })

                return _return
            },

            XLSdownload(name) {
                this.search.xls = 'tasksale/raport_prace_sprzedaże_' + name + '_' + this.$uac.user.id +
                    '_' + parseInt(Math.random() * 1000000) + '.xls'

                EventBus.$emit('ng:emit', {
                    name: 'downloadable',
                    data: {
                        name:  this.search.xls,
                        status: true
                    }
                })
            },
            goXLS() {
                this.XLSdownload('ogólne')

                API.post('reports/task-sale/xls', {
                    from: this.from,
                    to: this.to,
                    tasks: this.tasks,
                    xls: this.search.xls
                }).catch(() => {
                    this.$notify.error('report.tasksale.notify.error.XLS')
                })
            },
            goXLSSalers() {
                this.XLSdownload('sprzedawcy')

                API.post('reports/task-sale/xls/all-users', this.search).catch(() => {
                    this.$notify.error('report.tasksale.notify.error.XLS')
                })
            },
            goXLSOptometrist() {
                this.XLSdownload('optometryści')

                API.post('reports/optometrist/xls', this.search).catch(() => {
                    this.$notify.error('report.tasksale.notify.error.XLS')
                })
            }
        }
    }
</script>

<style lang="less" scoped>
    table tr > td {
        white-space: nowrap;
    }
</style>