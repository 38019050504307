<template lang="pug">
    div
        .row
            .col-sm-6.margin
                label(for="period") {{'report.offer.PERIOD'|translate}}
                OptiSelectReportOffersPeriods(id="period" :period="period" @selected="updatePeriod")

            .col-sm-6.text-center.margin
                label(style="display:block") &nbsp;
                .btn-group
                    a.btn.btn-default(v-for="t in types" :class="{'btn-primary active': type === t}"
                        @click="type = t") {{'report.offer.type.' + t.toUpperCase()|translate}}

        .row(v-if="!loading")
            .col-sm-6.margin
                table.table.table-striped.middle(style="margin-bottom:0" v-if="period.offer_department_rate_id")
                    thead
                        tr.text-muted.text-center
                            th(rowspan="2")
                                small(style="display:block") {{'report.offer.average.HEADING'|translate}}
                                span {{period.offerDepartmentRate.department.login}}
                            th(colspan="3")
                                small.mr3 \#{{period.offerDepartmentRate.period.id}}
                                span {{period.offerDepartmentRate.period.label}}
                        tr.text-center
                            th {{'report.offer.average.RATE'|translate}}
                            th {{'report.offer.average.RATIO'|translate}}
                            th

                    tbody
                        tr
                            td.text-right {{'report.offer.average.OFFERS'|translate}}
                            td.text-center
                                a.fa-stack(:title="'report.offer.average.title.offer.RATE'|translate|lower"
                                    :href="getHref(period.offerDepartmentRate, 'offers')" target="_blank")
                                    i.fa.fa-square.fa-stack-2x
                                    i.fa.fa-stack-1x {{period.offerDepartmentRate.offers_rate_avg|number(1)}}
                            td.text-center
                                a.fa-stack(:title="$filters.translate('report.offer.average.title.offer.ratio.PART_1').toLowerCase() + period.offerDepartmentRate.offers_count + $filters.translate('report.offer.average.title.offer.ratio.PART_2').toLowerCase() + period.offerDepartmentRate.tasks_count + $filters.translate('report.offer.average.title.offer.ratio.PART_3').toLowerCase()"
                                    :href="getHref(period.offerDepartmentRate, 'tasks')" target="_blank")
                                    i.fa.fa-circle.fa-stack-2x
                                    i.fa.fa-stack-1x {{period.offerDepartmentRate.ratio_offers_count_tasks_count|number(1)}}
                            td.text-center(rowspan="2")
                                .fa-stack(:title="'report.offer.average.title.SUM'|translate|lower")
                                    i.fa.fa-square.fa-stack-2x.fa-rotate-45
                                    i.fa.fa-stack-1x {{period.offerDepartmentRate.sum|number(1)}}

                        tr
                            td.text-right {{'report.offer.average.TEMPORARY_TASKS'|translate}}
                            td.text-center
                                a.fa-stack(:title="'report.offer.average.title.temporary_task.RATE'|translate|lower"
                                    :href="getHref(period.offerDepartmentRate, 'temporary_tasks')" target="_blank")
                                    i.fa.fa-square.fa-stack-2x
                                    i.fa.fa-stack-1x {{period.offerDepartmentRate.temporary_tasks_rate_avg|number(1)}}
                            td.text-center
                                a.fa-stack(:title="$filters.translate('report.offer.average.title.temporary_task.ratio.PART_1').toLowerCase() + period.offerDepartmentRate.temporary_tasks_count + $filters.translate('report.offer.average.title.temporary_task.ratio.PART_2').toLowerCase() + period.offerDepartmentRate.tasks_count + $filters.translate('report.offer.average.title.temporary_task.ratio.PART_3').toLowerCase()"
                                    :href="getHref(period.offerDepartmentRate, 'tasks')" target="_blank")
                                    i.fa.fa-circle.fa-stack-2x
                                    i.fa.fa-stack-1x {{period.offerDepartmentRate.ratio_temporary_tasks_count_tasks_count|number(1)}}

                .text-muted.text-center(v-else)
                    i.fa.fa-exclamation-circle.mr3
                    span {{'report.offer.average.NO_RELATED'|translate}}

            .col-sm-6
                .input-group(style="width:350px;margin:0 auto")
                    .input-group-addon(style="cursor:pointer;height:45px" @click="sort.asc = !sort.asc")
                        i.fa(:class="{'fa-sort-amount-asc': sort.asc, 'fa-sort-amount-desc': !sort.asc}")

                    select.form-control(style="height:45px" v-model="sort.by")
                        option(v-for="field in sort.fields" :value="field.keys") {{field.translate|translate}}

                .text-center(style="margin-top:15px" v-show="type === 'users'")
                    OptiSwitchToggleButton(:checked="group_by_departments" @input="c => group_by_departments = c")
                        template(#right) {{'report.offer.GROUP_BY_DEPARTMENTS'|translate}}

                div(style="display:flex;align-items:center;justify-content:center;margin-top:15px")
                    b(style="margin-right:7px") {{'report.offer.ALL_DEPARTMENTS'|translate}}
                    OptiReportOffersRate(:rate="rate_central")

        .row
            .col-xs-12.margin
                table.table.table-striped.middle(v-if="!loading")
                    thead
                        tr
                            th.text-right(style="width:50px") #
                            th {{'report.offer.table.' + (type === 'users' ? 'USER' : 'DEPARTMENT')|translate}}
                            th {{'report.offer.table.RATE'|translate}}
                    tbody
                        template(v-for="(rate, index) in sorted_rates")
                            tr(:key="'department-' + rate.id" v-if="group_by_departments && type === 'users' && (!sorted_rates[index - 1] || (sorted_rates[index - 1].user.activeDepartment.id !== rate.user.activeDepartment.id))")
                                td.text-center(colspan="100")
                                    div(style="display:flex;justify-content:center;align-items:center")
                                        .text-muted(style="margin-right:10px") \#{{rate.department_index + 1}}
                                        span(style="margin-right:10px") {{rate.user.activeDepartment.name}}
                                        OptiReportOffersRate(:rate="rates.departments.filter(d => d.department_id === rate.user.activeDepartment.id)[0]")
                            tr(:key="'user-' + rate.id" :class="{'bg-separator': isOwn(rate)}")
                                td.text-right
                                    span(v-if="type === 'users' && group_by_departments") {{rate.index}}
                                    span(v-else) {{index + 1}}
                                td
                                    div(v-if="type === 'users'")
                                        div(v-if="rate.user")
                                            span {{rate.user.firstName}} {{rate.user.lastName}}
                                            small.text-muted.ml3(v-show="rate.user.exchangeDepartment.id"
                                                ) ({{'report.offer.EXCHANGE'|translate}} {{rate.user.exchangeDepartment.name}})
                                        small.text-muted(v-if="rate.user") {{rate.user.activeDepartment.name}}
                                    div(v-if="type === 'departments' && rate.department") {{rate.department.name}}

                                td
                                    OptiReportOffersRate(:rate="rate")

                        tr(v-show="!rates[type].length")
                            td.text-muted.text-center(colspan="100")
                                i.fa.fa-exclamation-circle.mr3
                                span {{'default.table.EMPTY'|translate}}
                    tfoot.text-right(v-show="!group_by_departments")
                        tr
                            td(colspan="100")
                                OptiButton(@click.native="excel") Excel
                                    template(#icon)
                                        i.fa.fa-file-excel-o

                OptiDimmer(:active="loading")
</template>

<script>
    import {API} from '@/js/app/vue/api'
    import {ReportOffersRatesHelper} from '@/js/app/vue/components/Reports/Offers/ReportOffersRatesHelper'
    import {DownloadFileHelper} from '@/js/app/vue/helpers/DownloadFileHelper'

    import OptiSwitchToggleButton from '@/js/app/vue/components/Button/OptiSwitchToggleButton'
    import OptiSelectReportOffersPeriods from '@/js/app/vue/components/Select/OptiSelectReportOffersPeriods'
    import OptiSortFilter from '@/js/app/vue/components/Catalog/Filters/OptiSortFilter'
    import OptiReportOffersRate from '@/js/app/vue/components/Reports/Offers/OptiReportOffersRate'
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiReportOffers',
        components: {
            OptiSwitchToggleButton,
            OptiSelectReportOffersPeriods,
            OptiSortFilter,
            OptiReportOffersRate,
            OptiButton,
            OptiDimmer
        },
        data() {
            return {
                types: ['users', 'departments'],
                type: 'users',
                period: null,
                rates: [],
                rate_central: null,
                sort: {
                    fields: [{
                        keys: ['sum', 'offers_rate_avg', 'ratio_offers_count_tasks_count', 'temporary_tasks_rate_avg', 'ratio_temporary_tasks_count_tasks_count'],
                        translate: 'report.offer.rate.SUM'
                    }, {
                        keys: ['offers_rate_avg', 'sum', 'ratio_offers_count_tasks_count', 'temporary_tasks_rate_avg', 'ratio_temporary_tasks_count_tasks_count'],
                        translate: 'report.offer.rate.offer.RATE'
                    }, {
                        keys: ['ratio_offers_count_tasks_count', 'sum', 'offers_rate_avg', 'temporary_tasks_rate_avg', 'ratio_temporary_tasks_count_tasks_count'],
                        translate: 'report.offer.rate.offer.RATIO'
                    }, {
                        keys: ['temporary_tasks_rate_avg', 'sum', 'offers_rate_avg', 'ratio_offers_count_tasks_count', 'ratio_temporary_tasks_count_tasks_count'],
                        translate: 'report.offer.rate.temporary_task.RATE'
                    }, {
                        keys: ['ratio_temporary_tasks_count_tasks_count', 'sum', 'offers_rate_avg', 'ratio_offers_count_tasks_count', 'temporary_tasks_rate_avg'],
                        translate: 'report.offer.rate.temporary_task.RATIO'
                    }],
                    asc: false,
                    by: ['sum', 'offers_rate_avg', 'ratio_offers_count_tasks_count', 'temporary_tasks_rate_avg', 'ratio_temporary_tasks_count_tasks_count']
                },
                group_by_departments: false,

                loading: true
            }
        },
        methods: {
            updatePeriod(period) {
                this.loading = true

                this.period = period

                if(this.period.offer_department_rate_id) {
                    this.period.offerDepartmentRate.period.label =
                        moment(this.period.offerDepartmentRate.period.start_date).format('YYYY-MM-DD') + ' - ' +
                        moment(this.period.offerDepartmentRate.period.end_date).format('YYYY-MM-DD')
                }

                API.get('reports/offers/rates/' + this.period.id).then(res => {
                    this.rates = res.data

                    let key_central = parseInt(_.findKey(this.rates.departments, d => d.department_id === 91))

                    this.rate_central = this.rates.departments[key_central]
                    this.rates.departments.splice(key_central, 1)
                }).finally(() => {
                    this.loading = false
                }).catch(() => {
                    this.$notify.error('report.offer.notify.error.LOAD')
                })
            },
            isOwn(rate) {
                return !this.$uac.user.activeRole.admin && ReportOffersRatesHelper.isOwn(rate)
            },
            getHref(rate, type) {
                let {state, params} = ReportOffersRatesHelper.getHref(rate, type)

                return this.$state.href(state, params)
            },
            excel() {
                let filename = 'rates/reports/' + this.period.id + ' ' +
                    this.period.start_date.split(' ')[0] + ' ' + this.period.end_date.split(' ')[0] + '.xls'

                DownloadFileHelper.post('reports/offers/rates/excel', filename, {
                    type: this.type,
                    rows: this.sorted_rates
                })
            }
        },
        computed: {
            sorted_rates() {
                let sort_by = _.cloneDeep(this.sort.by),
                    sort_asc_desc = this.sort.asc ? _.fill(_.range(0, this.sort.by.length), 'asc') :
                        _.fill(_.range(0, this.sort.by.length), 'desc')

                if(this.type === 'users') {
                    let orderByDepartmentIds = _.orderBy(this.rates.departments, sort_by, sort_asc_desc).map(d => d.department_id)

                    this.rates.users.forEach(user => {
                        user.department_index = orderByDepartmentIds.indexOf(parseInt(user.user.activeDepartmentId))
                    })

                    if(this.group_by_departments) {
                        sort_by.unshift('department_index')
                        sort_asc_desc.unshift('asc')
                    }
                }

                let orderBy = _.orderBy(this.rates[this.type], sort_by, sort_asc_desc)

                if(this.type === 'users') {
                    let department_index = orderBy[0].department_index,
                        index = 0

                    orderBy.forEach(o => {
                        if(department_index !== o.department_index) {
                            department_index = o.department_index
                            index = 0
                        }

                        o.index = ++index
                    })

                    orderBy = orderBy.filter(o => o.department_index !== -1)
                }

                return orderBy
            }
        }
    }
</script>

<style lang="less" scoped>
    .margin {
        margin-bottom: 15px;
    }

    .fa-stack {
        color: #fff;

        &:hover, &:focus {
            color: #fff;
        }

        > .fa-stack-2x {
            color: royalblue;

            -webkit-text-stroke: 1px powderblue;
            text-stroke: 1px powderblue;

            &.fa-rotate-45 {
                transform: rotate(45deg);
            }
        }
    }
</style>