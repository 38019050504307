<template lang="pug">
    OptiDialog.fullscreen
        template(#header)
            i.fa.fa-eye.mr3
            span {{'tasks.offer.thickness.HEADING'|translate}}

            i.fa.fa-lg.fa-remove.pull-right(style="cursor:pointer;padding:3px" :title="'default.CLOSE'|translate"
                @click="goClose")

        template(#body)
            .row(style="margin:10px 0")
                div(v-for="product in products" :class="{'col-md-4': products.length < 4, 'col-md-3': products.length === 4}")
                    OptiTaskDetailsOfferProduct(
                        :product="product"
                        :offer-temporary-task-id="offer_temporary_task_id"
                        :rate-save="rateSave"

                        :rate="rate"

                        @selected="id => offer_temporary_task_id = id"
                        @rate-save="$emit('rate-save')"
                        @reload="$emit('reload-offer')")

                .col-xs-12(style="margin-bottom:70px")
                    .row(v-if="!loading[offer_temporary_task_id]")
                        .col-xs-12
                            .table-responsive
                                table.table.table-striped.middle.text-center(style="margin-bottom:0")
                                    tbody
                                        tr
                                            td(:rowspan="data[offer_temporary_task_id].lenses.name ? 1 : 2")
                                                a.link(v-if="data[offer_temporary_task_id].lenses.name"
                                                    :href="$state.href('app.catalog.category', {id: data[offer_temporary_task_id].lenses.hoya_lens_category_id, isOffer: 1, taskId: offer_temporary_task_id})"
                                                    target="_blank") {{data[offer_temporary_task_id].lenses.name}}
                                                .warn(v-else)
                                                    i.fa.fa-exclamation-circle.mr3
                                                    span {{'tasks.offer.lenses.none.LENSES'|translate}}
                                            td(:rowspan="data[offer_temporary_task_id].lenses_to_compare.name ? 1 : 2")
                                                a.link(v-if="data[offer_temporary_task_id].lenses_to_compare.name"
                                                    :href="$state.href('app.catalog.category', {id: data[offer_temporary_task_id].lenses_to_compare.hoya_lens_category_id, isOffer: 1, taskId: offer_temporary_task_id})"
                                                    target="_blank") {{data[offer_temporary_task_id].lenses_to_compare.name}}
                                                .warn(v-else)
                                                    i.fa.fa-exclamation-circle.mr3
                                                    span {{'tasks.offer.lenses.none.LENSES_TO_COMPARE'|translate}}
                                        tr
                                            td(v-if="data[offer_temporary_task_id].lenses.name")
                                                OptiPictograms(:pack-details="data[offer_temporary_task_id].lenses.pictograms")
                                            td(v-if="data[offer_temporary_task_id].lenses_to_compare.name")
                                                OptiPictograms(:pack-details="data[offer_temporary_task_id].lenses_to_compare.pictograms")

                                        tr
                                            td
                                                a(:href="'api/oma/offer/image/' + offer_temporary_task_id + '.jpg'" target="_blank")
                                                    img(style="width:100%" :src="'api/oma/offer/image/' + offer_temporary_task_id + '.jpg?ts=' + data[offer_temporary_task_id].ts")
                                            td
                                                a(:href="'api/oma/offer_compare/image/' + offer_temporary_task_id + '.jpg'" target="_blank")
                                                    img(style="width:100%" :src="'api/oma/offer_compare/image/' + offer_temporary_task_id + '.jpg?ts=' + data[offer_temporary_task_id].ts")

                            .table-responsive
                                table.table.table-striped.middle.text-center
                                    tbody
                                        tr
                                            th(style="width:16.65%" v-if="data[offer_temporary_task_id].lenses.oma.exists") {{'prescription.RIGHT'|translate}}
                                            th(style="width:16.65%" v-if="data[offer_temporary_task_id].lenses.oma.exists") {{'prescription.LEFT'|translate}}
                                            td(style="width:33.33%" v-else colspan="2" rowspan="6")
                                                .warn
                                                    i.fa.fa-exclamation-circle.mr3
                                                    span {{'tasks.offer.thickness.notify.NO_THICKNESS'|translate}}

                                                OptiButton(type="info" style="margin-top:15px" :disabled="data[offer_temporary_task_id].lenses.disabled_thickness"
                                                    @click.native="loadThickness('offer')") {{'tasks.compare.BTN'|translate}}
                                                    template(#icon)
                                                        i.fa.fa-refresh
                                            th(style="width:33.33%")
                                            th(style="width:16.65%" v-if="data[offer_temporary_task_id].lenses_to_compare.oma.exists") {{'prescription.RIGHT'|translate}}
                                            th(style="width:16.65%" v-if="data[offer_temporary_task_id].lenses_to_compare.oma.exists") {{'prescription.LEFT'|translate}}
                                            td(style="width:33.33%" v-else colspan="2" rowspan="6")
                                                .warn
                                                    i.fa.fa-exclamation-circle.mr3
                                                    span {{'tasks.offer.thickness.notify.NO_THICKNESS'|translate}}

                                                OptiButton(type="info" style="margin-top:15px" :disabled="data[offer_temporary_task_id].lenses_to_compare.disabled_thickness"
                                                    @click.native="loadThickness('offer_compare')") {{'tasks.compare.BTN'|translate}}
                                                    template(#icon)
                                                        i.fa.fa-refresh

                                        tr
                                            td(v-if="data[offer_temporary_task_id].lenses.oma.exists"
                                                :class="{'warn': data[offer_temporary_task_id].lenses.oma.right.min > data[offer_temporary_task_id].lenses_to_compare.oma.right.min}"
                                                ) {{data[offer_temporary_task_id].lenses.oma.right.min|number(2)|mm}}
                                            td(v-if="data[offer_temporary_task_id].lenses.oma.exists"
                                                :class="{'warn': data[offer_temporary_task_id].lenses.oma.left.min > data[offer_temporary_task_id].lenses_to_compare.oma.left.min}"
                                                ) {{data[offer_temporary_task_id].lenses.oma.left.min|number(2)|mm}}
                                            th {{'tasks.compare.data.MIN'|translate}}
                                            td(v-if="data[offer_temporary_task_id].lenses_to_compare.oma.exists"
                                                :class="{'warn': data[offer_temporary_task_id].lenses.oma.right.min < data[offer_temporary_task_id].lenses_to_compare.oma.right.min}"
                                                ) {{data[offer_temporary_task_id].lenses_to_compare.oma.right.min|number(2)|mm}}
                                            td(v-if="data[offer_temporary_task_id].lenses_to_compare.oma.exists"
                                                :class="{'warn': data[offer_temporary_task_id].lenses.oma.left.min < data[offer_temporary_task_id].lenses_to_compare.oma.left.min}"
                                                ) {{data[offer_temporary_task_id].lenses_to_compare.oma.left.min|number(2)|mm}}

                                        tr
                                            td(v-if="data[offer_temporary_task_id].lenses.oma.exists"
                                                :class="{'warn': data[offer_temporary_task_id].lenses.oma.right.max > data[offer_temporary_task_id].lenses_to_compare.oma.right.max}"
                                                ) {{data[offer_temporary_task_id].lenses.oma.right.max|number(2)|mm}}
                                            td(v-if="data[offer_temporary_task_id].lenses.oma.exists"
                                                :class="{'warn': data[offer_temporary_task_id].lenses.oma.left.max > data[offer_temporary_task_id].lenses_to_compare.oma.left.max}"
                                                ) {{data[offer_temporary_task_id].lenses.oma.left.max|number(2)|mm}}
                                            th {{'tasks.compare.data.MAX'|translate}}
                                            td(v-if="data[offer_temporary_task_id].lenses_to_compare.oma.exists"
                                                :class="{'warn': data[offer_temporary_task_id].lenses.oma.right.max < data[offer_temporary_task_id].lenses_to_compare.oma.right.max}"
                                                ) {{data[offer_temporary_task_id].lenses_to_compare.oma.right.max|number(2)|mm}}
                                            td(v-if="data[offer_temporary_task_id].lenses_to_compare.oma.exists"
                                                :class="{'warn': data[offer_temporary_task_id].lenses.oma.left.max < data[offer_temporary_task_id].lenses_to_compare.oma.left.max}"
                                                ) {{data[offer_temporary_task_id].lenses_to_compare.oma.left.max|number(2)|mm}}

                                        tr
                                            td(v-if="data[offer_temporary_task_id].lenses.oma.exists") {{data[offer_temporary_task_id].lenses.oma.right.fi|mm}}
                                            td(v-if="data[offer_temporary_task_id].lenses.oma.exists") {{data[offer_temporary_task_id].lenses.oma.left.fi|mm}}
                                            th Fi
                                            td(v-if="data[offer_temporary_task_id].lenses_to_compare.oma.exists") {{data[offer_temporary_task_id].lenses_to_compare.oma.right.fi|mm}}
                                            td(v-if="data[offer_temporary_task_id].lenses_to_compare.oma.exists") {{data[offer_temporary_task_id].lenses_to_compare.oma.left.fi|mm}}

                                        tr
                                            td(v-if="data[offer_temporary_task_id].lenses.oma.exists")
                                                span(v-if="data[offer_temporary_task_id].lenses.curve.right") {{data[offer_temporary_task_id].lenses.curve.right}} dpt
                                                span(v-else) -

                                            td(v-if="data[offer_temporary_task_id].lenses.oma.exists")
                                                span(v-if="data[offer_temporary_task_id].lenses.curve.left") {{data[offer_temporary_task_id].lenses.curve.left}} dpt
                                                span(v-else) -
                                            th {{'tasks.logs.lenses.CURVE'|translate}}
                                            td(v-if="data[offer_temporary_task_id].lenses_to_compare.oma.exists")
                                                span(v-if="data[offer_temporary_task_id].lenses_to_compare.curve.right") {{data[offer_temporary_task_id].lenses_to_compare.curve.right}} dpt
                                                span(v-else) -

                                            td(v-if="data[offer_temporary_task_id].lenses_to_compare.oma.exists")
                                                span(v-if="data[offer_temporary_task_id].lenses_to_compare.curve.left") {{data[offer_temporary_task_id].lenses_to_compare.curve.left}} dpt
                                                span(v-else) -

                                        tr
                                            td(v-if="data[offer_temporary_task_id].lenses.oma.exists"
                                                :class="{'warn': data[offer_temporary_task_id].lenses.oma.right.weight > data[offer_temporary_task_id].lenses_to_compare.oma.right.weight}"
                                                ) {{data[offer_temporary_task_id].lenses.oma.right.weight|number(2)}} g
                                            td(v-if="data[offer_temporary_task_id].lenses.oma.exists"
                                                :class="{'warn': data[offer_temporary_task_id].lenses.oma.left.weight > data[offer_temporary_task_id].lenses_to_compare.oma.left.weight}"
                                                ) {{data[offer_temporary_task_id].lenses.oma.left.weight|number(2)}} g
                                            th {{'tasks.compare.data.weight.LENSES'|translate}}
                                            td(v-if="data[offer_temporary_task_id].lenses_to_compare.oma.exists"
                                                :class="{'warn': data[offer_temporary_task_id].lenses.oma.right.weight < data[offer_temporary_task_id].lenses_to_compare.oma.right.weight}"
                                                ) {{data[offer_temporary_task_id].lenses_to_compare.oma.right.weight|number(2)}} g
                                            td(v-if="data[offer_temporary_task_id].lenses_to_compare.oma.exists"
                                                :class="{'warn': data[offer_temporary_task_id].lenses.oma.left.weight < data[offer_temporary_task_id].lenses_to_compare.oma.left.weight}"
                                                ) {{data[offer_temporary_task_id].lenses_to_compare.oma.left.weight|number(2)}} g

                                        tr(v-if="data[offer_temporary_task_id].frame.weight")
                                            td(colspan="2") {{data[offer_temporary_task_id].frame.weight}} g
                                            th {{'tasks.compare.data.weight.FRAME'|translate}}
                                            td(colspan="2") {{data[offer_temporary_task_id].frame.weight}} g

                                        tr(v-if="(data[offer_temporary_task_id].lenses.oma.exists || data[offer_temporary_task_id].lenses_to_compare.oma.exists) && data[offer_temporary_task_id].frame.weight")
                                            td(colspan="2" :class="{'warn': data[offer_temporary_task_id].lenses.oma.right.weight + data[offer_temporary_task_id].lenses.oma.left.weight + data[offer_temporary_task_id].frame.weight > data[offer_temporary_task_id].lenses_to_compare.oma.right.weight + data[offer_temporary_task_id].lenses_to_compare.oma.left.weight + data[offer_temporary_task_id].frame.weight}")
                                                span(v-if="data[offer_temporary_task_id].lenses.oma.exists && data[offer_temporary_task_id].frame.weight") {{data[offer_temporary_task_id].lenses.oma.right.weight + data[offer_temporary_task_id].lenses.oma.left.weight + data[offer_temporary_task_id].frame.weight|number(2)}} g
                                            th {{'tasks.compare.data.weight.SUM'|translate}}
                                            td(colspan="2" :class="{'warn': data[offer_temporary_task_id].lenses.oma.right.weight + data[offer_temporary_task_id].lenses.oma.left.weight + data[offer_temporary_task_id].frame.weight < data[offer_temporary_task_id].lenses_to_compare.oma.right.weight + data[offer_temporary_task_id].lenses_to_compare.oma.left.weight + data[offer_temporary_task_id].frame.weight}")
                                                span(v-if="data[offer_temporary_task_id].lenses_to_compare.oma.exists && data[offer_temporary_task_id].frame.weight") {{data[offer_temporary_task_id].lenses_to_compare.oma.right.weight + data[offer_temporary_task_id].lenses_to_compare.oma.left.weight + data[offer_temporary_task_id].frame.weight|number(2)}} g

                    OptiDimmer(:active="loading[offer_temporary_task_id]")

        template(#footer)
            OptiButton.pull-right(:disabled="disabled" @click.native="goClose") {{'default.OK'|translate}}
                template(#icon)
                    i.fa.fa-check
</template>

<script>
    import {API} from '@/js/app/vue/api'

    import OptiDialog from '@/js/app/vue/components/OptiDialog'
    import OptiTaskDetailsOfferProduct
        from '@/js/app/vue/components/Tasks/Details/Offer/OptiTaskDetailsOfferProduct'
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiPictograms from '@/js/app/vue/components/Pictograms/OptiPictograms'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiTaskDetailsOfferDialogsCompare',
        components: {
            OptiDialog,
            OptiTaskDetailsOfferProduct,
            OptiButton,
            OptiPictograms,
            OptiDimmer
        },
        props: {
            products: {
                type: Array,
                required: true
            },
            rateSave: {
                required: true
            },

            rate: {
                type: Object | Array,
                required: true
            }
        },
        data() {
            let offer_temporary_task_id = parseInt(this.$state.params.id),
                loading = {}

            loading[offer_temporary_task_id] = true

            return {
                data: {},
                offer_temporary_task_id: offer_temporary_task_id,

                refresh: false,
                disabled: false,
                loading: loading
            }
        },

        mounted() {
            document.body.style.overflow = 'hidden'

            this.products.forEach(product => {
                product.pivot.tasks.forEach(task => {
                    this.loadProduct(task.offer_temporary_task_id)
                })
            })
        },
        destroyed() {
            document.body.style.overflow = ''
        },

        methods: {
            loadProduct(offer_temporary_task_id) {
                this.$set(this.loading, offer_temporary_task_id, true)

                API.get('offer/task/compare/' + offer_temporary_task_id).then(res => {
                    this.data[offer_temporary_task_id] = res.data
                }).finally(() => {
                    this.$set(this.loading, offer_temporary_task_id, false)
                }).catch(() => {
                    this.$notify.error('tasks.offer.thickness.notify.error.LOAD')
                })
            },
            loadThickness(type) {
                this.$set(this.loading, this.offer_temporary_task_id, true)

                let current = this.offer_temporary_task_id === parseInt(this.$state.params.id)

                API.get('task/data/' + type + '/' + this.offer_temporary_task_id + '/' + (current ? '0' : '1')).then(res => {
                    if(res.data.ok) {
                        this.loadProduct(this.offer_temporary_task_id)

                        if(current) {
                            this.refresh = true
                        }

                        this.$notify.success('tasks.frame.oma.notify.THICKNESS', true, true)
                    } else if('error' in res.data) {
                        this.$set(this.loading, this.offer_temporary_task_id, false)

                        this.$notify.error(res.data.error, false)
                    } else {
                        this.$set(this.loading, this.offer_temporary_task_id, false)

                        this.$notify.error('tasks.frame.oma.notify.error.OMA')
                    }
                }).catch(() => {
                    this.$set(this.loading, this.offer_temporary_task_id, false)

                    this.$notify.error('tasks.frame.oma.notify.error.OMA')
                })
            },
            goClose() {
                if(this.disabled) {
                    return false
                }

                this.disabled = true

                API.get('offer/task/compare/logs/' + this.offer_temporary_task_id).then(res => {
                    if(res.data.includes(parseInt(this.$state.params.id))) {
                        this.$emit('reload-history')
                    }
                }).finally(() => {
                    this.disabled = false

                    this.$emit('closed', this.refresh)
                }).catch(() => {
                    this.$notify.error('tasks.offer.thickness.notify.error.COMPARE_LOGS')
                })
            }
        }
    }
</script>