<template lang="pug">
    .row(style="margin-left:0;margin-right:0")
        .col-md-6.col-xs-12
            .row
                .col-xs-12.col-sm-3.p-sm
                    .panel.widget.action-panel
                        a.panel-body.text-center(:href="$state.href('app.tasks.notaccepted', {resetSearch: true})")
                            h2.mt0 {{dashboard.simpleData.notaccepted || 0}}
                            .mb0.text-muted {{'dashboard.simple.NOTACCEPTED'|translate}}
                .col-xs-12.col-sm-3.p-sm
                    .panel.widget.action-panel
                        a.panel-body.text-center(:href="$state.href('app.tasks.accepted', {resetSearch: true})")
                            h2.mt0 {{dashboard.simpleData.accepted || 0}}
                            .mb0.text-muted {{'dashboard.simple.ACCEPTED'|translate}}
                .col-xs-12.col-sm-3.p-sm
                    .panel.widget.action-panel
                        a.panel-body.text-center(:href="$state.href('app.tasks.notaccepted', {search: ['putBack:=:yes']})")
                            h2.mt0
                                i.task-icon.put-back.mr3(style="margin-left:-26px")
                                span {{dashboard.simpleData.putBack || 0}}
                            .mb0.text-muted {{'dashboard.simple.BACK'|translate}}
                .col-xs-12.col-sm-3.p-sm
                    .panel.widget.action-panel
                        a.panel-body.text-center(:href="$state.href('app.tasks.all', {search: ['status:!=:cancelled', 'status:!=:done', 'status:!=:notaccepted', 'status:!=:sent', 'status:!=:received']})")
                            h2.mt0 {{dashboard.simpleData.inProgress || 0}}
                            .mb0.text-muted {{'dashboard.simple.DURING'|translate}}

        .col-md-6.col-xs-12
            .col-xs-12.col-sm-3.p-sm
                .panel.widget.action-panel
                    a.panel-body.text-center(:href="$state.href('app.tasks.sent')")
                        h2.mt0 {{dashboard.simpleData.sent || 0}}
                        .mb0.text-muted {{'dashboard.simple.SENT'|translate}}
            .col-xs-12.col-sm-3.p-sm
                .panel.widget.action-panel
                    a.panel-body.text-center(:href="$state.href('app.tasks.received')")
                        h2.mt0 {{dashboard.simpleData.received || 0}}
                        .mb0.text-muted {{'dashboard.simple.RECEIVED'|translate}}
            .col-xs-12.col-sm-3.p-sm
                .panel.widget.action-panel
                    a.panel-body.text-center(:href="$state.href('app.tasks.completed', {search: ['deadline:=:yes']})")
                        h2.mt0
                            i.task-icon.deadline.mr3(style="margin-left:-26px")
                            span {{dashboard.simpleData.deadline || 0}}
                            span(v-show="dashboard.simpleData.deadline_today" :title="'dashboard.DEADLINETODAY'|translate"
                                ) ({{dashboard.simpleData.deadline_today}})
                        .mb0.text-muted {{'dashboard.simple.DEADLINE'|translate}}
            .col-xs-12.col-sm-3.p-sm
                .panel.widget.action-panel
                    a.panel-body.text-center(:href="$state.href('app.tasks.all', {search: ['status:!=:cancelled', 'status:!=:done', 'status:!=:notaccepted', 'status:!=:received', 'status:!=:sent', 'deadline:=:yes']})")
                        h2.mt0
                            i.task-icon.deadline.mr3(style="margin-left:-26px")
                            span {{dashboard.simpleData.delay || 0}}
                        .mb0.text-muted {{'dashboard.simple.DELAY'|translate}}

        .col-md-6
            OptiNotifications

        .col-md-6
            .row
                .col-xs-12
                    OptiTransferDashboard

                .col-sm-6
                    .panel.panel-transparent
                        .panel-heading
                            i.fa.fa-map-marker.fa-fw.text-muted.mr3
                            span.text-muted {{'dashboard.simple.MAP'|translate}}
                        .action-div.clearfix
                            AngularComponent(:component="map")

                    .text-center(style="margin-bottom:20px")
                        OptiCatalogOfferBtnPhotoSlides

                .col-sm-6
                    OptiReportOffersRateDashboard

                    OptiRoleExceptionDashboard
</template>

<script>
    import OptiNotifications from '@/js/app/vue/components/Dashboard/Simple/Notification/OptiNotifications'
    import OptiTransferDashboard from '@/js/app/vue/components/Warehouse/Transfers/OptiTransferDashboard'
    import AngularComponent from '@/js/app/_bridge/components/AngularComponent'
    import OptiReportOffersRateDashboard
        from '@/js/app/vue/components/Reports/Offers/OptiReportOffersRateDashboard'
    import OptiRoleExceptionDashboard
        from '@/js/app/vue/components/Users/Roles/Exceptions/OptiRoleExceptionDashboard'
    import OptiCatalogOfferBtnPhotoSlides
        from "@/js/app/vue/components/Catalog/Offer/OptiCatalogOfferBtnPhotoSlides.vue";

    export default {
        name: 'OptiDashboardSimple',
        components: {
            OptiCatalogOfferBtnPhotoSlides,
            OptiNotifications,
            OptiTransferDashboard,
            AngularComponent,
            OptiReportOffersRateDashboard,
            OptiRoleExceptionDashboard,
        },
        props: {
            dashboard: {
                type: Object,
                required: true
            },
            departmentId: {
                type: Number,
                required: true
            }
        },
        computed: {
            map() {
                let vm = this

                return {
                    template: `<layout-map department-id="$ctrl.departmentId" width="300px" height="300px"></layout-map>`,
                    $ctrl: {
                        get departmentId() {return vm.departmentId}
                    }
                }
            }
        }
    }
</script>