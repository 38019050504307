<template lang="pug">
    td(:class="{'border-pink': measurementApp === 2 || (previousLens.measurement.ipad.measurement_id && previousLens.measurement.measurementJawroVisu.measurement_id)}"
        colspan="12")
        .row(style="margin:0")
            div(style="padding:0" :class="{'col-lg-6': mode === 'task', 'col-sm-6': mode !== 'task'}")
                .table-responsive
                    table.table.middle
                        thead
                            tr
                                td
                                td.text-center.header(colspan="100") VisuOpti
                            tr(v-show="!previousLens.measurement.measurement_jawro_visu_id")
                                td.text-center.warn(colspan="5")
                                    i.fa.fa-exclamation-circle.mr3
                                    span {{'previouslens.NODATA'|translate}}
                                    span(style="margin-left:3px" v-show="!previousLens.task_id && (previousLens.r_sph > 2 || previousLens.r_sph < -2 || previousLens.l_sph > 2 || previousLens.l_sph < -2)"
                                        ) {{'previouslens.NODATAREQUIRED'|translate}}
                            tr
                                td.bold
                                td.bold.text-center(:colspan="!previousLens.task_id ? 2 : 1") {{'prescription.RIGHT'|translate}}
                                td.bold.text-center(style="border-left:1px solid #aaa"
                                    :colspan="!previousLens.task_id ? 2 : 1") {{'prescription.LEFT'|translate}}
                            tr
                                td.bold
                                td.bold.text-center
                                    span {{'previouslens.ipad.IPAD'|translate}}
                                    .warn(style="display:inline-block;padding-left:3px" :title="'previouslens.ipad.NEAR'|translate"
                                        v-show="previousLens.measurement.measurementJawroVisu.measurement_id && previousLens.distance_id === 2") *
                                td.bold.text-center(v-show="!previousLens.task_id") {{'previouslens.ipad.DIOPTROMIERZ'|translate}}
                                td.bold.text-center(style="border-left:1px solid #aaa")
                                    span {{'previouslens.ipad.IPAD'|translate}}
                                    .warn(style="display:inline-block;padding-left:3px" :title="'previouslens.ipad.NEAR'|translate"
                                        v-show="previousLens.measurement.measurementJawroVisu.measurement_id && previousLens.distance_id === 2") *
                                td.bold.text-center(v-show="!previousLens.task_id") {{'previouslens.ipad.DIOPTROMIERZ'|translate}}

                        tbody
                            tr
                                td.bold PD/2
                                td.text-center(:class="{'its-error': !measurementValidate.pdR}")
                                    span(v-show="previousLens.measurement.measurementJawroVisu.pd_r"
                                        ) {{previousLens.measurement.measurementJawroVisu.pd_r|number(2)}}
                                    small(style="display:block" v-show="measurementValidate.delta.pdR"
                                        ) (&Delta; {{measurementValidate.delta.pdR|number(2)|mm}})
                                td(v-show="!previousLens.task_id")
                                    OptiNumber.text-center(style="width:80px;margin:0 auto"
                                        :class="{'its-error': !measurementValidate.pdR}"
                                        :header="$filters.translate('previouslens.ipad.DIOPTROMIERZ') + ' (' + $filters.translate('prescription.RIGHT') + ') - PD/2'"
                                        :value="previousLens.measurement.pdR" @confirmed="v => $emit('update-dioprometer', 'pdR', v)"
                                        :disabled="disabled || !!previousLens.task_id" :float="true"
                                        ) {{previousLens.measurement.pdR|number(2)}}
                                td.text-center(style="border-left:1px solid #aaa" :class="{'its-error': !measurementValidate.pdL}")
                                    span(v-show="previousLens.measurement.measurementJawroVisu.pd_l"
                                        ) {{previousLens.measurement.measurementJawroVisu.pd_l|number(2)}}
                                    small(style="display:block" v-show="measurementValidate.delta.pdL"
                                        ) (&Delta; {{measurementValidate.delta.pdL|number(2)|mm}})
                                td(v-show="!previousLens.task_id")
                                    OptiNumber.text-center(style="width:80px;margin:0 auto"
                                        :class="{'its-error': !measurementValidate.pdL}"
                                        :header="$filters.translate('previouslens.ipad.DIOPTROMIERZ') + ' (' + $filters.translate('prescription.LEFT') + ') - PD/2'"
                                        :value="previousLens.measurement.pdL" @confirmed="v => $emit('update-dioprometer', 'pdL', v)"
                                        :disabled="disabled || !!previousLens.task_id" :float="true") {{previousLens.measurement.pdL|number(2)}}
                            tr
                                td.bold BT
                                td.text-center(:class="{'its-error': !measurementValidate.btR}")
                                    span(v-show="previousLens.measurement.measurementJawroVisu.bt_r"
                                        ) {{previousLens.measurement.measurementJawroVisu.bt_r|number(2)}}
                                    small(style="display:block" v-show="measurementValidate.delta.btR") (&Delta; {{measurementValidate.delta.btR|number(2)|mm}})
                                td(v-show="!previousLens.task_id")
                                    OptiNumber.text-center(style="width:80px;margin:0 auto"
                                        :class="{'its-error': !measurementValidate.btR}"
                                        :header="$filters.translate('previouslens.ipad.DIOPTROMIERZ') + ' (' + $filters.translate('prescription.RIGHT') + ') - BT'"
                                        :value="previousLens.measurement.btR" @confirmed="v => $emit('update-dioprometer', 'btR', v)"
                                        :disabled="disabled || !!previousLens.task_id" :float="true") {{previousLens.measurement.btR|number(2)}}
                                td.text-center(style="border-left:1px solid #aaa" :class="{'its-error': !measurementValidate.btL}")
                                    span(v-show="previousLens.measurement.measurementJawroVisu.bt_l"
                                        ) {{previousLens.measurement.measurementJawroVisu.bt_l|number(2)}}
                                    small(style="display:block" v-show="measurementValidate.delta.btL"
                                        ) (&Delta; {{measurementValidate.delta.btL|number(2)|mm}})
                                td(v-show="!previousLens.task_id")
                                    OptiNumber.text-center(style="width:80px;margin:0 auto"
                                        :class="{'its-error': !measurementValidate.btL}"
                                        :header="$filters.translate('previouslens.ipad.DIOPTROMIERZ') + ' (' + $filters.translate('prescription.LEFT') + ') - BT'"
                                        :value="previousLens.measurement.btL" @confirmed="v => $emit('update-dioprometer', 'btL', v)"
                                        :disabled="disabled || !!previousLens.task_id" :float="true") {{previousLens.measurement.btL|number(2)}}

            div(style="margin-top:7px" :class="{'col-lg-6': mode === 'task', 'col-sm-6': mode !== 'task'}")
                #interview-measurement(:class="{'pupil-centre': previousLens.measurement.measurementJawroVisu.method === 1}")
                    #pdR(v-if="previousLens.measurement.measurementJawroVisu.pd_r || previousLens.task_pd_bt"
                        ) {{previousLens.measurement.measurementJawroVisu.pd_r ? previousLens.measurement.measurementJawroVisu.pd_r : previousLens.task_pd_bt.pd_r|number(2)}}
                    #pdL(v-if="previousLens.measurement.measurementJawroVisu.pd_l || previousLens.task_pd_bt"
                        ) {{previousLens.measurement.measurementJawroVisu.pd_l ? previousLens.measurement.measurementJawroVisu.pd_l : previousLens.task_pd_bt.pd_l|number(2)}}

                    #btR(v-if="previousLens.measurement.measurementJawroVisu.bt_r || previousLens.task_pd_bt"
                        ) {{previousLens.measurement.measurementJawroVisu.bt_r ? previousLens.measurement.measurementJawroVisu.bt_r : previousLens.task_pd_bt.bt_r|number(2)}}
                    #btL(v-if="previousLens.measurement.measurementJawroVisu.bt_r || previousLens.task_pd_bt"
                        ) {{previousLens.measurement.measurementJawroVisu.bt_r ? previousLens.measurement.measurementJawroVisu.bt_r : previousLens.task_pd_bt.bt_l|number(2)}}

                    #width(v-show="previousLens.measurement.measurementJawroVisu.frame_width"
                        ) {{previousLens.measurement.measurementJawroVisu.frame_width|number(2)}}
                    #height(v-show="previousLens.measurement.measurementJawroVisu.frame_height"
                        ) {{previousLens.measurement.measurementJawroVisu.frame_height|number(2)}}


                    #data(v-show="previousLens.measurement.measurementJawroVisu.pa || previousLens.measurement.measurementJawroVisu.fffa || previousLens.measurement.measurementJawroVisu.cvd")
                        span
                            span.mr3 {{'tasks.INCLINATION'|translate}}
                            span {{previousLens.measurement.measurementJawroVisu.pa|number(2)}}

                        span
                            span.mr3 {{'tasks.FFFA'|translate}}
                            span {{previousLens.measurement.measurementJawroVisu.fffa|number(2)}}

                        span
                            span.mr3 {{'tasks.CVD'|translate}}

                            small.mr3(v-show="previousLens.measurement.measurementJawroVisu.cvd_r !== previousLens.measurement.measurementJawroVisu.cvd_l"
                                ) {{'prescription.RIGHT'|translate}}
                            span.mr3 {{previousLens.measurement.measurementJawroVisu.cvd_r|number(1)}}

                            small.mr3(v-show="previousLens.measurement.measurementJawroVisu.cvd_r !== previousLens.measurement.measurementJawroVisu.cvd_l"
                                ) {{'prescription.LEFT'|translate}}
                            span(v-show="previousLens.measurement.measurementJawroVisu.cvd_r !== previousLens.measurement.measurementJawroVisu.cvd_l"
                                ) {{previousLens.measurement.measurementJawroVisu.cvd_l|number(1)}}

                        span.text-right {{previousLens.measurement.measurementJawroVisu.method_name|translate}}

            div(v-if="previousLens.measurement.measurementJawroVisu.measurement_id")
                a(:href="'upload/jawro_visu/previous_lenses/' + previousLens.measurement.measurementJawroVisu.measurement_id + '.jpg'"
                    target="_blank" :title="'tasks.ipad.printscreen.TITLE'|translate")
                    img(style="width:100%" :src="'upload/jawro_visu/previous_lenses/' + previousLens.measurement.measurementJawroVisu.measurement_id + '.jpg?refresh=' + previousLens.measurement.measurementJawroVisu.updated_at")
</template>

<script>
    import OptiNumber from '@/js/app/vue/components/Inputs/OptiNumber'

    export default {
        name: 'OptiPreviousLensesMeasurementJawroVisu',
        components: {
            OptiNumber,
        },
        props: {
            mode: {
                type: String,
                required: true
            },

            previousLens: {
                type: Object,
                required: true
            },
            measurementValidate: {
                type: Object,
                required: true
            },
            measurementApp: {
                type: Number,
                required: true
            },

            disabled: {
                type: Boolean,
                required: true
            }
        }
    }
</script>

<style lang="less" scoped>
    td.border-pink {
        border: 3px solid deeppink !important;

        .header {
            color: deeppink;
        }
    }
</style>