<template lang="pug">
    OptiDialog.fullscreen(:header="false" :footer="false")
        template(#body)
            i.fa.fa-close.fa-2x(:title="'default.CLOSE'|translate" @click="closeFullScreen")

            .offer-photo-slide-container
                img.animated.fadeIn(rel="preload" v-for="photo in grouped_photos[key.current]"
                    :class="'img-photo-slide-' + grouped_photos[key.current].length"
                    :key="photo" :src="'upload/offer/' + photo")

            .offer-photo-slide-container.invisible
                img(rel="preload" v-for="photo in grouped_photos[key.next]"
                    :class="'img-photo-slide-' + grouped_photos[key.next].length"
                    :key="photo" :src="'upload/offer/' + photo")

            OptiDimmer(:active="loading")
</template>

<script>
    import {API} from '@/js/app/vue/api'
    import {FullScreenMode} from '@/js/app/vue/helpers/FullScreenMode'

    import OptiDialog from '@/js/app/vue/components/OptiDialog'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiCatalogOfferDialogBtnPhotoSlides',
        components: {
            OptiDimmer,
            OptiDialog
        },
        data() {
            return {
                grouped_photos: [],
                duration: 0,

                interval: null,
                key: {
                    current: 0,
                    next: 1
                },

                loading: true
            }
        },
        mounted() {
            FullScreenMode.open()

            window.addEventListener('fullscreenchange', this.changeFullscreen)

            API.get('catalog/offer/photos/slides').then(res => {
                this.grouped_photos = res.data.grouped_photos
                this.duration = res.data.slide_duration

                this.interval = setInterval(() => {
                    this.incrementKey('current')
                    this.incrementKey('next')
                }, this.duration * 1000)
            }).finally(() => {
                this.loading = false
            }).catch(() => {
                this.closeFullScreen()

                this.$notify.error('catalog.offer.photos.slides.notify.error.LOAD')
            })
        },
        destroyed() {
            clearInterval(this.interval)

            window.removeEventListener('fullscreenchange', this.changeFullscreen)
        },
        methods: {
            incrementKey(type) {
                if(this.key[type] === this.grouped_photos.length - 1) {
                    this.key[type] = 0
                } else {
                    ++this.key[type]
                }
            },
            closeFullScreen() {
                FullScreenMode.close()

                this.$emit('closed')
            },
            changeFullscreen() {
                if(!document.fullscreenElement) {
                    document.body.style.overflow = ''

                    this.$emit('closed')
                }
            }
        }
    }
</script>

<style lang="less" scoped>
    /deep/ .modal-body {
        background: #fff;
    }

    i.fa-close {
        position: absolute;
        top: 10px;
        right: 15px;
        cursor: pointer;
        color: grey;
        z-index: 9999;
    }

    .offer-photo-slide-container {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        height: 100%;
        width: 100%;

        position: absolute;
        top: 0;
        left: 0;

        > img {
            &.img-photo-slide-1 {
                max-height: 85%;
            }

            &.img-photo-slide-2 {
                max-height: 75%;
            }

            &.img-photo-slide-3 {
                max-height: 55%;
            }

            &.img-photo-slide-4 {
                max-height: 40%;
            }
        }
    }
</style>