<template lang="pug">
    .row
        .col-xs-12(v-if="!loading")
            .text-right
                OptiButton(@click.native="exportTickets") {{'prices.prices.PRINT_TICKETS'|translate}}
                    template(#icon)
                        i.fa.fa-print

            .panel.panel-primary(style="margin-top:10px")
                .panel-heading
                    i.fa.fa-navicon.mr3
                    span.mr3 {{'ticket.breadcrumb.TICKETS'|translate}} \#{{ticket.id}}
                    small ({{ticket.status_name|translate}})
                    .pull-right \#{{ticket.date}}
                .table-responsive
                    table.table.table-condensed.table-striped.middle
                        thead
                            tr
                                th #
                                th {{'ticket.details.head.PRODUCTID'|translate}}
                                th {{'ticket.details.head.PRODUCTNAME'|translate}}
                                th {{'ticket.details.head.BARCODE'|translate}}
                                th {{'ticket.details.head.PRICE'|translate}}
                                th
                        tbody
                            tr(v-for="(detail, index) in ticket.details")
                                td.text-right {{index + 1}}
                                td.text-right
                                    OptiProduct(:product="detail.product") {{detail.product_id}}
                                td
                                    OptiProduct(:product="detail.product") {{detail.product_name}}
                                    small(style="display:block") {{detail.product.fullName}}
                                td {{detail.barcode}}
                                td.text-right {{detail.price|currency}}
                                td.text-center
                                    a(:href="$state.href('app.catalog.product',{id: detail.product_id, departmentId: ticket.department_id})"
                                        :title="'returns.items.title.PRODUCT'|translate")
                                        i.fa.fa-2x.fa-fw.fa-info-circle

                            tr(v-show="!ticket.details.length")
                                td.text-center.text-muted(colspan="100")
                                    i.fa.fa-exclamation-circle.mr3
                                    span {{'default.table.EMPTY'|translate}}

        .col-md-8.col-md-offset-4.col-xs-12(v-if="!loading")
            .panel-grid
                .panel.panel-primary
                    .row
                        .col-sm-6 {{'ticket.table.STATUS'|translate}}
                        .col-sm-6 {{ticket.status_name|translate}}
                    .row(v-show="shipment_exists")
                        .col-sm-6 {{'shipments.SHIPMENTS'|translate}}
                        .col-sm-6
                            OptiShipmentNotice(:type="16" :id="ticket.id"
                                @shipment-exists="s => shipment_exists = s")
                    .row
                        .col-sm-6 {{'ticket.table.CREATEDBY'|translate}}
                        .col-sm-6
                            div {{ticket.user.firstName}} {{ticket.user.lastName}}
                            small.text-muted {{ticket.department.name}}

        .col-xs-12(v-else)
            OptiDimmer(:active="loading")
</template>

<script>
    import {API} from '@/js/app/vue/api'
    import {DownloadFileHelper} from '@/js/app/vue/helpers/DownloadFileHelper'

    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiProduct from '@/js/app/vue/components/Warehouse/Products/OptiProduct'
    import OptiShipmentNotice from '@/js/app/vue/components/Shipping/OptiShipmentNotice'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiTicketsDetails',
        components: {
            OptiButton,
            OptiProduct,
            OptiShipmentNotice,
            OptiDimmer
        },
        data() {
            return {
                ticket: null,

                shipment_exists: false,
                loading: true
            }
        },
        mounted() {
            API.get('warehouse/ticket/' + this.$state.params.id).then(res => {
                this.ticket = res.data
            }).finally(() => {
                this.loading = false
            }).catch(() => {
                this.$notify.error('ticket.details.notify.error.LOAD')
            })
        },
        methods: {
            exportTickets() {
                let filename = 'warehouse/tickets/details/' + this.ticket.id + ' ' +
                    moment().format('YYYY-MM-DD HH:mm:ss') + ' ' + this.ticket.department_name + '.csv'

                DownloadFileHelper.post('warehouse/ticket/exportTickets', filename, {
                    id: this.$state.params.id
                })
            }
        }

    }
</script>