<template lang="pug">
    .panel.panel-primary(style="position:relative")
        .panel-heading
            i.fa.fa-navicon.mr3
            span {{'settings.general.info.HEADING'|translate}}

        .panel-body
            .row.margin-bottom
                .col-xs-12.bold {{'settings.general.info.ups.NUMBER'|translate}}
                .col-xs-12
                    span(v-if="data.ups") {{data.ups}}
                    .warn.bold(v-else) {{'settings.general.info.NODATA'|translate}}
            .row.margin-bottom
                .col-xs-12.bold {{'settings.general.info.ups.CONTACT'|translate}}
                .col-xs-12
                    span Tomasz Musiał, 691 781 743,
                    a.link(style="padding-left:3px" href='mailto:tmusial@ups.com') tmusial@ups.com
            .row.margin-bottom
                .col-xs-12.bold {{'settings.general.info.BGZ'|translate}}
                .col-xs-12
                    span(v-if="data.bgz") {{data.bgz}}
                    .warn.bold(v-else) {{'settings.general.info.NODATA'|translate}}
            .row
                .col-xs-12.bold {{'settings.general.info.TAKINGSPAYMENTS'|translate}}
                .col-xs-12
                    span(v-if="data.payments") {{data.payments}}
                    .warn.bold(v-else) {{'settings.general.info.NODATA'|translate}}


        .panel-footer.text-right(v-if="!data.is_jawro")
            OptiButton(type="primary" @click.native="dialog = true") {{'settings.general.info.EDIT'|translate}}
                template(#icon)
                    i.fa.fa-edit

            MountingPortal(v-if="dialog" mountTo="#vue-modal" append)
                OptiSettingsGeneralDialogInformation(
                    :data="data"

                    @saved="d => data = d"
                    @close="dialog = false")

        OptiDimmer(:active="loading")
</template>

<script>
    import {API} from '@/js/app/vue/api'

    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiSettingsGeneralDialogInformation
        from '@/js/app/vue/components/Settings/General/Dialogs/OptiSettingsGeneralDialogInformation'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiSettingsGeneralInformation',
        components: {
            OptiButton,
            OptiSettingsGeneralDialogInformation,
            OptiDimmer,
        },
        data() {
            return {
                data: {},

                dialog: false,
                loading: true
            }
        },
        mounted() {
            API.get('settings/general/information/get').then(res => {
                this.data = res.data
            }).finally(() => {
                this.loading = false
            }).catch(() => {
                this.$notify.error('settings.general.info.notify.error.LOAD')
            })
        }
    }
</script>

<style lang="less" scoped>
    .margin-bottom {
        margin-bottom: 15px;
    }
</style>