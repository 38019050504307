<template lang="pug">
    div
        label(v-for="type in types" :key="type.id" v-show="(disabled && type.checkbox) || !disabled")
            .checkbox.c-checkbox.checkbox-primary.inline
                label
                    input(type="checkbox" v-model="type.checkbox" :disabled="disabled"
                        @change="e => $emit('update-base-curve', e.target.checked ? type.id : 0)")
                    span.fa.fa-check
            span {{type.name|translate}}
</template>

<script>
    import {EnumHoyaLensBaseCurve}  from '@/js/app/vue/enums/Enums'

    export default {
        name: 'OptiCatalogLensesPropertiesBaseCurve',
        props: {
            baseCurve: {
                type: Number,
                required: true
            },
            disabled: {
                type: Boolean,
                required: true
            }
        },
        computed: {
            types() {
                let types = EnumHoyaLensBaseCurve.simpleItems().filter(t => t.id)

                types.forEach(t => {
                    t.checkbox = this.baseCurve === t.id
                })

                return types
            }
        }
    }

</script>

<style lang="less" scoped>
    label {
        display: block;
    }
</style>