<template lang="pug">
    .panel.panel-primary(style="position:relative")
        .panel-heading
            i.fa.fa-navicon.mr3
            span InPost

        .panel-body
            .form-horizontal
                .form-group
                    label.col-md-4.control-label(for="parcel") {{'settings.general.in-post.PARCEL'|translate}}
                    .col-md-8
                        input#parcel.form-control(name="parcel" v-model="parcel.changed")

        .panel-footer
            OptiButtonWikipedia(url="oprogramowanie:system_eopti:paczkomaty")

            OptiButtonSave.pull-right(:disabled="parcel.current === parcel.changed" @click.native="save")

        OptiDimmer(:active="loading")
</template>

<script>
    import {API} from '@/js/app/vue/api'

    import OptiButtonWikipedia from '@/js/app/vue/components/Button/OptiButtonWikipedia'
    import OptiButtonSave from '@/js/app/vue/components/Button/OptiButtonSave'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiSettingsGeneralInPost',
        components: {
            OptiButtonWikipedia,
            OptiButtonSave,
            OptiDimmer,
        },
        data() {
            return {
                parcel: {
                    current: '',
                    changed: ''
                },

                dialog: false,
                loading: true
            }
        },
        mounted() {
            API.get('settings/general/in-post/get').then(res => {
                this.parcel = {
                    current: res.data,
                    changed: res.data
                }
            }).finally(() => {
                this.loading = false
            }).catch(() => {
                this.$notify.error('settings.general.in-post.notify.error.LOAD')
            })
        },
        methods: {
            save() {
                this.loading = true

                API.post('settings/general/in-post/save', {
                    parcel: this.parcel.changed
                }).then(res => {
                    this.parcel = {
                        current: res.data,
                        changed: res.data
                    }

                    this.$notify.success('settings.general.in-post.notify.SAVE')
                }).finally(() => {
                    this.loading = false
                }).catch(() => {
                    this.$notify.error('settings.general.in-post.notify.error.SAVE')
                })
            }
        }
    }
</script>