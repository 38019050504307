<template lang="pug">
    #previous-lenses-directive
        .row.text-center
            .col-sm-6
                label
                    .checkbox.c-checkbox.checkbox-primary.inline
                        label
                            input(type="checkbox" :disabled="disabled || disabledCheckboxs"
                                v-model="glasses.first" @change="checkGlasses('first')")
                            span.fa.fa-check
                    span {{'previouslens.checkboxs.FIRST'|translate}}
            .col-sm-6
                label
                    .checkbox.c-checkbox.checkbox-primary.inline
                        label
                            input(type="checkbox" :disabled="disabled || disabledCheckboxs"
                                v-model="glasses.none" @change="checkGlasses('none')")
                            span.fa.fa-check
                    span {{'previouslens.checkboxs.NONE'|translate}}

        .panel.panel-primary
            .panel-heading
                i.fa.fa-navicon.mr3
                span {{'previouslens.PREVIOUSLENS'|translate}}

            .table-responsive(style="overflow-y:hidden")
                table.table.text-center
                    thead(v-show="data.length")
                        tr
                            th {{'previouslens.DISTANCE'|translate}}
                            th {{'previouslens.TYPE'|translate}}
                            th(colspan="2") {{'previouslens.TARGET'|translate}}
                            th
                            th SPH
                            th CYL
                            th(v-show="previousLensesShow.axs") AXS
                            th(v-show="previousLensesShow.add") Add
                            th Prism
                            th(v-show="previousLensesShow.base") Angle

                    tbody
                        template(v-for="(previousLens, key) in data")
                            tr.border-top
                                td.row.bg-separator(colspan="100")
                                    .col-xs-6 \#{{key + 1}}
                                    .col-xs-6
                                        span(v-show="previousLens.created_at")
                                            i.fa.fa-calendar.mr3
                                            span {{previousLens.created_at|dateWithoutPastYear}}

                            tr(:class="{'tr-stripped': key % 2}")
                                td
                                    OptiSelectEnum(:class="{'its-error': !previousLens.distance_id}" enum="DistanceType"
                                        :value="previousLens.distance_id" @update-enum="d => changeDistance(key, d)"
                                        :disabled="disabled || !!previousLens.task_id || (mode === 'interview' && checked[key])")
                                td
                                    select.form-control(:class="{'its-error': !previousLens.type_id}" v-model="previousLens.type_id"
                                        @change="changeType(key)" :disabled="disabled || !!previousLens.task_id")
                                        option(v-for="e in filterPreviousType(key)" :value="e.id") {{e.name|translate}}
                                td(colspan="2")
                                    select.form-control(v-model="previousLens.target_id"
                                        @change="$emit('update-previous-lenses')" :disabled="disabled")
                                        option(v-for="e in filterPreviousTarget(key)" :value="e.id") {{e.name|translate}}
                                td
                                    .form-control-static.bold {{'prescription.RIGHT'|translate}}
                                td
                                    OptiNumberPrescription.text-center(:class="{'opacity': previousLens.cant_read_powers}"
                                        :header="$filters.translate('previouslens.PREVIOUSLENS') + ' (' + $filters.translate('prescription.RIGHT') + ') - SPH'"
                                        :value="previousLens.r_sph" @confirmed="v => updateSph(key, 'r', v)"
                                        :disabled="disabled || !!previousLens.task_id || previousLens.cant_read_powers") {{previousLens.r_sph|plus}}
                                td
                                    OptiNumberPrescription.text-center(:class="{'opacity': previousLens.cant_read_powers}"
                                        :header="$filters.translate('previouslens.PREVIOUSLENS') + ' (' + $filters.translate('prescription.RIGHT') + ') - CYL'"
                                        :value="previousLens.r_cyl" @confirmed="v => updateCyl(key, 'r', v)"
                                        :disabled="disabled || !!previousLens.task_id || previousLens.cant_read_powers") {{previousLens.r_cyl|plus}}
                                td(v-show="previousLensesShow.axs")
                                    OptiNumber.text-center(v-show="previousLens.r_cyl" :class="{'its-error': previousLens.r_axs === 'brak'}" :max="180"
                                        :header="$filters.translate('previouslens.PREVIOUSLENS') + ' (' + $filters.translate('prescription.RIGHT') + ') - AXS'"
                                        :value="previousLens.r_axs" @confirmed="v => updateAxs(key, 'r', v)"
                                        :disabled="disabled || !!previousLens.task_id || previousLens.cant_read_powers") {{previousLens.r_axs}}
                                td(v-show="previousLensesShow.add")
                                    OptiNumberPrescriptionAddiction.text-center(v-show="previousLens.distance_id === 1 && [2, 3, 4].includes(previousLens.type_id)"
                                        :class="{'its-error': !previousLens.r_add && !previousLens.cant_read_powers, 'opacity': previousLens.cant_read_powers}"
                                        :header="$filters.translate('previouslens.PREVIOUSLENS') + ' (' + $filters.translate('prescription.RIGHT') + ') - Add'"
                                        :value="previousLens.r_add" @confirmed="v => updateAdd(key, 'r', v)"
                                        :disabled="disabled || !!previousLens.task_id || previousLens.cant_read_powers") {{previousLens.r_add|number(2)}}
                                td
                                    OptiNumberPrescription.text-center(:class="{'opacity': previousLens.cant_read_powers}" :minus="false"
                                        :header="$filters.translate('previouslens.PREVIOUSLENS') + ' (' + $filters.translate('prescription.RIGHT') + ') - Prism'"
                                        :value="previousLens.r_prd" @confirmed="v => updatePrd(key, 'r', v)"
                                        :disabled="disabled || !!previousLens.task_id || previousLens.cant_read_powers") {{previousLens.r_prd|number(2)}}
                                td(v-show="previousLensesShow.base")
                                    OptiNumber.text-center(v-show="previousLens.r_prd" :max="360"
                                        :header="$filters.translate('previouslens.PREVIOUSLENS') + ' (' + $filters.translate('prescription.RIGHT') + ') - Angle'"
                                        :value="previousLens.r_base" @confirmed="v => updateBase(key, 'r', v)"
                                        :disabled="disabled || !!previousLens.task_id || previousLens.cant_read_powers") {{previousLens.r_base}}

                            tr(:class="{'tr-stripped': key % 2}")
                                td(colspan="4" style="text-align:left")
                                    OptiButton(type="danger" :disabled="disabled || !!previousLens.task_id || (mode === 'interview' && checked[key])"
                                        @click.native="removePreviousLens(key)") {{'previouslens.action.DELETE'|translate}}
                                        template(#icon)
                                            i.fa.fa-minus
                                    OptiButton(type="success" style="margin-left:5px" v-show="!key"
                                        :disabled="disabled || !previousLens.distance_id ||!previousLens.type_id"
                                        @click.native="addPreviousLens") {{'previouslens.action.ADD'|translate}}
                                        template(#icon)
                                            i.fa.fa-plus
                                    label.pull-right(v-show="previousLens.type_id")
                                        .checkbox.c-checkbox.checkbox-primary.inline(style="margin:5px 0")
                                            label
                                                input(type="checkbox" :disabled="disabled || (!!previousLens.task_id && mode === 'interview')"
                                                    v-model="checked[key]" @change="checkPreviousLens(key, previousLens.distance_id)")
                                                span.fa.fa-check
                                        span {{'previouslens.CURRENT'|translate}}
                                    div(style="clear:both" v-show="previousLens.task_id")
                                        a.pull-right.link(:title="'default.REFERENCETYPE'|translate"
                                            :href="$state.href('app.task.detail', {id: previousLens.task_id})" target="_blank"
                                            ) {{'previouslens.FROMTASK'|translate}} {{previousLens.task_id}}
                                td
                                    .form-control-static.bold {{'prescription.LEFT'|translate}}
                                td
                                    OptiNumberPrescription.text-center(:class="{'opacity': previousLens.cant_read_powers}"
                                        :header="$filters.translate('previouslens.PREVIOUSLENS') + ' (' + $filters.translate('prescription.LEFT') + ') - SPH'"
                                        :value="previousLens.l_sph" @confirmed="v => updateSph(key, 'l', v)"
                                        :disabled="disabled || !!previousLens.task_id || previousLens.cant_read_powers") {{previousLens.l_sph|plus}}
                                td
                                    OptiNumberPrescription.text-center(:class="{'opacity': previousLens.cant_read_powers}"
                                        :header="$filters.translate('previouslens.PREVIOUSLENS') + ' (' + $filters.translate('prescription.LEFT') + ') - CYL'"
                                        :value="previousLens.l_cyl" @confirmed="v => updateCyl(key, 'l', v)"
                                        :disabled="disabled || !!previousLens.task_id || previousLens.cant_read_powers") {{previousLens.l_cyl|plus}}
                                td(v-show="previousLensesShow.axs")
                                    OptiNumber.text-center(v-show="previousLens.l_cyl" :class="{'its-error': previousLens.l_axs === 'brak'}" :max="180"
                                        :header="$filters.translate('previouslens.PREVIOUSLENS') + ' (' + $filters.translate('prescription.LEFT') + ') - AXS'"
                                        :value="previousLens.l_axs" @confirmed="v => updateAxs(key, 'l', v)"
                                        :disabled="disabled || !!previousLens.task_id || previousLens.cant_read_powers") {{previousLens.l_axs}}
                                td(v-show="previousLensesShow.add")
                                    OptiNumberPrescriptionAddiction.text-center(v-show="previousLens.distance_id === 1 && [2, 3, 4].includes(previousLens.type_id)"
                                        :class="{'its-error': !previousLens.l_add && !previousLens.cant_read_powers, 'opacity': previousLens.cant_read_powers}"
                                        :header="$filters.translate('previouslens.PREVIOUSLENS') + ' (' + $filters.translate('prescription.LEFT') + ') - Add'"
                                        :value="previousLens.l_add" @confirmed="v => updateAdd(key, 'l', v)"
                                        :disabled="disabled || !!previousLens.task_id || previousLens.cant_read_powers") {{previousLens.l_add|number(2)}}
                                td
                                    OptiNumberPrescription.text-center(:class="{'opacity': previousLens.cant_read_powers}" :minus="false"
                                        :header="$filters.translate('previouslens.PREVIOUSLENS') + ' (' + $filters.translate('prescription.LEFT') + ') - Prism'"
                                        :value="previousLens.l_prd" @confirmed="v => updatePrd(key, 'l', v)"
                                        :disabled="disabled || !!previousLens.task_id || previousLens.cant_read_powers") {{previousLens.l_prd|number(2)}}
                                td(v-show="previousLensesShow.base")
                                    OptiNumber.text-center(v-show="previousLens.r_prd" :max="360"
                                        :header="$filters.translate('previouslens.PREVIOUSLENS') + ' (' + $filters.translate('prescription.LEFT') + ') - Angle'"
                                        :value="previousLens.l_base" @confirmed="v => updateBase(key, 'l', v)"
                                        :disabled="disabled || !!previousLens.task_id || previousLens.cant_read_powers") {{previousLens.l_base}}

                            tr(:class="{'tr-stripped': key % 2}")
                                td.middle(colspan="3")
                                    OptiButtonMeasurement(v-show="checked[key] && [0, 2].includes(measurementApp)"
                                        :id="patientId"
                                        :department-id="departmentId"
                                        :measurement-app="measurementApp"

                                        :measurement-id="previousLens.measurement.ipad.measurement_id"

                                        :disabled="disabled || !!previousLens.task_id"

                                        @update-measurement-id="m => updateMeasurement(key, m)")

                                    OptiButtonMeasurementJawroVisu(:class="{'btn-jawro-visu': measurementApp === 2}"
                                        v-show="checked[key] && [1, 2].includes(measurementApp)"
                                        :patient-id="patientId"
                                        :measurement-app="measurementApp"

                                        :measurement-id="previousLens.measurement.measurementJawroVisu.measurement_id"

                                        :disabled="disabled || !!previousLens.task_id"

                                        @update-measurement="m => updateMeasurementJawroVisu(key, m)")

                                td(style="text-align:right" colspan="2")
                                    div(style="width:10px;height:25px" v-show="previousLens.type_id === 4")
                                    label.form-control-static(:for="'comments' + key") {{'previouslens.COMMENTS'|translate}}
                                td(colspan="7")
                                    .text-center(v-show="previousLens.type_id === 4 && !previousLens.task_id")
                                        label
                                            .checkbox.c-checkbox.checkbox-primary.inline(style="margin:5px 0")
                                                label
                                                    input(type="checkbox" :disabled="disabled"
                                                        v-model="previousLens.cant_read_powers" @change="checkNoPowers(previousLens)")
                                                    span.fa.fa-check
                                            span {{'previouslens.READPOWER'|translate}}
                                    textarea.form-control(:id="'comments' + key" rows="3"
                                        v-model.lazy="previousLens.comments" @change="$emit('update-previous-lenses')"
                                        :disabled="disabled")

                            tr(:class="{'tr-stripped': key % 2}" v-if="(checked[key] && [0, 2].includes(measurementApp)) || previousLens.measurement.ipad.id")
                                OptiPreviousLensesMeasurementVisuReal(
                                    :mode="mode"

                                    :previous-lens="previousLens"
                                    :measurement-validate="measurements_validate.visu_real[key]"

                                    :disabled="disabled"

                                    @update-dioprometer="(field, v) => updateDioprometer(key, field, v)")

                            tr(:class="{'tr-stripped': key % 2}" v-if="(checked[key] && [1, 2].includes(measurementApp)) || previousLens.measurement.measurementJawroVisu.id")
                                OptiPreviousLensesMeasurementJawroVisu(
                                    :mode="mode"

                                    :previous-lens="previousLens"
                                    :measurement-validate="measurements_validate.jawro_visu[key]"
                                    :measurement-app="measurementApp"

                                    :disabled="disabled"

                                    @update-dioprometer="(field, v) => updateDioprometer(key, field, v)")

                            tr(:class="{'tr-stripped': key % 2}" v-if="(checked[key] && measurementApp === 2) || (previousLens.measurement.ipad.id && previousLens.measurement.measurementJawroVisu.id)")
                                td(colspan="100")
                                    OptiTaskDetailsIpadCompare(style="margin-top:-30px"
                                        :measurement="previousLens.measurement.ipad"
                                        :measurement-jawro-visu="previousLens.measurement.measurementJawroVisu")

                    tfoot(v-show="!data.length")
                        tr
                            td.text-center.text-muted(colspan="12")
                                i.fa.fa-exclamation-circle.mr3
                                span {{'previouslens.NONE'|translate}}
                        tr
                            td(colspan="12" style="text-align:right")
                                OptiButton(type="success" :disabled="disabled" @click.native="addPreviousLens"
                                    ) {{'previouslens.action.ADD'|translate}}
                                    template(#icon)
                                        i.fa.fa-plus

        .panel.panel-primary
            .panel-heading
                i.fa.fa-navicon.mr3
                span {{'previouslens.contactlens.HEADING'|translate}}

            .table-responsive
                table.table.text-center.middle
                    thead(v-show="contactLenses.length")
                        tr
                            th
                            th {{'previouslens.contactlens.NAME'|translate}}
                            th(v-show="contactLensesShow.sph") SPH
                            th(v-show="contactLensesShow.cyl") CYL
                            th(v-show="contactLensesShow.cyl") AXS
                            th(v-show="contactLensesShow.add") Add

                    tbody
                        template(v-for="(contactLens, key) in contactLenses")
                            tr.border-top
                                td.row.bg-separator(colspan="100")
                                    .col-xs-6 \#{{key + 1}}
                                    .col-xs-6
                                        span(v-show="contactLens.created_at")
                                            i.fa.fa-calendar.mr3
                                            span {{contactLens.created_at|dateWithoutPastYear}}
                            tr(:class="{'tr-stripped': key % 2}")
                                td
                                    .form-control-static.bold {{'prescription.RIGHT'|translate}}
                                td
                                    select.form-control(:class="{'its-error': !contactLens.r_product.id}" v-model="contactLens.r_product"
                                        @change="changeContactLens(contactLens, 'r')" :disabled="disabled")
                                        option(v-for="p in products" :value="p" :key="p.id"
                                            :disabled="disabled_product_ids.includes(p.id)") {{p.producent + ' ' + p.name}}
                                    .input-group(style="margin-top:10px" v-show="contactLens.r_product.id === 100")
                                        .input-group-btn(style="width:100%")
                                            input.form-control(:class="{'its-error': !contactLens.r_product_name}"
                                                v-model.lazy="contactLens.r_product_name"
                                                @change="changeContactLensInput(contactLens, 'r')" :disabled="disabled")
                                        .input-group(style="width:130px")
                                            OptiSelectEnum(enum="PreviousLensContactLensType"
                                                :value="contactLens.r_type_id" @update-enum="t => contactLens.r_type_id = t"
                                                :disabled="disabled")
                                td(v-show="contactLensesShow.sph")
                                    OptiNumberPrescription.text-center(v-show="contactLens.r_product.sph"
                                        :header="$filters.translate('previouslens.PREVIOUSLENS') + ' (' + $filters.translate('prescription.RIGHT') + ') - SPH'"
                                        :value="contactLens.r_sph" @confirmed="v => updateContactLensSph(key, 'r', v)"
                                        :disabled="disabled") {{contactLens.r_sph|plus}}
                                td(v-show="contactLensesShow.cyl")
                                    OptiNumberPrescription.text-center(v-show="contactLens.r_product.cyl"
                                        :class="{'its-error': !contactLens.r_cyl && contactLens.r_product.id !== 100}"
                                        :header="$filters.translate('previouslens.PREVIOUSLENS') + ' (' + $filters.translate('prescription.RIGHT') + ') - CYL'"
                                        :value="contactLens.r_cyl" @confirmed="v => updateContactLensCyl(key, 'r', v)"
                                        :disabled="disabled") {{contactLens.r_cyl|plus}}
                                td(v-show="contactLensesShow.cyl")
                                    OptiNumber.text-center(v-show="contactLens.r_cyl" :class="{'its-error': contactLens.r_axs === 'brak'}" :max="180"
                                        :header="$filters.translate('previouslens.PREVIOUSLENS') + ' (' + $filters.translate('prescription.RIGHT') + ') - AXS'"
                                        :value="contactLens.r_axs" @confirmed="v => updateContactLensAxs(key, 'r', v)"
                                        :disabled="disabled") {{contactLens.r_axs}}
                                td(v-show="contactLensesShow.add")
                                    OptiNumberPrescriptionAddiction.text-center(v-show="contactLens.r_product.add"
                                        :class="{'its-error': !contactLens.r_add && contactLens.r_product.id !== 100}"
                                        :header="$filters.translate('previouslens.PREVIOUSLENS') + ' (' + $filters.translate('prescription.RIGHT') + ') - Add'"
                                        :value="contactLens.r_add" @confirmed="v => updateContactLensAdd(key, 'r', v)"
                                        :disabled="disabled") {{contactLens.r_add|number(2)}}

                            tr(:class="{'tr-stripped': key % 2}")
                                td
                                    .form-control-static.bold {{'prescription.LEFT'|translate}}
                                td
                                    select.form-control(:class="{'its-error': !contactLens.l_product.id}" v-model="contactLens.l_product"
                                        @change="changeContactLens(contactLens, 'l')" :disabled="disabled")
                                        option(v-for="p in products" :value="p" :key="p.id"
                                            :disabled="disabled_product_ids.includes(p.id)") {{p.producent + ' ' + p.name}}
                                    .input-group(style="margin-top:10px" v-show="contactLens.l_product.id === 100")
                                        .input-group-btn(style="width:100%")
                                            input.form-control(:class="{'its-error': !contactLens.l_product_name}"
                                                v-model.lazy="contactLens.l_product_name"
                                                @change="changeContactLensInput(contactLens, 'l')" :disabled="disabled")
                                        .input-group(style="width:130px")
                                            OptiSelectEnum(enum="PreviousLensContactLensType"
                                                :value="contactLens.l_type_id" @update-enum="t => contactLens.l_type_id = t"
                                                :disabled="disabled")
                                td(v-show="contactLensesShow.sph")
                                    OptiNumberPrescription.text-center(v-show="contactLens.l_product.sph"
                                        :header="$filters.translate('previouslens.PREVIOUSLENS') + ' (' + $filters.translate('prescription.LEFT') + ') - SPH'"
                                        :value="contactLens.l_sph" @confirmed="v => updateContactLensSph(key, 'l', v)"
                                        :disabled="disabled") {{contactLens.l_sph|plus}}
                                td(v-show="contactLensesShow.cyl")
                                    OptiNumberPrescription.text-center(v-show="contactLens.l_product.cyl"
                                        :class="{'its-error': !contactLens.l_cyl && contactLens.l_product.id !== 100}"
                                        :header="$filters.translate('previouslens.PREVIOUSLENS') + ' (' + $filters.translate('prescription.LEFT') + ') - CYL'"
                                        :value="contactLens.l_cyl" @confirmed="v => updateContactLensCyl(key, 'l', v)"
                                        :disabled="disabled") {{contactLens.l_cyl|plus}}
                                td(v-show="contactLensesShow.cyl")
                                    OptiNumber.text-center(v-show="contactLens.l_cyl" :class="{'its-error': contactLens.l_axs === 'brak'}" :max="180"
                                        :header="$filters.translate('previouslens.PREVIOUSLENS') + ' (' + $filters.translate('prescription.LEFT') + ') - AXS'"
                                        :value="contactLens.l_axs" @confirmed="v => updateContactLensAxs(key, 'l', v)"
                                        :disabled="disabled") {{contactLens.l_axs}}
                                td(v-show="contactLensesShow.add")
                                    OptiNumberPrescriptionAddiction.text-center(v-show="contactLens.l_product.add"
                                        :class="{'its-error': !contactLens.l_add && contactLens.l_product.id !== 100}"
                                        :header="$filters.translate('previouslens.PREVIOUSLENS') + ' (' + $filters.translate('prescription.LEFT') + ') - Add'"
                                        :value="contactLens.l_add" @confirmed="v => updateContactLensAdd(key, 'l', v)"
                                        :disabled="disabled") {{contactLens.l_add|number(2)}}

                            tr(:class="{'tr-stripped': key % 2}")
                                td(colspan="2" style="vertical-align:top !important")
                                    .pull-left
                                        OptiButton(type="danger" :disabled="disabled || (mode === 'interview' && checkedContact[key])"
                                            @click.native="removeContactLens(key)") {{'previouslens.action.DELETE'|translate}}
                                            template(#icon)
                                                i.fa.fa-minus
                                        OptiButton(type="success" style="margin-left:5px" v-show="!key"
                                            :disabled="disabled || !contactLens.l_product.id || !contactLens.r_product.id"
                                            text="previouslens.contactlens.confirm" @confirmed="addContactLens") {{'previouslens.action.ADD'|translate}}
                                            template(#icon)
                                                i.fa.fa-plus

                                    label.form-control-static.pull-right(:for="'contactLensComments' + key") {{'previouslens.COMMENTS'|translate}}

                                    label.pull-right(style="margin-right:25px" v-show="contactLens.r_product.id && contactLens.l_product.id")
                                        .checkbox.c-checkbox.checkbox-primary.inline(style="margin:5px 0")
                                            label
                                                input(type="checkbox" :disabled="disabled"
                                                    v-model="checkedContact[key]" @change="checkContactLens(key)")
                                                span.fa.fa-check
                                        span {{'previouslens.contactlens.CURRENT'|translate}}

                                    .clearfix

                                td(colspan="4")
                                    textarea.form-control(:id="'contactLensComments' + key" rows="3"
                                        v-model.lazy="contactLens.comments" @change="$emit('update-previous-lenses-contact')"
                                        :disabled="disabled")

                    tfoot(v-show="!contactLenses.length")
                        tr
                            td.text-center.text-muted(colspan="6")
                                i.fa.fa-exclamation-circle.mr3
                                span {{'previouslens.contactlens.NONE'|translate}}
                        tr
                            td(style="text-align:right" colspan="6")
                                OptiButton(type="success" :disabled="disabled"
                                    text="previouslens.contactlens.confirm" @confirmed="addContactLens"
                                    ) {{'previouslens.contactlens.confirm.BTN'|translate}}
                                    template(#icon)
                                        i.fa.fa-plus
</template>

<script>
    import {API} from '@/js/app/vue/api'
    import {EnumLensType, EnumLensTarget} from '@/js/app/vue/enums/Enums'

    import OptiSelectEnum from '@/js/app/vue/components/Select/OptiSelectEnum'
    import OptiNumberPrescription from '@/js/app/vue/components/Inputs/OptiNumberPrescription'
    import OptiNumber from '@/js/app/vue/components/Inputs/OptiNumber'
    import OptiNumberPrescriptionAddiction from '@/js/app/vue/components/Inputs/OptiNumberPrescriptionAddiction'
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiButtonMeasurement from '@/js/app/vue/components/Button/OptiButtonMeasurement'
    import OptiButtonMeasurementJawroVisu
        from '@/js/app/vue/components/PreviousLenses/Measurement/OptiButtonMeasurementJawroVisu'
    import OptiPreviousLensesMeasurementVisuReal
        from '@/js/app/vue/components/PreviousLenses/Measurement/OptiPreviousLensesMeasurementVisuReal'
    import OptiPreviousLensesMeasurementJawroVisu
        from '@/js/app/vue/components/PreviousLenses/Measurement/OptiPreviousLensesMeasurementJawroVisu'
    import OptiTaskDetailsIpadCompare from "@/js/app/vue/components/Tasks/Details/Ipad/OptiTaskDetailsIpadCompare.vue";

    export default {
        name: 'OptiPreviousLenses',
        components: {
            OptiTaskDetailsIpadCompare,
            OptiSelectEnum,
            OptiNumberPrescription,
            OptiNumber,
            OptiNumberPrescriptionAddiction,
            OptiButton,
            OptiButtonMeasurement,
            OptiButtonMeasurementJawroVisu,
            OptiPreviousLensesMeasurementVisuReal,
            OptiPreviousLensesMeasurementJawroVisu
        },
        props: {
            mode: {
                type: String,
                required: true
            },
            data: {
                type: Array,
                required: true
            },
            contactLenses: {
                type: Array,
                required: true
            },
            patientId: {
                type: Number,
                required: true
            },
            departmentId: {
                type: Number,
                required: true
            },
            measurementApp: {
                type: Number,
                required: true
            },
            allowSave: {
                type: Boolean,
                required: false,
                default: true
            },
            checked: {
                type: Array,
                required: true
            },
            checkedContact: {
                type: Array,
                required: true
            },
            disabled: {
                type: Boolean,
                required: false,
                default: false
            },
            glasses: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                previousLensesShow: {
                    axs: false,
                    add: false,
                    base: false
                },
                contactLensesShow: {
                    sph: false,
                    cyl: false,
                    add: false
                },

                lensType: EnumLensType.simpleItems(),
                lensTarget: EnumLensTarget.simpleItems(),

                measurements_validate: {
                    visu_real: [],
                    jawro_visu: []
                },

                products: [],
                disabled_product_ids: [],

                loading_jawro_visu: false
            }
        },
        beforeMount() {
            this.updateShow()
            this.updateContactLensesShow()

            API.get('patient/previouslenses/products', API.id(Math.random())).then(res => {
                this.products = this.parseContactLenses(res.data)

                this.products.unshift({
                    id: 0,
                    producent: '',
                    name: '-',
                    sph: false,
                    cyl: false,
                    add: false
                })
            }).catch(() => {
                this.$notify.error('previouslens.contactlens.notify.error.LOADPRODUCTS')
            })

            if(this.mode === 'interview') {
                this.$watch('checked', () => {
                    let check_allow_save = false

                    Object.keys(this.measurements_validate).forEach(app => {
                        this.data.forEach((previousLens, key) => {
                            if(!Object.keys(this.measurements_validate[app]).includes(key)) {
                                this.measurementCheck(app, key)
                                check_allow_save = true
                            }
                        })
                    })

                    if(check_allow_save) {
                        this.checkAllowSave()
                    }
                })
            }
        },
        methods: {
            maxSph(sph, cyl) {
                let _return = sph

                if(cyl < 0) {
                    _return -= cyl * -1
                } else if(cyl > 0) {
                    _return += cyl * -1
                }

                return _return
            },
            measurementCheckReset(app, key, bool) {
                this.$set(this.measurements_validate[app], key, {
                    pdR: bool,
                    pdL: bool,
                    btR: bool,
                    btL: bool,
                    delta: {
                        pdR: 0,
                        pdL: 0,
                        btR: 0,
                        btL: 0
                    }
                })
            },
            measurementCheck(app, key) {
                let delta = 0,
                    data = {
                        visu_real: {
                            table: 'ipad',
                            ref_id: 'ipad_measurements_previous_lens_id',
                            side: {
                                right: 'R',
                                left: 'L'
                            }
                        },
                        jawro_visu: {
                            table: 'measurementJawroVisu',
                            ref_id: 'measurement_jawro_visu_id',
                            side: {
                                right: '_r',
                                left: '_l'
                            }
                        }
                    }

                if(!Object.keys(this.measurements_validate[app]).includes(key)) {
                    this.measurementCheckReset(app, key, true)
                }

                if(this.checked[key] && !this.data[key].measurement[data[app].ref_id]) {
                    this.measurementCheckReset(app, key, false)

                    return
                }

                if(!this.data[key].task_id && this.data[key].measurement[data[app].ref_id]) {
                    let s_r_pd = Math.abs(this.data[key].measurement[data[app].table]['pd' + data[app].side.right] - this.data[key].measurement.pdR),
                        s_l_pd = Math.abs(this.data[key].measurement[data[app].table]['pd' + data[app].side.left] - this.data[key].measurement.pdL),
                        s_r_bt = Math.abs(this.data[key].measurement[data[app].table]['bt' + data[app].side.right] - this.data[key].measurement.btR),
                        s_l_bt = Math.abs(this.data[key].measurement[data[app].table]['bt' + data[app].side.left] - this.data[key].measurement.btL),
                        p_r = this.maxSph(this.data[key].r_sph, this.data[key].r_cyl),
                        p_l = this.maxSph(this.data[key].l_sph, this.data[key].l_cyl)

                    // right
                    if(!p_r) {
                        this.measurements_validate[app][key].pdR = true
                        this.measurements_validate[app][key].delta.pdR = 0

                        this.measurements_validate[app][key].btR = true
                        this.measurements_validate[app][key].delta.btR = 0
                    } else if(this.data[key].r_prd >= 0 && this.data[key].r_prd <= 2) {
                        //pd
                        delta = p_r > 3.25 ? p_r * 0.2 : 0.67

                        this.measurements_validate[app][key].delta.pdR = p_r ? Math.abs(delta / p_r * 10) : 0

                        if(s_r_pd > this.measurements_validate[app][key].delta.pdR) {
                            this.measurements_validate[app][key].pdR = false
                        } else {
                            this.measurements_validate[app][key].pdR = true
                        }

                        //bt
                        delta = p_r > 5 ? p_r : 0.5

                        this.measurements_validate[app][key].delta.btR = p_r ? Math.abs(delta / p_r * 10) : 0

                        if(s_r_bt > this.measurements_validate[app][key].delta.btR) {
                            this.measurements_validate[app][key].btR = false
                        } else {
                            this.measurements_validate[app][key].btR = true
                        }
                    } else if(this.data[key].r_prd > 2 && this.data[key].r_prd <= 10) {
                        //pd
                        delta = p_r > 3.25 ? 0.33 + p_r * 0.2 : 1

                        this.measurements_validate[app][key].delta.pdR = p_r ? Math.abs(delta / p_r * 10) : 0

                        if(s_r_pd > this.measurements_validate[app][key].delta.pdR) {
                            this.measurements_validate[app][key].pdR = false
                        } else {
                            this.measurements_validate[app][key].pdR = true
                        }

                        //bt
                        delta = p_r > 5 ? 0.25 + p_r : 0.75

                        this.measurements_validate[app][key].delta.btR = p_r ? Math.abs(delta / p_r * 10) : 0

                        if(s_r_bt > this.measurements_validate[app][key].delta.btR) {
                            this.measurements_validate[app][key].btR = false
                        } else {
                            this.measurements_validate[app][key].btR = true
                        }
                    } else {
                        //pd
                        delta = p_r > 3.25 ? 0.58 + p_r * 0.2 : 1.25;

                        this.measurements_validate[app][key].delta.pdR = p_r ? Math.abs(delta / p_r * 10) : 0

                        if(s_r_pd > this.measurements_validate[app][key].delta.pdR) {
                            this.measurements_validate[app][key].pdR = false
                        } else {
                            this.measurements_validate[app][key].pdR = true
                        }

                        //bt
                        delta = p_r > 5 ? 0.5 + p_r : 1

                        this.measurements_validate[app][key].delta.btR = p_r ? Math.abs(delta / p_r * 10) : 0

                        if(s_r_bt > this.measurements_validate[app][key].delta.btR) {
                            this.measurements_validate[app][key].btR = false
                        } else {
                            this.measurements_validate[app][key].btR = true
                        }
                    }

                    // left
                    if(!p_l) {
                        this.measurements_validate[app][key].pdL = true
                        this.measurements_validate[app][key].delta.pdL = 0

                        this.measurements_validate[app][key].btL = true
                        this.measurements_validate[app][key].delta.btL = 0
                    } else if(this.data[key].l_prd >= 0 && this.data[key].l_prd <= 2) {
                        //pd
                        delta = p_l > 3.25 ? p_l * 0.2 : 0.67

                        this.measurements_validate[app][key].delta.pdL = p_l ? Math.abs(delta / p_l * 10) : 0

                        if(s_l_pd > this.measurements_validate[app][key].delta.pdL) {
                            this.measurements_validate[app][key].pdL = false
                        } else {
                            this.measurements_validate[app][key].pdL = true
                        }

                        //bt
                        delta = p_l > 5 ? p_l : 0.5

                        this.measurements_validate[app][key].delta.btL = p_l ? Math.abs(delta / p_l * 10) : 0

                        if(s_l_bt > this.measurements_validate[app][key].delta.btL) {
                            this.measurements_validate[app][key].btL = false
                        } else {
                            this.measurements_validate[app][key].btL = true
                        }
                    } else if(this.data[key].l_prd > 2 && this.data[key].l_prd <= 10) {
                        //pd
                        delta = p_l > 3.25 ? 0.33 + p_l * 0.2 : 1

                        this.measurements_validate[app][key].delta.pdL = p_l ? Math.abs(delta / p_l * 10) : 0

                        if(s_l_pd > this.measurements_validate[app][key].delta.pdL) {
                            this.measurements_validate[app][key].pdL = false
                        } else {
                            this.measurements_validate[app][key].pdL = true
                        }

                        //bt
                        delta = p_l > 5 ? 0.25 + p_l : 0.75;

                        this.measurements_validate[app][key].delta.btL = p_l ? Math.abs(delta / p_l * 10) : 0

                        if(s_l_bt > this.measurements_validate[app][key].delta.btL) {
                            this.measurements_validate[app][key].btL = false
                        } else {
                            this.measurements_validate[app][key].btL = true
                        }
                    } else {
                        //pd
                        delta = p_l > 3.25 ? 0.58 + p_l * 0.2 : 1.25

                        this.measurements_validate[app][key].delta.pdL = p_l ? Math.abs(delta / p_l * 10) : 0

                        if(s_l_pd > this.measurements_validate[app][key].delta.pdL) {
                            this.measurements_validate[app][key].pdL = false
                        } else {
                            this.measurements_validate[app][key].pdL = true
                        }

                        //bt
                        delta = p_l > 5 ? 0.5 + p_l : 1

                        this.measurements_validate[app][key].delta.btL = p_l ? Math.abs(delta / p_l * 10) : 0

                        if(s_l_bt > this.measurements_validate[app][key].delta.btL) {
                            this.measurements_validate[app][key].btL = false
                        } else {
                            this.measurements_validate[app][key].btL = true
                        }
                    }

                    if(Math.abs(p_r) >= 3.5) {
                        this.measurements_validate[app][key].delta.pdR = 2

                        if(s_r_pd > this.measurements_validate[app][key].delta.pdR) {
                            this.measurements_validate[app][key].pdR = false
                        } else {
                            this.measurements_validate[app][key].pdR = true
                        }
                    }

                    if(Math.abs(p_l) >= 3.5) {
                        this.measurements_validate[app][key].delta.pdL = 2

                        if(s_l_pd > this.measurements_validate[app][key].delta.pdL) {
                            this.measurements_validate[app][key].pdL = false
                        } else {
                            this.measurements_validate[app][key].pdL = true
                        }
                    }

                    if(Math.abs(p_r) >= 5) {
                        this.measurements_validate[app][key].delta.btR = 1

                        if(s_r_bt > this.measurements_validate[app][key].delta.btR) {
                            this.measurements_validate[app][key].btR = false
                        } else {
                            this.measurements_validate[app][key].btR = true
                        }
                    }

                    if(Math.abs(p_l) >= 5) {
                        this.measurements_validate[app][key].delta.btL = 1

                        if(s_l_bt > this.measurements_validate[app][key].delta.btL) {
                            this.measurements_validate[app][key].btL = false
                        } else {
                            this.measurements_validate[app][key].btL = true
                        }
                    }

                    if(!this.data[key].measurement.pdR) {
                        this.measurements_validate[app][key].pdR = false
                    }

                    if(!this.data[key].measurement.pdL) {
                        this.measurements_validate[app][key].pdL = false
                    }

                    if(!this.data[key].measurement.btR) {
                        this.measurements_validate[app][key].btR = false
                    }

                    if(!this.data[key].measurement.btL) {
                        this.measurements_validate[app][key].btL = false
                    }
                } else {
                    this.measurementCheckReset(app, key, true)
                }
            },
            checkAllowSave() {
                let allowSave = true

                this.data.forEach((previousLens, key) => {
                    if(this.checked[key] && !previousLens.task_id && !previousLens.measurement.ipad_measurements_previous_lens_id &&
                        previousLens.r_sph <= 2 && previousLens.r_sph >= -2 && previousLens.l_sph <= 2 && previousLens.l_sph >= -2) {
                        // jak są małe moce to zostawiasz
                    } else {
                        if(!previousLens.distance_id || !previousLens.type_id) {
                            allowSave = false
                        } else if((previousLens.r_cyl && previousLens.r_axs === 'brak') || (previousLens.l_cyl && previousLens.l_axs === 'brak')) {
                            allowSave = false
                        } else if(previousLens.distance_id === 1 && [2, 3, 4].includes(previousLens.type_id) && !previousLens.cant_read_powers &&
                            (!previousLens.r_add || !previousLens.l_add)) {
                            allowSave = false
                        } else if(this.checked[key] && !previousLens.task_id &&
                            (!previousLens.measurement.pdR || !previousLens.measurement.pdL ||
                                !previousLens.measurement.btR || !previousLens.measurement.btL)) {
                            allowSave = false
                        }
                    }
                })

                this.contactLenses.forEach(contactLens => {
                    if(!contactLens.r_product.id || !contactLens.l_product.id) {
                        allowSave = false
                    } else if((contactLens.r_product.id !== 100 && contactLens.r_product.cyl && contactLens.r_axs === 'brak') ||
                        (contactLens.l_product.id !== 100 && contactLens.l_product.cyl && contactLens.l_axs === 'brak')) {
                        allowSave = false
                    } else if((contactLens.r_product.id !== 100 && contactLens.r_product.add && !contactLens.r_add) ||
                        (contactLens.l_product.id !== 100 && contactLens.l_product.add && !contactLens.l_add)) {
                        allowSave = false
                    } else if(!contactLens.r_product_name || !contactLens.l_product_name) {
                        allowSave = false
                    }
                })

                this.$emit('update-allow-save', allowSave)
                this.$emit('update-previous-lenses')
            },
            updateShow() {
                this.previousLensesShow = {
                    axs: false,
                    add: false,
                    base: false
                }

                this.data.forEach((previousLens, key) => {
                    if(previousLens.r_cyl || previousLens.l_cyl) {
                        this.previousLensesShow.axs = true
                    }

                    if(previousLens.distance_id === 1 && [2, 3, 4].includes(previousLens.type_id)) {
                        this.previousLensesShow.add = true
                    }

                    if(previousLens.r_prd || previousLens.l_prd) {
                        this.previousLensesShow.base = true
                    }

                    this.measurementCheck('visu_real', key)
                    this.measurementCheck('jawro_visu', key)
                })

                this.checkAllowSave()
            },

            changeDistanceAndType(key) {
                if(this.data[key].distance_id === 2 || ![2, 3, 4].includes(this.data[key].type_id)) {
                    this.data[key].r_add = 0
                    this.data[key].l_add = 0
                }

                if(!this.data[key].distance_id || !this.data[key].type_id) {
                    this.checked[key] = false
                }

                this.updateShow()
            },
            changeDistance(key, d) {
                if(d === 0) {
                    this.$notify.error('previouslens.notify.error.FARORNEAR')

                    return false
                }

                this.data[key].distance_id = d

                if(!this.data[key].distance_id) {
                    this.data[key].type_id = 0
                    this.data[key].target_id = 0
                }

                this.data.forEach((previousLens, index) => {
                    if(previousLens.distance_id === this.data[key].distance_id && index !== key && this.checked[index]) {
                        this.checked[key] = false
                    }
                })

                this.ifFarGoMinusTwo(this.data[key])
                this.ifNearGoMinusTwo(this.data[key])

                this.changeDistanceAndType(key)
            },
            changeType(key) {
                if(!this.data[key].type_id) {
                    this.data[key].target_id = 0
                }

                if(this.data[key].type_id !== 4) {
                    this.data[key].cant_read_powers = false
                }

                this.changeDistanceAndType(key)
            },

            filterPreviousType(key) {
                let _return = [],
                    for_near = [0, 1, 2],
                    previousLens = this.data[key]

                this.lensType.forEach(type => {
                    if(previousLens.distance_id === 2) { // dla bliży tylko jednoogniskowe i biurowe
                        if(for_near.includes(type.id)) {
                            _return.push(type)
                        }
                    } else if(previousLens.distance_id === 1) {
                        _return.push(type)
                    } else if(type.id === 0) {
                        _return.push(type)
                    }
                })

                if(previousLens.distance_id === 2 && !for_near.includes(previousLens.type_id)) {
                    previousLens.type_id = 0
                }

                return _return
            },
            filterPreviousTarget(key) {
                let _return = [],
                    for_near = [0, 1, 4, 5, 6],
                    previousLens = this.data[key]

                this.lensTarget.forEach(target => {
                    if(previousLens.type_id && previousLens.distance_id === 2) { // dla bliży tylko barwienie i wszystkie AR,
                        if(for_near.includes(target.id)) {
                            _return.push(target)
                        }
                    } else if(previousLens.type_id && previousLens.distance_id === 1) {
                        _return.push(target)
                    } else if(!target.id) {
                        _return.push(target)
                    }
                })

                if(previousLens.distance_id === 2 && !for_near.includes(previousLens.target_id)) {
                    previousLens.target_id = 0
                }

                return _return
            },

            updateSph(key, side, s) {
                this.data[key][side + '_sph'] = s

                this.checkAllowSave()
                this.measurementCheck('visu_real', key)
                this.measurementCheck('jawro_visu', key)
            },
            updateCyl(key, side, c) {
                this.data[key][side + '_cyl'] = c

                if(!this.data[key][side + '_cyl']) {
                    this.data[key][side + '_axs'] = 'brak'
                }

                this.updateShow()
            },
            updateAxs(key, side, a) {
                this.data[key][side + '_axs'] = a

                this.checkAllowSave()
            },
            updateAdd(key, side, a) {
                this.data[key][side + '_add'] = a

                this.checkAllowSave()
            },
            updatePrd(key, side, p) {
                this.data[key][side + '_prd'] = p

                if(!this.data[key][side + '_prd']) {
                    this.data[key][side + '_base'] = 0
                }

                this.updateShow()
            },
            updateBase(key, side, b) {
                this.data[key][side + '_base'] = b

                this.$emit('update-previous-lenses')
            },
            updateDioprometer(key, field, v) {
                this.data[key].measurement[field] = v

                this.measurementCheck('visu_real', key)
                this.measurementCheck('jawro_visu', key)
                this.checkAllowSave()

                this.$emit('update-previous-lenses')
            },

            updateMeasurement(key, measurement_id) {
                API.get('patient/previouslenses/measurement/' + measurement_id).then(res => {
                    this.data[key].measurement.ipad = res.data
                    this.data[key].measurement.ipad_measurements_previous_lens_id = this.data[key].measurement.ipad.id

                    this.ifNearGoMinusTwo(this.data[key])

                    this.measurementCheck('visu_real', key)
                    this.checkAllowSave()
                    this.$emit('update-previous-lenses')
                }).catch(() => {
                    this.$notify.error('optometrist.notify.error.MEASUREMENT')
                })
            },
            updateMeasurementJawroVisu(key, measurement) {
                this.data[key].measurement.measurementJawroVisu = measurement
                this.data[key].measurement.measurement_jawro_visu_id = this.data[key].measurement.measurementJawroVisu.id

                this.ifNearGoMinusTwo(this.data[key])

                this.measurementCheck('jawro_visu', key)
                this.checkAllowSave()
                this.$emit('update-previous-lenses')
            },

            addPreviousLens() {
                this.data.unshift({
                    patient_id: this.patientId,
                    distance_id: 0,
                    type_id: 0,
                    target_id: 0,
                    r_sph: 0,
                    r_cyl: 0,
                    r_axs: 'brak',
                    r_add: 0,
                    r_prd: 0,
                    r_base: 0,
                    l_sph: 0,
                    l_cyl: 0,
                    l_axs: 'brak',
                    l_add: 0,
                    l_prd: 0,
                    l_base: 0,
                    task_id: 0,
                    comments: '',
                    cant_read_powers: false,
                    measurement: {
                        ipad_measurements_previous_lens_id: 0,
                        measurement_jawro_visu_id: 0,
                        pdR: 0,
                        pdL: 0,
                        btR: 0,
                        btL: 0,
                        ipad: [],
                        measurementJawroVisu: []
                    }
                })

                this.checked.unshift(false)

                this.measurements_validate.visu_real.unshift({
                    pdR: false,
                    pdL: false,
                    btR: false,
                    btL: false,
                    delta: {
                        pdR: 0,
                        pdL: 0,
                        btR: 0,
                        btL: 0
                    }
                })

                this.measurements_validate.jawro_visu.unshift({
                    pdR: false,
                    pdL: false,
                    btR: false,
                    btL: false,
                    delta: {
                        pdR: 0,
                        pdL: 0,
                        btR: 0,
                        btL: 0
                    }
                })

                this.checkAllowSave()
            },
            removePreviousLens(key) {
                this.data.splice(key, 1)
                this.checked.splice(key, 1)

                this.checkAllowSave()
            },

            uncheckFromConfirm(key, distance, toCheck) {
                if(this.mode === 'interview') {
                    if(confirm(this.$filters.translate('previouslens.confirm.uncheck.MESSAGE'))) {
                        this.$nextTick(() => {
                            this.$set(this.checked, key, false)
                        })

                        this.$emit('delete-visus', distance)

                        if(typeof toCheck !== 'undefined') {
                            this.$nextTick(() => {
                                this.$set(this.checked, toCheck, true)
                            })

                            if(this.data[key].task_id) {
                                this.checked[key] = false
                            }
                        }
                    } else {
                        this.$nextTick(() => {
                            this.$set(this.checked, key, true)
                        })

                        if(typeof toCheck !== 'undefined') {
                            this.$nextTick(() => {
                                this.$set(this.checked, toCheck, false)
                            })
                        }
                    }
                } else {
                    this.$nextTick(() => {
                        this.$set(this.checked, key, false)
                    })

                    if(typeof toCheck !== 'undefined') {
                        this.$nextTick(() => {
                            this.$set(this.checked, toCheck, true)
                        })
                    }
                }
            },
            checkPreviousLens(key, distance_id) {
                if(this.checked[key]) {
                    let nothing_check = true

                    this.data.forEach((previousLens, index) => {
                        if(previousLens.distance_id === this.data[key].distance_id) {
                            if(this.checked[index] && key !== index) {
                                this.uncheckFromConfirm(index, distance_id, key)

                                nothing_check = false
                            }
                        }
                    })

                    if(nothing_check) {
                        this.$nextTick(() => {
                            this.$set(this.checked, key, true)
                        })
                    }
                } else {
                    this.uncheckFromConfirm(key, distance_id)
                }

                this.$nextTick(() => {
                    this.data.forEach((previousLens, key) => {
                        this.measurementCheck('visu_real', key)
                        this.measurementCheck('jawro_visu', key)
                    })

                    this.checkAllowSave()
                })
            },

            ifFarGoMinusTwo(previousLens) {
                if(!previousLens.task_id && previousLens.distance_id === 1) {
                    previousLens.measurement.ipad.pd += 4
                    previousLens.measurement.ipad.pdR += 2
                    previousLens.measurement.ipad.pdL += 2

                    previousLens.measurement.ipad.btR += 2
                    previousLens.measurement.ipad.btL += 2
                }
            },
            ifNearGoMinusTwo(previousLens) {
                if(!previousLens.task_id && previousLens.distance_id === 2) {
                    previousLens.measurement.ipad.pd -= 4
                    previousLens.measurement.ipad.pdR -= 2
                    previousLens.measurement.ipad.pdL -= 2

                    previousLens.measurement.ipad.btR -= 2
                    previousLens.measurement.ipad.btL -= 2
                }
            },

            checkNoPowers(previousLens) {
                if(previousLens.cant_read_powers) {
                    if(confirm(this.$filters.translate('previouslens.confirm.progressive.MESSAGE'))) {
                        previousLens.r_sph = 0
                        previousLens.r_cyl = 0
                        previousLens.r_axs = 'brak'
                        previousLens.r_add = 0
                        previousLens.r_prd = 0
                        previousLens.r_base = 0

                        previousLens.l_sph = 0
                        previousLens.l_cyl = 0
                        previousLens.l_axs = 'brak'
                        previousLens.l_add = 0
                        previousLens.l_prd = 0
                        previousLens.l_base = 0
                    } else {
                        this.$nextTick(() => {
                            previousLens.cant_read_powers = false
                        })
                    }
                }

                this.$nextTick(() => {
                    this.$emit('update-previous-lenses')
                })
            },

            updateContactLensesShow() {
                this.contactLensesShow = {
                    sph: false,
                    cyl: false,
                    axs: false,
                    add: false
                }

                this.contactLenses.forEach(contactLens => {
                    if(contactLens.r_product.sph || contactLens.l_product.sph) {
                        this.contactLensesShow.sph = true
                    }

                    if(contactLens.r_product.cyl || contactLens.l_product.cyl) {
                        this.contactLensesShow.cyl = true
                    }

                    if(contactLens.r_product.axs || contactLens.l_product.axs) {
                        this.contactLensesShow.axs = true
                    }

                    if(contactLens.r_product.add || contactLens.l_product.add) {
                        this.contactLensesShow.add = true
                    }
                })
            },

            changeContactLens(contactLens, side) {
                if(!contactLens.r_product.sph) {
                    contactLens.r_sph = 0
                }

                if(!contactLens.r_product.cyl) {
                    contactLens.r_cyl = 0
                    contactLens.r_axs = 'brak'
                }

                if(!contactLens.r_product.add) {
                    contactLens.r_add = 0
                }

                if(!contactLens.l_product.sph) {
                    contactLens.l_sph = 0
                }

                if(!contactLens.l_product.cyl) {
                    contactLens.l_cyl = 0
                    contactLens.l_axs = 'brak'
                }

                if(!contactLens.l_product.add) {
                    contactLens.l_add = 0
                }

                if(side === 'r') {
                    if(contactLens.r_product.id > 0 && contactLens.r_product.id !== 100) {
                        contactLens.r_product_name = contactLens.r_product.producent + ' ' + contactLens.r_product.name
                        contactLens.r_type_id = contactLens.r_product.type_id
                    } else {
                        contactLens.r_product_name = ''
                        contactLens.r_type_id = 0
                    }
                }

                if(side === 'l') {
                    if(contactLens.l_product.id > 0 && contactLens.l_product.id !== 100) {
                        contactLens.l_product_name = contactLens.l_product.producent + ' ' + contactLens.l_product.name
                        contactLens.l_type_id = contactLens.l_product.type_id
                    } else {
                        contactLens.l_product_name = ''
                        contactLens.l_type_id = 0
                    }
                }

                if(side === 'r' && contactLens.r_product.id && !contactLens.l_product_name) {
                    contactLens.l_product = _.clone(contactLens.r_product)
                    contactLens.l_product_id = contactLens.r_product_id
                    contactLens.l_product_name = contactLens.r_product_name
                    contactLens.l_type_id = contactLens.r_type_id
                }

                if(side === 'l' && contactLens.l_product.id && !contactLens.r_product_name) {
                    contactLens.r_product = _.clone(contactLens.l_product)
                    contactLens.r_product_id = contactLens.l_product_id
                    contactLens.r_product_name = contactLens.l_product_name
                    contactLens.r_type_id = contactLens.l_type_id
                }

                this.updateContactLensesShow()
                this.checkAllowSave()
                this.$emit('update-previous-lenses-contact')
            },
            changeContactLensInput(contactLens, side) {
                if(side === 'r' && contactLens.r_product_name && !contactLens.l_product_name) {
                    contactLens.l_product_name = contactLens.r_product_name
                }

                if(side === 'l' && contactLens.l_product_name && !contactLens.r_product_name) {
                    contactLens.r_product_name = contactLens.l_product_name
                }

                this.checkAllowSave()
                this.$emit('update-previous-lenses-contact')
            },

            updateContactLensSph(key, side, s) {
                this.contactLenses[key][side + '_sph'] = s

                this.updateContactLensesShow()
                this.checkAllowSave()
                this.$emit('update-previous-lenses-contact')
            },
            updateContactLensCyl(key, side, c) {
                this.contactLenses[key][side + '_cyl'] = c

                if(!this.contactLenses[key][side + '_cyl']) {
                    this.contactLenses[key][side + '_axs'] = 'brak'
                }

                this.updateContactLensesShow()
                this.checkAllowSave()
                this.$emit('update-previous-lenses-contact')
            },
            updateContactLensAxs(key, side, a) {
                this.contactLenses[key][side + '_axs'] = a

                this.updateContactLensesShow()
                this.checkAllowSave()
                this.$emit('update-previous-lenses-contact')
            },
            updateContactLensAdd(key, side, a) {
                this.contactLenses[key][side + '_add'] = a

                this.updateContactLensesShow()
                this.checkAllowSave()
                this.$emit('update-previous-lenses-contact')
            },

            addContactLens() {
                this.contactLenses.unshift({
                    patient_id: this.patientId,
                    r_product: {
                        id: 0,
                        producent: '',
                        name: '-',
                        sph: false,
                        cyl: false,
                        add: false
                    },
                    l_product: {
                        id: 0,
                        producent: '',
                        name: '-',
                        sph: false,
                        cyl: false,
                        add: false
                    },
                    r_product_name: '',
                    r_type_id: 0,
                    r_sph: 0,
                    r_cyl: 0,
                    r_axs: 'brak',
                    r_add: 0,
                    l_product_name: '',
                    l_type_id: 0,
                    l_sph: 0,
                    l_cyl: 0,
                    l_axs: 'brak',
                    l_add: 0,
                    comments: ''
                })

                this.checkedContact.unshift(false)

                this.checkAllowSave()
                this.$emit('update-previous-lenses-contact')
            },
            removeContactLens(key) {
                this.contactLenses.splice(key, 1)
                this.checkedContact.splice(key, 1)

                this.checkAllowSave()
                this.$emit('update-previous-lenses-contact')
            },

            uncheckFromConfirmContactLens(key, toCheck) {
                if(this.mode === 'interview') {
                    if(confirm(this.$filters.translate('previouslens.confirm.uncheckcontactlens.MESSAGE'))) {
                        this.$nextTick(() => {
                            this.$set(this.checkedContact, key, false)
                        })

                        this.$emit('delete-visus-correction')

                        if(typeof toCheck !== 'undefined') {
                            this.$nextTick(() => {
                                this.$set(this.checkedContact, toCheck, true)
                            })
                        }
                    } else {
                        this.$nextTick(() => {
                            this.$set(this.checkedContact, key, true)
                        })

                        if(typeof toCheck !== 'undefined') {
                            this.$nextTick(() => {
                                this.$set(this.checkedContact, toCheck, false)
                            })
                        }
                    }
                } else {
                    this.$nextTick(() => {
                        this.$set(this.checkedContact, key, false)
                    })

                    if(typeof toCheck !== 'undefined') {
                        this.$nextTick(() => {
                            this.$set(this.checkedContact, toCheck, true)
                        })
                    }
                }
            },
            checkContactLens(key) {
                if(this.checkedContact[key]) {
                    let nothing_check = true

                    this.contactLenses.forEach((contactLens, index) => {
                        if(this.checkedContact[index] && key !== index) {
                            this.uncheckFromConfirmContactLens(index, key)

                            nothing_check = false
                        }
                    })

                    if(nothing_check) {
                        this.$nextTick(() => {
                            this.$set(this.checkedContact, key, true)
                        })
                    }
                } else {
                    this.uncheckFromConfirmContactLens(key)
                }

                this.$emit('update-previous-lenses-contact')
            },
            checkGlasses(type) {
                if(this.glasses.first && this.glasses.none) {
                    this.glasses[type === 'first' ? 'none' : 'first'] = false
                }

                this.$emit('update-glasses-checkbox')
            },
            parseContactLenses(products) {
                let other_id = 100,
                    others = _.cloneDeep(_.find(products, {id: other_id}))

                _.remove(products, {id: other_id})

                this.contactLenses.forEach(contactLens => {
                    ['r', 'l'].forEach(side => {
                        if(contactLens[side + '_previous_lenses_product_id'] !== other_id &&
                            typeof _.find(products, {id: contactLens[side + '_previous_lenses_product_id']}) === 'undefined') {
                            let product = _.cloneDeep(contactLens[side + '_product'])
                            this.disabled_product_ids.push(product.id)

                            products.push(product)
                        }
                    })
                })

                products.push(others)

                return products
            }
        },
        computed: {
            disabledCheckboxs() {
                let exists = this.data.length + this.contactLenses.length

                if(exists) {
                    this.glasses.first = false
                    this.glasses.none = false
                }

                return !!exists
            }
        }
    }
</script>

<style lang="less" scoped>
    .btn-jawro-visu {
        display: block;
        margin: 10px auto;
    }
</style>