<template lang="pug">
    OptiButton(@click.native="openWiki") Wiki
        template(#icon)
            b W
</template>

<script>
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'

    export default {
        name: 'OptiButtonWikipedia',
        components: {
            OptiButton
        },
        props: {
            url: {
                type: String,
                required: true
            }
        },
        methods: {
            openWiki() {
                window.open('https://wiki.eopti.pl/' + this.url, '_blank')
            }
        }
    }
</script>