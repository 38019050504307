<template lang="pug">
    li.opti-menu-item(v-if="!item.separator" @click="onClick" mouseenter="onMouseEnter" :class="itemClass")
        a.opti-menu-item__header(:href="href")
            span.opti-menu-item__icon(:class="iconClass")
                span.opti-menu-item__dot(v-if="dotVisible")
            template(v-if="showNames")
                span(v-if="item.type !== 'raw'") {{itemText}}
                span(v-if="item.type === 'raw'")
                    AngularComponent(:component="rawComponent")

                span.label.label-danger.right-label(v-if="labelValue") {{labelValue}}
                span.opti-menu-item__icon.icon-empty.fa.fa-caret-right.right(v-if="item.submenu" :class="{ 'no-margin': !!labelValue }")
            .clearfix

    li.opti-menu-item__separator(v-else)
</template>

<script>
import AngularComponent from '../../../_bridge/components/AngularComponent'

export default {
    name: 'OptiMenuItem',
    components: {AngularComponent},
    props: {
        item: {
            type: Object,
            required: true
        },
        showNames: {
            type: Boolean,
            default: true
        },
        showDot: {
            type: Boolean,
            default: false
        },
        useHardcodedLabels: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        itemText() {
            if (this.item.translate) return this.$filters.translate(this.item.translate)
            return this.item.text
        },
        itemClass() {
            let cls = []

            if (!this.item.submenu) {
                if (!this.item.forceFullWidth) {
                    cls.push('no-submenu')
                }
            }

            if (this.item.type === 'raw') {
                cls.push('raw-item')
            }

            if (this.$store.getters['globals/windowHeight'] < 700 && !this.$store.getters['globals/isMobile']) {
                cls.push('small')
            }

            return cls.join(' ')
        },
        iconClass() {
            if (this.item.icon) return this.item.icon
            return 'icon-empty'
        },
        dotVisible() {
            if (!this.showDot) return false
            return !!this.labelValue
        },
        labelHardcodedValue() {
            if (this.item.translate === 'sidebar.WAREHOUSE') {
                let transfers = this.getLabel('transfers_to_send')

                if (transfers) {
                    return transfers
                }

                return false
            } else if (this.item.translate === 'sidebar.CATALOG') {
                return this.getLabel('layout_errors') || 0
            } else if (this.item.translate === 'alert.ALERTS') {
                return this.$store.getters['dashboard/alerts']
            } else if (this.item.translate === 'timeline.MESSAGES') {
                return this.$store.getters['dashboard/unread'].all
            }

            return false
        },
        labelValue() {
            if (this.useHardcodedLabels) return this.labelHardcodedValue
            let label = this.item.label
            if (!label) return false

            if (['layout_errors', 'layout_blocks'].includes(label)) {
                // To zawiera proste inty a nie arraye ze statusami wiec można szybciej skończyć
                return this.getLabel(label)
            }

            if (['unreadMessage', 'alertUnreadMessages'].includes(label)) {
                // TODO: angular pobierał z $rootScope[label]

                if (label === 'unreadMessage') return this.$store.getters['dashboard/unread'].all

                return 0
            }

            let key = label.split('.')[0]

            if (key === 'bugtrack') key = 'errors'
            if (key === 'sale') key = 'sale_reclamations'

            let collection = this.getLabel(key)

            if (collection && collection.length) {

                if (['shipments', 'transfers'].includes(key)) {
                    // przeniesienia i wysyłki maja dodatkowe typy (wchodzące/przychodzące)
                    let status = label.split('.')[3],
                        type   = label.split('.')[1]

                    let item = collection.find(item => item.name === status)
                    if (!item) return false

                    return type === 'outcoming' ? item.total1 : item.total2
                }

                let status = label.split('.').pop()
                let item = collection.find(item => item.name === status)
                return item ? item.total : 0
            }

            return false
        },
        rawComponent() {
            let vm = this

            return {
                template: this.item.text,
                $ctrl: {
                    // nothing?
                }
            }
        },
        href() {
            return this.$state.href(
                this.item.sref,
                this.item.params || {}
            )
        }
    },
    methods: {
        getLabel(name) {
            return this.$store.getters['dashboard/get'](name)
        },
        onClick() {
            this.$emit('click', this.item)
        },
        onMouseEnter() {
            this.$emit('click', this.item)
        }
    },
}
</script>

<style scoped lang="less">
    ul {
        list-style: none;
        width: 240px;

        xflex: 1;
        padding: 0;
        margin: 0;
    }

    .opti-menu-item {
        height: 100%;
        min-height: 48px;
        //padding: 8px;
        position: relative;
        cursor: pointer;
        display: block;

        border-bottom-left-radius: 8px;
        border-top-left-radius: 8px;
        margin-left: 8px;
        margin-bottom: 2px;
        padding-left: 16px;

        &.small {
            min-height: 36px;

            & .opti-menu-item__header {
                min-height: 36px;
            }
        }

        &__separator {
            height: 4px;
            background-color: rgba(0, 0, 0, 0.3);
            //margin-top: 4px;
            //margin-bottom: 4px;
            margin: 4px;
            margin-left: 20px;
        }

        &.no-submenu {
            border-radius: 8px;
            margin-right: 8px;
            padding-right: 16px;
        }

        &.raw-item {
            min-height: calc(48px * 4);
            padding: 10px;
        }

        & .right {
            margin-left: auto;
            order: 2;
            width: 30px;
            height: 30px;

            &.no-margin {
                margin-left: unset;
            }
        }

        & .right-label {
            margin-left: auto;
            order: 2;
            box-shadow: 0 0 3px 0 rgba(0,0,0,0.5);
        }

        //&:hover, &.active {
        //    background-color: rgba(255, 255, 255, 0.15);
        //}

        &__header {
            height: 100%;
            min-height: 48px;
            display: flex;
            justify-content: start;
            align-items: center;
            color: unset;
            text-decoration: none;

            & > span {
                display: block;
            }
        }

        &__icon {
            display: inline-block;
            padding: 8px;
            border-radius: 2px;
            //background-color: #2f2f2f;
            background-color: rgba(0,0,0,.2);
            margin-right: 8px;
            width: 30px;
            height: 30px;
            text-align: center;
            position: relative;
        }

        &__dot {
            display: block;
            position: absolute;
            right: -2px;
            bottom: -2px;
            width: 10px;
            height: 10px;
            border-radius: 5px;
            background-color: @alert-danger-bg;
        }

        .icon-empty {
            background-color: unset;
        }
    }
</style>
