<template lang="pug">
    OptiDialog
        template(#header)
            i.fa.fa-navicon.mr3
            span {{'settings.general.info.HEADING'|translate}}

            i.fa.fa-lg.fa-remove.pull-right(style="cursor:pointer;padding:3px" :title="'default.CLOSE'|translate"
                @click="$emit('close')")

        template(#body)
            .form-horizontal
                .form-group
                    label.col-xs-12.star-required(for="bgz") {{'settings.general.info.BGZ'|translate}}
                    .col-xs-12
                        input#bgz.form-control(name="bgz" v-model="form.bgz" required)
                        OptiValidate(f="bgz" :data="$v.form.bgz")
                .form-group
                    label.col-xs-12.star-required(for="payments") {{'settings.general.info.TAKINGSPAYMENTS'|translate}}
                    .col-xs-12
                        input#payments.form-control(name="payments" v-model="form.payments" required)
                        OptiValidate(f="payments" :data="$v.form.payments")

                OptiDimmer(:active="loading")

        template(#footer)
            OptiButtonSave(:disabled="$v.form.$invalid || loading" @click.native="save")
</template>

<script>
    import {API} from '@/js/app/vue/api'
    import {required} from 'vuelidate/lib/validators'

    import OptiDialog from '@/js/app/vue/components/OptiDialog'
    import OptiValidate from '@/js/app/vue/components/OptiValidate'
    import OptiButtonSave from '@/js/app/vue/components/Button/OptiButtonSave'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiSettingsGeneralDialogInformation',
        components: {
            OptiDialog,
            OptiValidate,
            OptiButtonSave,
            OptiDimmer
        },
        props: {
            data: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                form: _.cloneDeep(this.data),

                loading: false
            }
        },
        validations: {
            form: {
                bgz: {
                    required
                },
                payments: {
                    required
                }
            }
        },
        methods: {
            save() {
                this.loading = true

                API.post('settings/general/information/save', this.form).then(res => {
                    this.$emit('saved', res.data)

                    this.$notify.success('settings.general.info.notify.SAVE')
                }).finally(() => {
                    this.loading = false

                    this.$emit('close')
                }).catch(() => {
                    this.$notify.error('settings.general.info.notify.error.SAVE')
                })
            }
        }
    }
</script>

<style lang="less" scoped>
    /deep/ .modal-container {
        max-width: 500px;
    }
</style>