<template lang="pug">
    OptiDialog
        template(#header)
            i.fa.fa-indent.mr3
            span {{'tasks.compare.COMPAREILOG'|translate}}

            i.fa.fa-lg.fa-remove.pull-right(style="cursor:pointer;padding:3px" :title="'default.CLOSE'|translate"
                @click="$emit('closed')")

        template(#body)
            .row.tables-compare.text-expressive(ref="tablesCompare" v-if="!loading")
                .col-xs-12.text-center(style="margin-top:5px" v-if="ilog.right_lens_to_calculate")
                    i.fa.fa-exclamation-circle.mr3
                    span {{'tasks.admin.compareilog.calculate.RIGHT'|translate}}

                .col-xs-12.text-center(style="margin-top:5px" v-if="ilog.left_lens_to_calculate")
                    i.fa.fa-exclamation-circle.mr3
                    span {{'tasks.admin.compareilog.calculate.LEFT'|translate}}

                .col-xs-12.no-ilog(v-if="forbiddenInputs")
                    .alert.alert-danger.text-center(style="margin-bottom:0")
                        i.fa.fa-exclamation-circle.mr3
                        span {{'tasks.admin.compareilog.WRONGILOG'|translate}}
                        span(v-if="ilog.checked_grindery") "Wybrano szlifowanie" 
                        span(v-if="ilog.prism_error") "Pryzma horyzontalna/wertykalna" 
                        span(v-if="ilog.checked_slab_off") "Slab off is selected" 
                        span(v-if="ilog.patient_name") "Imię pacjenta" 
                        span(v-if="ilog.patient_sex") "Płeć pacjenta" 
                        span(v-if="ilog.patient_surname") "Nazwisko pacjenta" 
                        span(v-if="ilog.date_of_birth") "Data urodzenia pacjenta" 
                        span(v-if="ilog.weight_compensation") "Wyrównanie ciężaru" 

                .col-xs-4(v-if="requiredHoyaOrder && !save_mode")
                    table.table.table-striped.table-responsive.middle
                        thead
                            tr
                                th.text-right
                                th.text-right Opti
                                th.text-right iLog
                            tr
                                th.text-center(colspan="3") {{'tasks.RIGHT'|translate}} {{'tasks.EYE'|translate}}
                        tbody
                            tr(:class="{'bg-warning': task.rightEye.sphere !== ilog.sphere_right}")
                                td.text-right {{'tasks.admin.compareilog.SPHERE'|translate}}
                                td.text-right {{task.rightEye.sphere|plus}}
                                td.text-right {{ilog.sphere_right|plus}}
                            tr(:class="{'bg-warning': task.rightEye.cylinder !== ilog.cylinder_right}")
                                td.text-right {{'tasks.admin.compareilog.CYLINDER'|translate}}
                                td.text-right {{task.rightEye.cylinder|plus}}
                                td.text-right {{ilog.cylinder_right|plus}}
                            tr(:class="{'bg-warning': task.rightEye.axis !== ilog.axis_right}")
                                td.text-right {{'tasks.admin.compareilog.AXIS'|translate}}
                                td.text-right {{task.rightEye.axis}}
                                td.text-right {{ilog.axis_right}}
                            tr(:class="{'bg-warning': task.rightEye.addition !== ilog.addition_right}"
                                v-if="task.rightEye.addition && ilog.addition_right")
                                td.text-right {{'tasks.admin.compareilog.ADDITION'|translate}}
                                td.text-right {{task.rightEye.addition|plus}}
                                td.text-right {{ilog.addition_right|plus}}
                            tr(:class="{'bg-warning': rightBt !== ilog.bt_right}")
                                td.text-right
                                    span(v-if="task.type !== 3") BT
                                    span(v-if="task.type === 3") {{'tasks.admin.compareilog.BTBIFOCAL'|translate}}
                                td.text-right {{rightBt|number}}
                                td.text-right {{ilog.bt_right|number}}
                            tr(:class="{'bg-warning': task.rightEye.pdDist !== ilog.pd_far_right}"
                                    v-if="task.type !== 1")
                                td.text-right {{'tasks.admin.compareilog.PDDIST'|translate}}
                                td.text-right {{task.rightEye.pdDist|number}}
                                td.text-right {{ilog.pd_far_right|number}}
                            tr(:class="{'bg-warning': task.rightEye.pdNear !== ilog.pd_far_right}"
                                    v-if="task.type === 1")
                                td.text-right {{'tasks.admin.compareilog.PDNEAR'|translate}}
                                td.text-right {{task.rightEye.pdNear|number}}
                                td.text-right {{ilog.pd_far_right|number}}
                            tr(:class="{'bg-warning': task.rightEye.prism !== ilog.angle_right}" v-if="task.rightEye.prism")
                                td.text-right {{'tasks.admin.compareilog.PRISM'|translate}}
                                td.text-right {{task.rightEye.prism|number(2)}}
                                td.text-right {{ilog.angle_right|number(2)}}
                            tr(:class="{'bg-warning': task.rightEye.prismAngle !== ilog.base_right}" v-if="task.rightEye.prism")
                                td.text-right {{'tasks.admin.compareilog.PRISMANGLE'|translate}}
                                td.text-right {{task.rightEye.prismAngle}}
                                td.text-right {{ilog.base_right}}
                        tbody(v-if="!task.hoyaLens.individual")
                            tr
                                td.text-bold.text-center(colspan="3") {{'tasks.admin.compareilog.PRODUCTION'|translate}}
                            tr(:class="{'bg-warning':  cvdWarning}" v-if="task.cvd || task.hoyaLens.cvd")
                                td.text-right {{'tasks.admin.compareilog.CVD'|translate}}
                                td.text-right {{task.cvd|number}}
                                td.text-right {{task.hoyaLens.cvd|number}} (Δ{{task.hoyaLens.cvd_delta|number}})
                            tr(:class="{'bg-warning': paWarning || task.inclination < 0 || task.hoyaLens.pa < 0}")
                                td.text-right {{'tasks.admin.compareilog.INCLINATION'|translate}}
                                td.text-right {{task.inclination|number}}
                                td.text-right {{task.hoyaLens.pa|number}} (Δ{{task.hoyaLens.pa_delta|number}})
                            tr(:class="{'bg-warning': fffaWarning || task.fffa < 0 || task.hoyaLens.fffa < 0}")
                                td.text-right {{'tasks.admin.compareilog.FFFA'|translate}}
                                td.text-right {{task.fffa|number}}
                                td.text-right {{task.hoyaLens.fffa|number}} (Δ{{task.hoyaLens.fffa_delta|number}})
                        tbody(v-if="task.hoyaLens.individual")
                            tr
                                td.text-bold.text-center(colspan="3") {{'tasks.admin.compareilog.INDIVIDUAL'|translate}}
                            tr(:class="{'bg-warning':  task.cvd !== ilog.cvd_right}" v-if="task.cvd || ilog.cvd_right")
                                td.text-right {{'tasks.admin.compareilog.CVD'|translate}}
                                td.text-right {{task.cvd|number}}
                                td.text-right {{ilog.cvd_right|number}}
                            tr(:class="{'bg-warning': (task.inclination !== ilog.inclination) || task.inclination < 0 || ilog.inclination < 0}")
                                td.text-right {{'tasks.admin.compareilog.INCLINATION'|translate}}
                                td.text-right {{task.inclination|number}}
                                td.text-right {{ilog.inclination|number}}
                            tr(:class="{'bg-warning': (task.fffa !== ilog.fffa) || task.fffa < 0 || ilog.fffa < 0}")
                                td.text-right {{'tasks.admin.compareilog.FFFA'|translate}}
                                td.text-right {{task.fffa|number}}
                                td.text-right {{ilog.fffa|number}}

                    table.table.table-striped.table-responsive.middle.replace-to-smaller-table(
                        v-for="(measurement, app) in ilog.measurements")
                        thead
                            tr
                                th.text-center(colspan="100")
                                    span {{app}} {{measurement.pa|number(1)}} (PA)
                                    small(style="display:block")
                                        span BT {{measurement.raw_method|translate|lower}}
                                        span(style="margin-left:3px" v-show="measurement.raw_method !== measurement.method")
                                            i.fa.fa-arrow-right.mr3
                                            span {{measurement.method|translate|lower}}
                            tr
                                th
                                th {{'tasks.admin.bt.APP'|translate}}
                                th {{'tasks.admin.bt.CONVERTED'|translate}}
                                th {{'tasks.admin.bt.SHOULD_BE'|translate}}
                                th {{'tasks.admin.bt.TASK'|translate}}

                        tbody
                            tr(:class="{'bg-warning': measurement.r.should_be !== measurement.r.converted || measurement.r.should_be !== measurement.r.task}")
                                td.bold {{'prescription.RIGHT'|translate}}
                                td.text-right {{measurement.r.raw|number(1)}}
                                td.text-right {{measurement.r.converted|number(1)}}
                                td.text-right {{measurement.r.should_be|number(1)}}
                                td.text-right {{measurement.r.task|number(1)}}

                            tr(:class="{'bg-warning': measurement.l.should_be !== measurement.l.converted || measurement.l.should_be !== measurement.l.task}")
                                td.bold {{'prescription.LEFT'|translate}}
                                td.text-right {{measurement.l.raw|number(1)}}
                                td.text-right {{measurement.l.converted|number(1)}}
                                td.text-right {{measurement.l.should_be|number(1)}}
                                td.text-right {{measurement.l.task|number(1)}}

                .col-xs-4(v-if="requiredHoyaOrder && !save_mode")
                    table.table.table-striped.table-responsive.middle
                        thead
                            tr
                                th.text-right
                                th.text-right Opti
                                th.text-right iLog
                            tr
                                th.text-center(colspan="3") {{'tasks.LEFT'|translate}} {{'tasks.EYE'|translate}}
                        tbody
                            tr(:class="{'bg-warning': task.leftEye.sphere !== ilog.sphere_left}")
                                td.text-right {{'tasks.admin.compareilog.SPHERE'|translate}}
                                td.text-right {{task.leftEye.sphere|plus}}
                                td.text-right {{ilog.sphere_left|plus}}
                            tr(:class="{'bg-warning': task.leftEye.cylinder !== ilog.cylinder_left}")
                                td.text-right {{'tasks.admin.compareilog.CYLINDER'|translate}}
                                td.text-right {{task.leftEye.cylinder|plus}}
                                td.text-right {{ilog.cylinder_left|plus}}
                            tr(:class="{'bg-warning': task.leftEye.axis !== ilog.axis_left}")
                                td.text-right {{'tasks.admin.compareilog.AXIS'|translate}}
                                td.text-right {{task.leftEye.axis}}
                                td.text-right {{ilog.axis_left}}
                            tr(:class="{'bg-warning': task.leftEye.addition !== ilog.addition_left}"
                                v-if="task.leftEye.addition && ilog.addition_left")
                                td.text-right {{'tasks.admin.compareilog.ADDITION'|translate}}
                                td.text-right {{task.leftEye.addition|plus}}
                                td.text-right {{ilog.addition_left|plus}}
                            tr(:class="{'bg-warning': leftBt !== ilog.bt_left}")
                                td.text-right
                                    span(v-if="task.type !== 3") BT
                                    span(v-if="task.type === 3") {{'tasks.admin.compareilog.BTBIFOCAL'|translate}}
                                td.text-right {{leftBt|number}}
                                td.text-right {{ilog.bt_left|number}}
                            tr(:class="{'bg-warning': task.leftEye.pdDist !== ilog.pd_far_left}" v-if="task.type !== 1")
                                td.text-right {{'tasks.admin.compareilog.PDDIST'|translate}}
                                td.text-right {{task.leftEye.pdDist|number}}
                                td.text-right {{ilog.pd_far_left|number}}
                            tr(:class="{'bg-warning': task.leftEye.pdNear !== ilog.pd_far_left}" v-if="task.type === 1")
                                td.text-right {{'tasks.admin.compareilog.PDNEAR'|translate}}
                                td.text-right {{task.leftEye.pdNear|number}}
                                td.text-right {{ilog.pd_far_left|number}}
                            tr(:class="{'bg-warning': task.leftEye.prism !== ilog.angle_left}"
                                v-if="task.leftEye.prism")
                                td.text-right {{'tasks.admin.compareilog.PRISM'|translate}}
                                td.text-right {{task.leftEye.prism|number(2)}}
                                td.text-right {{ilog.angle_left|number(2)}}
                            tr(:class="{'bg-warning': task.leftEye.prismAngle !== ilog.base_left}" v-if="task.leftEye.prism")
                                td.text-right {{'tasks.admin.compareilog.PRISMANGLE'|translate}}
                                td.text-right {{task.leftEye.prismAngle}}
                                td.text-right {{ilog.base_left}}
                        tbody(v-if="task.hoyaLens.individual")
                            tr
                                td.text-bold.text-center(colspan="3") {{'tasks.admin.compareilog.INDIVIDUAL'|translate}}
                            tr(:class="{'bg-warning': task.cvdLeft !== ilog.cvd_left}" v-if="task.cvdLeft || ilog.cvd_left")
                                td.text-right {{'tasks.admin.compareilog.CVD'|translate}}
                                td.text-right {{task.cvdLeft|number}}
                                td.text-right {{ilog.cvd_left|number}}
                        tbody
                            tr
                                td.text-bold.text-center(colspan="3") {{'tasks.admin.compareilog.MEASURMENT'|translate}}
                            tr(:class="{'bg-warning': (ilog.optiMeasurments.right.minThickness !== ilog.ilogMeasurments.right.minThickness) || (ilog.optiMeasurments.left.minThickness !== ilog.ilogMeasurments.left.minThickness)}")
                                td.text-right {{'tasks.admin.compareilog.MINTHICK'|translate}}
                                td.text-right
                                    span(v-if="ilog.optiMeasurments.right.minThickness === ''") {{'prescription.RIGHT'|translate}}: brak
                                    span(v-if="ilog.optiMeasurments.right.minThickness !== ''") {{'prescription.RIGHT'|translate}}: {{ilog.optiMeasurments.right.minThickness|number}}
                                    br
                                    |
                                    span(v-if="ilog.optiMeasurments.left.minThickness === ''") {{'prescription.LEFT'|translate}}: brak
                                    span(v-if="ilog.optiMeasurments.left.minThickness !== ''") {{'prescription.LEFT'|translate}}: {{ilog.optiMeasurments.left.minThickness|number}}
                                td.text-right
                                    span(v-if="ilog.ilogMeasurments.right.minThickness === ''") {{'prescription.RIGHT'|translate}}: brak
                                    span(v-if="ilog.ilogMeasurments.right.minThickness !== ''") {{'prescription.RIGHT'|translate}}: {{ilog.ilogMeasurments.right.minThickness|number}}
                                    br
                                    |
                                    span(v-if="ilog.ilogMeasurments.left.minThickness === ''") {{'prescription.LEFT'|translate}}: brak
                                    span(v-if="ilog.ilogMeasurments.left.minThickness !== ''") {{'prescription.LEFT'|translate}}: {{ilog.ilogMeasurments.left.minThickness|number}}
                            tr(:class="{'bg-warning': (ilog.optiMeasurments.right.maxThickness !== ilog.ilogMeasurments.right.maxThickness) || (ilog.optiMeasurments.left.maxThickness !== ilog.ilogMeasurments.left.maxThickness)}")
                                td.text-right {{'tasks.admin.compareilog.MAXTHICK'|translate}}
                                td.text-right
                                    span(v-if="ilog.optiMeasurments.right.maxThickness === ''") {{'prescription.RIGHT'|translate}}: brak
                                    span(v-if="ilog.optiMeasurments.right.maxThickness !== ''") {{'prescription.RIGHT'|translate}}: {{ilog.optiMeasurments.right.maxThickness|number}}
                                    br
                                    |
                                    span(v-if="ilog.optiMeasurments.left.maxThickness === ''") {{'prescription.LEFT'|translate}}: brak
                                    span(v-if="ilog.optiMeasurments.left.maxThickness !== ''") {{'prescription.LEFT'|translate}}: {{ilog.optiMeasurments.left.maxThickness|number}}
                                td.text-right
                                    span(v-if="ilog.ilogMeasurments.right.maxThickness === '' ") {{'prescription.RIGHT'|translate}}: brak
                                    span(v-if="ilog.ilogMeasurments.right.maxThickness !== '' ") {{'prescription.RIGHT'|translate}}: {{ilog.ilogMeasurments.right.maxThickness|number}}
                                    br
                                    |
                                    span(v-if="ilog.ilogMeasurments.left.maxThickness === ''") {{'prescription.LEFT'|translate}}: brak
                                    span(v-if="ilog.ilogMeasurments.left.maxThickness !== ''") {{'prescription.LEFT'|translate}}: {{ilog.ilogMeasurments.left.maxThickness|number}}
                            tr(:class="{'bg-warning': (ilog.optiMeasurments.right.centerThickness !== ilog.ilogMeasurments.right.centerThickness) || (ilog.optiMeasurments.left.centerThickness !== ilog.ilogMeasurments.left.centerThickness)}")
                                td.text-right {{'tasks.admin.compareilog.CENTERTHICK'|translate}}
                                td.text-right
                                    span(v-if="ilog.optiMeasurments.right.centerThickness === ''") {{'prescription.RIGHT'|translate}}: brak
                                    span(v-if="ilog.optiMeasurments.right.centerThickness !== ''") {{'prescription.RIGHT'|translate}}: {{ilog.optiMeasurments.right.centerThickness|number}}
                                    br
                                    |
                                    span(v-if="ilog.optiMeasurments.left.centerThickness === ''") {{'prescription.LEFT'|translate}}: brak
                                    span(v-if="ilog.optiMeasurments.left.centerThickness !== ''") {{'prescription.LEFT'|translate}}: {{ilog.optiMeasurments.left.centerThickness|number}}
                                td.text-right
                                    span(v-if="ilog.ilogMeasurments.right.centerThickness ===''") {{'prescription.RIGHT'|translate}}: brak
                                    span(v-if="ilog.ilogMeasurments.right.centerThickness !==''") {{'prescription.RIGHT'|translate}}: {{ilog.ilogMeasurments.right.centerThickness|number}}
                                    br
                                    |
                                    span(v-if="ilog.ilogMeasurments.left.centerThickness === ''") {{'prescription.LEFT'|translate}}: brak
                                    span(v-if="ilog.ilogMeasurments.left.centerThickness !== ''") {{'prescription.LEFT'|translate}}: {{ilog.ilogMeasurments.left.centerThickness|number}}
                            tr(:class="{'bg-warning': task.rightLens.baseCurve !== ilog.forced_curvature_right || task.leftLens.baseCurve !== ilog.forced_curvature_left}")
                                td.text-right
                                    small(style="display:block") {{'catalog.hoya.base_curve.BC'|translate}} /
                                    small {{'catalog.hoya.base_curve.PCV'|translate}}
                                td.text-right
                                    span {{'prescription.RIGHT'|translate}}: {{task.rightLens.baseCurve ? task.rightLens.baseCurve + ' dpt' : '-'}}
                                    br
                                    span {{'prescription.LEFT'|translate}}: {{task.leftLens.baseCurve ? task.leftLens.baseCurve + ' dpt' : '-'}}
                                td.text-right
                                    span {{'prescription.RIGHT'|translate}}: {{ilog.forced_curvature_right ? ilog.forced_curvature_right + ' dpt' : '-'}}
                                    br
                                    span {{'prescription.LEFT'|translate}}: {{ilog.forced_curvature_left ? ilog.forced_curvature_left + ' dpt' : '-'}}
                            tr
                                td.text-right {{'tasks.admin.compareilog.FCRV'|translate}}
                                td.text-right
                                    span(v-if="ilog.optiMeasurments.right.fcrv === '-'") {{'prescription.RIGHT'|translate}}: brak
                                    span(v-if="ilog.optiMeasurments.right.fcrv !== '-'") {{'prescription.RIGHT'|translate}}: ~ {{ilog.optiMeasurments.right.fcrv|number}}
                                    br
                                    |
                                    span(v-if="ilog.optiMeasurments.left.fcrv === '-'") {{'prescription.LEFT'|translate}}: brak
                                    span(v-if="ilog.optiMeasurments.left.fcrv !== '-'") {{'prescription.LEFT'|translate}}: ~ {{ilog.optiMeasurments.left.fcrv|number}}
                                td.text-right
                            tr
                                td.text-right {{'tasks.admin.compareilog.BC'|translate}}
                                td.text-right
                                td.text-right
                                    span(v-if="ilog.ilogMeasurments.right.bc === ''") {{'prescription.RIGHT'|translate}}: brak
                                    span(v-if="ilog.ilogMeasurments.right.bc !== ''") {{'prescription.RIGHT'|translate}}: {{ilog.ilogMeasurments.right.bc}}
                                    br
                                    |
                                    span(v-if="ilog.ilogMeasurments.left.bc === ''") {{'prescription.LEFT'|translate}}: brak
                                    span(v-if="ilog.ilogMeasurments.left.bc !== ''") {{'prescription.LEFT'|translate}}: {{ilog.ilogMeasurments.left.bc}}

                .col-xs-4.replace-to-col-xs-12(v-if="requiredHoyaOrder && !save_mode")
                    table.table.table-striped.table-responsive.middle
                        thead
                            tr
                                th.text-right
                                th.text-right Opti
                                th.text-right iLog
                        tbody
                            tr
                                td.text-bold.text-center(colspan="3") {{'tasks.admin.compareilog.ORDER'|translate}}
                            tr(:class="{'bg-warning': optiMets !== ilogMets}")
                                td.text-right METS?
                                td.text-right {{optiMets}}
                                td.text-right {{ilogMets}}
                            tr(style="border-top:0" v-if="progression || ilog.progression" :class="{'bg-warning': !progression_design_exceptions.includes(ilog.design_right) && (progression !== ilog.progression)}")
                                td.text-right
                                    span {{'tasks.admin.compareilog.PROGRESSION'|translate}}
                                    span.warn(style="margin-left:3px" v-if="progression_design_exceptions.includes(ilog.design_right)") *
                                td.text-right {{progression}}
                                td.text-right
                                    span(v-if="ilog.progression") {{ilog.progression}}
                                    span(v-else) brak danych
                            tr.text-right.warn(v-if="progression_design_exceptions.includes(ilog.design_right)")
                                td(style="border-top:0" colspan="3") {{'tasks.admin.compareilog.notify.warning.PROGRESSION_EXCEPTION'|translate}}
                        tbody(v-if="ilog.near_distance || ilog.measurment_methods || task.hoyaLens.construction_params")
                            tr(:class="{'bg-warning': ilog.near_distance !== measuredNearDistance}")
                                td.text-right {{'tasks.admin.compareilog.NEARDIST'|translate}}
                                td.text-right {{measuredNearDistance}} cm
                                td.text-right
                                    span(v-if="ilog.near_distance") {{ilog.near_distance}} cm
                                    span(v-else) brak danych
                            tr(:class="{'bg-warning': ilog.measurment_methods !== 'Pomiar foropterem'}")
                                td.text-right {{'tasks.admin.compareilog.MEASMETHOD'|translate}}
                                td.text-right Pomiar foropterem
                                td.text-right
                                    span(v-if="ilog.measurment_methods") {{ilog.measurment_methods}}
                                    span(v-else) brak danych
                        tbody
                            tr
                                td.text-bold.text-center(colspan="3") {{'tasks.admin.compareilog.LENS'|translate}}
                            tr(:class="{'bg-warning': task.hoyaLens.iLog_design !== ilog.design_right || task.hoyaLens.iLog_design !== ilog.design_left}")
                                td.text-right {{'tasks.admin.compareilog.DESIGN'|translate}}
                                td.text-right {{'prescription.RIGHT'|translate}}: {{task.hoyaLens.iLog_design}}
                                    br
                                    | {{'prescription.LEFT'|translate}}: {{task.hoyaLens.iLog_design}}
                                td.text-right {{'prescription.RIGHT'|translate}}: {{ilog.design_right}}
                                    br
                                    | {{'prescription.LEFT'|translate}}: {{ilog.design_left}}
                            tr(:class="{'bg-warning': task.hoyaLens.iLog_material !== ilog.material_right || task.hoyaLens.iLog_material !== ilog.material_left}")
                                td.text-right {{'tasks.admin.compareilog.MATERIAL'|translate}}
                                td.text-right {{'prescription.RIGHT'|translate}}: {{task.hoyaLens.iLog_material}}
                                    br
                                    | {{'prescription.LEFT'|translate}}: {{task.hoyaLens.iLog_material}}
                                td.text-right {{'prescription.RIGHT'|translate}}: {{ilog.material_right}}
                                    br
                                    | {{'prescription.LEFT'|translate}}: {{ilog.material_left}}
                            tr(:class="{'bg-warning': coatingWarning}")
                                td.text-right {{'tasks.admin.compareilog.COATING'|translate}}
                                td.text-right {{'prescription.RIGHT'|translate}}: {{coating}}
                                    br
                                    | {{'prescription.LEFT'|translate}}: {{coating}}
                                td.text-right {{'prescription.RIGHT'|translate}}: {{ilog.coating_right}}
                                    br
                                    | {{'prescription.LEFT'|translate}}: {{ilog.coating_left}}
                            tr(:class="{'bg-warning': ppf !== ilog.ppf_right}" v-if="ppf || ilog.ppf_right !== '-'")
                                td.text-right {{'tasks.admin.compareilog.PPF'|translate}}
                                td.text-right {{ppf}}
                                td.text-right {{ilog.ppf_right}}
                            tr(:class="{'bg-warning': color !== ilog.color_left}" v-if="color || ilog.color_left !== '-'")
                                td.text-right {{'tasks.admin.compareilog.COLOR'|translate}}
                                td.text-right {{color}}
                                td.text-right {{ilog.color_left}}
                            tr(:class="{'bg-warning': color_gradient !== ilog.gradation_color}" v-if="color_gradient || ilog.gradation_color")
                                td.text-right {{'catalog.lenses.properties.heading.gradient_type'|translate}}
                                td.text-right {{color_gradient}}
                                td.text-right {{ilog.gradation_color}}
                            tr(:class="{'bg-warning': (optiMinimalThicknessRight !== ilog.thickness_right) || (optiMinimalThicknessLeft !== ilog.thickness_left)}")
                                td.text-right {{'tasks.admin.compareilog.THICKNESS'|translate}}
                                td.text-right {{'prescription.RIGHT'|translate}}: {{optiMinimalThicknessRight|number(1)}}
                                    br
                                    | {{'prescription.LEFT'|translate}}: {{optiMinimalThicknessLeft|number(1)}}
                                td.text-right {{'prescription.RIGHT'|translate}}: {{ilog.thickness_right|number(1)}}
                                    br
                                    | {{'prescription.LEFT'|translate}}: {{ilog.thickness_left|number(1)}}
                            tr(:class="{'bg-warning': (task.rightLens.minFi !== ilog.diameter_right) || (task.leftLens.minFi !== ilog.diameter_left)}")
                                td.text-right {{'tasks.admin.compareilog.DIAMETER'|translate}}
                                td.text-right {{'prescription.RIGHT'|translate}}: {{task.rightLens.minFi}}
                                    br
                                    | {{'prescription.LEFT'|translate}}: {{task.leftLens.minFi}}
                                td.text-right {{'prescription.RIGHT'|translate}}: {{ilog.diameter_right}}
                                    br
                                    | {{'prescription.LEFT'|translate}}: {{ilog.diameter_left}}
                            tr
                                td.text-bold.text-center(colspan="3") {{'tasks.FRAME'|translate}}
                            tr(:class="{'bg-warning': rfwWarning}")
                                td.text-right {{'tasks.frame.WIDTH'|translate}} {{'tasks.admin.compareilog.RIGHT'|translate}}
                                td.text-right {{frameWidth|number}}
                                td.text-right {{ilog.frame_width_right|number}}
                            tr(:class="{'bg-warning': rfhWarning}")
                                td.text-right {{'tasks.frame.HEIGHT'|translate}} {{'tasks.admin.compareilog.RIGHT'|translate}}
                                td.text-right {{frameHeight|number}}
                                td.text-right {{ilog.frame_height_right|number}}
                            tr(:class="{'bg-warning': lfwWarning}")
                                td.text-right {{'tasks.frame.WIDTH'|translate}} {{'tasks.admin.compareilog.LEFT'|translate}}
                                td.text-right {{frameWidth|number}}
                                td.text-right {{ilog.frame_width_left|number}}
                            tr(:class="{'bg-warning': lfhWarning}")
                                td.text-right {{'tasks.frame.HEIGHT'|translate}} {{'tasks.admin.compareilog.LEFT'|translate}}
                                td.text-right {{frameHeight|number}}
                                td.text-right {{ilog.frame_height_left|number}}
                            tr(:class="{'bg-warning': dblWarning}")
                                td.text-right {{'tasks.frame.BRIDGE'|translate}}
                                td.text-right {{task.dbl|number}}
                                td.text-right {{ilog.dbl|number}}
                            tr(:class="{'bg-warning': true}"
                                v-if="ilog.decentrationR || ilog.decentrationL")
                                td.text-right {{'tasks.admin.compareilog.DECENTRATION'|translate}}
                                td
                                td.text-right
                                    span {{'prescription.RIGHT'|translate}}: {{ilog.decentrationR}}
                                    br
                                    |
                                    span {{'prescription.LEFT'|translate}}: {{ilog.decentrationL}}

                .col-xs-12.no-ilog(v-if="!task.manufacturerCustomId")
                    .alert.alert-warning.text-center(style="margin-bottom:0")
                        i.fa.fa-exclamation-circle.mr3
                        span {{'tasks.admin.compareilog.notify.NOILOG'|translate}}

                .col-xs-12.no-ilog(v-if="!task.required_ilog")
                    .alert.alert-warning.text-center(style="margin-bottom:0")
                        i.fa.fa-exclamation-circle.mr3
                        span {{'tasks.admin.compareilog.notify.NOREQUIREDILOG'|translate}}

                .col-xs-12.no-ilog(v-if="save_mode")
                    .alert.alert-warning.text-center(style="margin-bottom:0")
                        i.fa.fa-exclamation-circle.mr3
                        span {{'tasks.admin.compareilog.notify.SAVEMODE'|translate}}

        template(#footer)
            .row
                .col-xs-3.col-xs-offset-3.text-left
                    OptiButton(@click.native="$emit('closed')") {{'tasks.admin.compareilog.buttons.BACK'|translate}}
                        template(#icon)
                            i.fa.fa-arrow-left

                .col-xs-3.text-right
                    OptiButton(type="success" v-show="task.status === 2" :disabled="loading || forbiddenInputs"
                        @click.native="goConfirm") {{'tasks.admin.confirm.BTN'|translate}}
                        template(#icon)
                            i.fa.fa-check

                    OptiButton(type="info" v-show="task.status === 3" :disabled="loading || forbiddenInputs"
                        @click.native="goOrder") {{'tasks.admin.order.BTN'|translate}}
                        template(#icon)
                            i.fa.fa-truck

            OptiDimmer(:active="loading")
</template>

<script>
    import {API} from '@/js/app/vue/api'

    import OptiDialog from '@/js/app/vue/components/OptiDialog'
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiTaskDialogOptiHoyaCompare',
        components: {
            OptiDialog,
            OptiButton,
            OptiDimmer
        },
        props: {
            task: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                optiMinimalThicknessRight: '',
                optiMinimalThicknessLeft: '',
                optiMets: '',

                ilog: {},
                ilogMets: '',

                coating: '',
                ppf: '',
                color: '',
                color_gradient: '',

                progression_design_exceptions: ['EnRoute', 'EnRoute Pro'],
                //taka sama tablica jest w HoyaEDIFromTaskHelper::getProgressionLength
                progression: 0,

                exception_coatings: {},

                lfwWarning: false,
                lfhWarning: false,
                rfwWarning: false,
                rfhWarning: false,

                cvdWarning: false,
                paWarning: false,
                fffaWarning: false,

                dblWarning: false,

                coatingWarning: false,

                forbiddenInputs: false,

                frameHeight: '',
                frameWidth: '',

                rightBt: '',
                leftBt: '',

                save_mode: window.__env.hoya_save_mode,
                loading: true
            }
        },
        mounted() {
            if(this.requiredHoyaOrder && !this.save_mode) {
                API.post('task/hoya/order', {
                    task_id: this.task.id,
                    exception_coatings: true
                }).then(res => {
                    this.ilog = res.data

                    this.exception_coatings = res.data.exception_coatings
                }).then(() => {
                    this.getSection()

                    this.optiMinimalThicknessRight = this.checkThickness()
                    this.optiMinimalThicknessLeft = this.checkThickness()

                    this.ilogMets = this.checkMets(this.ilog.mets)
                    this.optiMets = this.checkMets(this.task.hoyaLens.lens_type === 'RX')

                    this.frameHeight = this.task.frame.height + this.task.heightAdditionBottom + this.task.heightAdditionTop
                    this.frameWidth = this.task.frame.width + this.task.widthNasal + this.task.widthTemporal

                    this.rightBt = this.task.rightEye.bt + this.task.heightAdditionBottom
                    this.leftBt = this.task.leftEye.bt + this.task.heightAdditionBottom

                    this.rfhWarning = this.checkFrame(this.frameHeight, this.ilog.frame_height_left)
                    this.rfwWarning = this.checkFrame(this.frameWidth, this.ilog.frame_width_left)
                    this.lfhWarning = this.checkFrame(this.frameHeight, this.ilog.frame_height_right)
                    this.lfwWarning = this.checkFrame(this.frameWidth, this.ilog.frame_width_right)

                    this.dblWarning = this.checkFrame(this.task.dbl, this.ilog.dbl)

                    this.forbiddenInputs = this.checkInputs(this.ilog.checked_grindery,this.ilog.patient_name,
                        this.ilog.patient_sex,this.ilog.patient_surname, this.ilog.date_of_birth,
                        this.ilog.checked_slab_off, this.ilog.prism_error, this.ilog.weight_compensation)

                    this.cvdWarning = this.checkParams(this.task.cvd, this.task.hoyaLens.cvd, this.task.hoyaLens.cvd_delta)
                    this.paWarning = this.checkParams(this.task.inclination, this.task.hoyaLens.pa, this.task.hoyaLens.pa_delta)
                    this.fffaWarning = this.checkParams(this.task.fffa, this.task.hoyaLens.fffa, this.task.hoyaLens.fffa_delta)

                    this.coatingWarning = this.checkCoating(this.coating, this.ilog.coating_right, this.coating, this.ilog.coating_left)
                }).finally(() => {
                    this.loading = false
                }).catch(() => {
                    this.$emit('closed')

                    this.$notify.error('tasks.admin.compareilog.notify.error.LOAD')
                })
            } else {
                this.loading = false
            }
        },
        methods: {
            getSection() {
                this.task.hoyaOptions.forEach(option => {
                    if((option.section.startsWith('coatings') || option.section.startsWith('mirror')) &&
                        !this.task.hoyaLens.name_long.includes(option.name_long)) {
                        this.coating = option.ilog_code.includes(',') ? option.ilog_code.split(',')[0] : option.ilog_code
                    } else if(option.section.startsWith('coloration')) {
                        this.color = option.name_iLog
                    } else if(option.section === 'gradient_type') {
                        this.color_gradient = option.name_iLog
                    } else if(option.section.startsWith('polarized') || option.section.startsWith('sensity')) {
                        this.ppf = option.name_iLog
                    } else if(option.section === 'progression_length') {
                        this.progression = parseInt(option.name_long)
                    }
                })
            },
            checkThickness() {
                let obj = {},
                    lens = this.task.hoyaLens

                if((!lens.minimal_thickness && lens.lens_type === 'RX') ||
                    (!lens.minimal_thickness && lens.lens_type === 'ST') ||
                    (!lens.minimal_thickness && lens.lens_type === 'ST/RX')) {
                    switch(this.task.frame.type) {
                        case 'Nylon':
                        case 'TwoPoint':
                            obj = 2
                            break

                        case 'Plastic':
                        case 'Metal':
                            obj = 1.1
                            break

                        default:
                            obj = 0
                    }
                } else if((lens.minimal_thickness && lens.lens_type === 'RX') ||
                    (lens.minimal_thickness && lens.lens_type === 'ST') ||
                    (lens.minimal_thickness && lens.lens_type === 'ST/RX')) {
                    obj = lens.minimal_thickness
                } else {
                    obj = 'brak'
                }

                return obj
            },
            checkInputs(q, w, e, r, t, y, u, i) {
                return q || w || e || r || t || y || u || i
            },
            checkMets(check) {
                return check ? 'Wybrano METS' : 'Nie wybrano METS'
            },
            checkFrame(opti, ilog) {
                return !((opti >= ilog - 0.3) && (opti <= ilog + 0.3))
            },
            checkParams(opti, ilog, x) {
                return !((parseFloat(opti) >= parseFloat(ilog) - parseFloat(x)) && (parseFloat(opti) <= parseFloat(ilog) + parseFloat(x)))
            },
            checkCoating(optiR, iLogR, optiL, iLogL) {
                let is_exception = false

                _.forEach(this.exception_coatings, (exception_opti, exception_iLog) => {
                    if(optiR === exception_opti && iLogR === exception_iLog && optiL === exception_opti && iLogL === exception_iLog) {
                        is_exception = true
                    }
                })

                if(is_exception) {
                    return false
                }

                if((!optiR || optiR === '-') && (!optiL || optiL === '-') && (!iLogR || iLogR === '-') && (!iLogL || iLogL === '-')) {
                    return false
                }

                return (optiR !== iLogR) || (optiL !== iLogL)
            },
            parseTable() {
                return this.$refs.tablesCompare.outerHTML
                    .replace('col-xs-4 replace-to-col-xs-12', 'col-xs-12')
                    .replace('replace-to-smaller-table', 'compare-smaller-table')
                    .replaceAll('col-xs-4', 'col-lg-6')
            },
            goConfirm() {
                if(this.checkIfForbitten()) {
                    return false
                }

                this.loading = true

                API.post('task/go-confirm', {
                    task_id: this.task.id,
                    html_table: this.parseTable(),
                    ilog: this.ilog
                }).then(() => {
                    this.$emit('confirmed')

                    this.$notify.success('tasks.admin.confirm.notify.CONFIRMED')
                }).finally(() => {
                    this.$emit('closed')

                    this.loading = false
                }).catch(() => {
                    this.$notify.error('tasks.admin.confirm.notify.error.CONFIRMED')
                })
            },
            goOrder() {
                if(this.checkIfForbitten()) {
                    return false
                }

                this.loading = true

                API.post('task/go-order', {
                    task_id: this.task.id,
                    html_table: this.parseTable(),
                    ilog: this.ilog
                }).then(() => {
                    this.$emit('ordered')

                    this.$notify.success('tasks.admin.order.notify.ORDERED')
                }).finally(() => {
                    this.$emit('closed')

                    this.loading = false
                }).catch(() => {
                    this.$notify.error('tasks.admin.order.notify.error.ORDERED')
                })
            },
            checkIfForbitten() { // TODO: to jest błąd Hoya, bo nie można do tych soczewek wymuszać bc, usunąć jak to ogarną czyli nigdy
                let forbidden = ['Indoor', 'SupeReader', 'Office Smart', 'WorkSmart']

                if(this.ilog.design_right && this.ilog.forced_curvature) {
                    let is_forbidden = false
                    forbidden.forEach(f => {
                        if(this.ilog.design_right.includes(f)) {
                            is_forbidden = true
                        }
                    })

                    if(is_forbidden) {
                        this.$notify.warning('tasks.admin.compareilog.notify.warning.FORCE_BC_ON_FORBIDDEN_LENSES')

                        return true
                    }
                }

                return false
            }
        },
        computed: {
            requiredHoyaOrder() {
                //to samo TaskConfirmAndOrderController.php:539
                return this.task.manufacturerCustomId && this.task.required_ilog
            },
            measuredNearDistance() {
                if(this.task.manufacturerParam1 && this.task.manufacturerParam1.split('-').length - 1 === 4) {
                    return parseInt(this.task.manufacturerParam1.split('-')[0].substring(2, 4))
                }

                return this.task.hoyaLens.construction_params ? 40 : 0
            }
        }
    }
</script>