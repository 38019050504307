<template lang="pug">
    .row(style="padding-top:10px;border-top:3px solid #dcdcdc"
        v-show="(departmentTaskId || !status || status >= 3) && (images.length || loading)")
        .col-sm-4(v-for="image in images")
            a(:href="'upload/task/measurement/' + image" target="_blank" :title="'tasks.ipad.printscreen.TITLE'|translate")
                img(style="width:100%" :src="'upload/task/measurement/' + image")

        OptiDimmer(:active="loading")
</template>

<script>
    import {API} from '@/js/app/vue/api'

    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiTaskDetailsIpadVisuRealPrtScr',
        components: {
            OptiDimmer
        },
        props: {
            taskId: {
                type: Number,
                required: true
            },
            departmentTaskId: {
                type: Number | String
            },
            status: {
                type: Number,
                required: true
            },

            taskDuplicate: {
                type: Boolean,
                required: true
            },
            taskService: {
                type: Boolean,
                required: true
            },
        },
        data() {
            return {
                images: [],

                loading: false
            }
        },
        mounted() {
            if(this.taskDuplicate || this.taskService || !this.status || this.status >= 3) {
                this.loading = true

                API.get('task/measurement/' + (this.taskDuplicate || this.taskService ? this.departmentTaskId : this.taskId), API.id(Math.random())).then(res => {
                    this.images = res.data
                }).finally(() => {
                    this.loading = false
                }).catch(() => {
                    this.$notify.error('tasks.ipad.printscreen.notify.error.LOAD')
                })
            }
        }
    }
</script>
