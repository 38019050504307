<template lang="pug">
    .col-xs-12
        #btn-group-patient-detail.row(style="margin-bottom:10px" v-show="!block")
            .col-md-4.text-left.text-center-sm.text-center-xs.text-left-lg
                OptiButton(type="danger" v-show="existsLenses"
                    text="tasks.lenses.confirm.delete" @confirmed="$emit('remove-lenses')"
                    ) {{'tasks.lenses.confirm.delete.BTN'|translate}}
                    template(#icon)
                        i.fa.fa-remove

            .col-md-4.text-center
                OptiButton(type="info" v-show="task.assemblyId && !taskAssembly.blockBothLens(task.assemblyId)"
                    @click.native="$emit('go-to-page', 'by-benefits')")
                    span {{'tasks.benefits.HEADING'|translate}}
                    template(#icon)
                        i.fa.fa-share-alt

            .col-md-4.text-right.text-center-sm.text-center-xs.text-right-lg
                OptiButton(type="info" v-show="task.assemblyId && !taskAssembly.blockBothLens(task.assemblyId)"
                    @click.native="$emit('go-to-page', 'catalog')")
                    span {{'tasks.pack.action.CATALOG'|translate}}
                    template(#icon)
                        i.fa.fa-sitemap

        .table-responsive
            table.table.table-striped.middle
                tbody
                    tr(v-show="task.pack.pack_name1 || task[field.right].partName || task[field.left].partName")
                        th.text-center(colspan="3") {{'tasks.lenses.SELECTEDLENSES'|translate}}
                    tr.text-center
                        th
                        td(style="width:40%")
                            b {{'tasks.lenses.RIGHT'|translate}}
                        td(style="width:40%")
                            b {{'tasks.lenses.LEFT'|translate}}
                    tr(v-show="task.pack.pack_name1 && task[field.right].partName && task[field.left].partName")
                        th.text-right {{'tasks.lenses.ID'|translate}}
                        td.text-center(colspan="2")
                            a.link.mr3(:title="'default.title.LENSES'|translate"
                                :href="$state.href('app.packs.list', {search: ['id:=:' + task.packId]})" target="_blank") {{task.pack.pack_name1}}
                            span(v-show="task.rLensPriceGroup.deliveryTime") {{task.rLensPriceGroup.deliveryTime|day}}
                    tr(v-show="(task[field.right].partName || task[field.left].partName) && (task[field.right].partName !== task[field.left].partName)")
                        th.text-right {{'tasks.lenses.NAME'|translate}}
                        td.text-center
                            small
                                a.link(:title="'default.title.LENSES'|translate"
                                    v-if="task['hoya_lens_category_id' + (isCompare ? '_2' : '')] || task.rLensPriceGroup.categoryId"
                                    :href="$state.href('app.catalog.category', {id: task['hoya_lens_category_id' + (isCompare ? '_2' : '')] ? task['hoya_lens_category_id' + (isCompare ? '_2' : '')] : task.rLensPriceGroup.categoryId, isOffer: isOffer ? 1 : 0, taskId: task.id})"
                                    target="_blank") {{task[field.right].partName}}
                                span(v-else) {{task[field.right].partName}}
                        td.text-center
                            small
                                a.link(:title="'default.title.LENSES'|translate"
                                    v-if="task['hoya_lens_category_id' + (isCompare ? '_2' : '')] || task.lLensPriceGroup.categoryId"
                                    :href="$state.href('app.catalog.category', {id: task['hoya_lens_category_id' + (isCompare ? '_2' : '')] ? task['hoya_lens_category_id' + (isCompare ? '_2' : '')] : task.lLensPriceGroup.categoryId, isOffer: isOffer ? 1 : 0, taskId: task.id})"
                                    target="_blank") {{task[field.left].partName}}
                                span(v-else) {{task[field.left].partName}}
                    tr(v-show="(task[field.right].partName || task[field.left].partName) && (task[field.right].partName === task[field.left].partName)")
                        th.text-right {{'tasks.lenses.NAME'|translate}}
                        td.text-center(colspan="2")
                            a.link(:title="'default.title.LENSES'|translate"
                                v-if="task['hoya_lens_category_id' + (isCompare ? '_2' : '')] || task.rLensPriceGroup.categoryId"
                                :href="$state.href('app.catalog.category', {id: task['hoya_lens_category_id' + (isCompare ? '_2' : '')] ? task['hoya_lens_category_id' + (isCompare ? '_2' : '')] : task.rLensPriceGroup.categoryId, isOffer: isOffer ? 1 : 0, taskId: task.id})"
                                target="_blank") {{task[field.right].partName}}
                            span(v-else) {{task[field.right].partName}}

                    tr(v-show="task[field.pictograms].length")
                        td.text-center(style="padding:0" colspan="3")
                            OptiPictograms(:pack-details="task[field.pictograms]")
                    tr(v-show="task[field.right].type || task[field.left].type")
                        th.text-right {{'tasks.lenses.TYPE'|translate}}
                        td.text-center(colspan="2") {{task[field.right].typeName|translate}}
                    tr
                        th.text-right
                            small {{'tasks.MINFI'|translate}}
                        td
                            OptiNumber#minFiR.text-center(style="padding:6px 5px;width:110px;margin:0 auto"
                                :header="$filters.translate('tasks.MINFI') + ' - ' + $filters.translate('prescription.RIGHT')"
                                :disabled="!!(block || task.packId)" :pending="pending"
                                :value="task[field.right].minFi" @confirmed="v => update('right', 'minFi', v)"
                                ) {{task[field.right].minFi|mm}}
                        td
                            OptiNumber#minFiR.text-center(style="padding:6px 5px;width:110px;margin:0 auto"
                                :header="$filters.translate('tasks.MINFI') + ' - ' + $filters.translate('prescription.LEFT')"
                                :disabled="!!(block || task.packId)" :pending="pending"
                                :value="task[field.left].minFi" @confirmed="v => update('left', 'minFi', v)"
                                ) {{task[field.left].minFi|mm}}
                    tr(v-show="(task[field.right].price || task[field.left].price) && !task.packId")
                        th.text-right {{'tasks.lenses.PRICE'|translate}}
                        td.text-center
                            small(v-show="task[field.right].price") {{task[field.right].price|currency}}
                        td.text-center
                            small(v-show="task[field.left].price") {{task[field.left].price|currency}}
                    tr(v-show="task.packId")
                        th.text-right {{'tasks.lenses.PRICE'|translate}}
                        td.text-center(colspan="2") {{task[field.right].price|currency}}
                    //tr(v-show="task['hoyaLens' + (isCompare ? '2' : '')].base_curve")
                    //    th.text-right
                    //        small {{task['hoyaLens' + (isCompare ? '2' : '')].base_curve_name|translate}}
                    //    td.text-center
                    //        OptiNumber#baseCurveRight.text-center(style="padding:6px 5px;width:110px;margin:0 auto"
                    //            :header="task['hoyaLens' + (isCompare ? '2' : '')].base_curve_name|translate"
                    //            :disabled="block" :pending="pending"
                    //            :value="task[field.right].baseCurve" @confirmed="v => update('right', 'baseCurve', v)"
                    //            ) {{task[field.right].baseCurve ? task[field.right].baseCurve : '-'}}
                    //    td.text-center
                    //        OptiNumber#baseCurveLeft.text-center(style="padding:6px 5px;width:110px;margin:0 auto"
                    //            :header="task['hoyaLens' + (isCompare ? '2' : '')].base_curve_name|translate"
                    //            :disabled="block" :pending="pending"
                    //            :value="task[field.left].baseCurve" @confirmed="v => update('left', 'baseCurve', v)"
                    //            ) {{task[field.left].baseCurve ? task[field.left].baseCurve : '-'}}
</template>

<script>
    import {TaskAssembly} from '@/js/app/vue/components/Tasks/TaskAssembly'

    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiPictograms from '@/js/app/vue/components/Pictograms/OptiPictograms'
    import OptiNumber from '@/js/app/vue/components/Inputs/OptiNumber'

    export default {
        name: 'OptiTaskDetailsLensesMain',
        components: {
            OptiPictograms,
            OptiButton,
            OptiNumber
        },
        props: {
            task: {
                type: Object,
                required: true
            },
            isOffer: {
                type: Boolean,
                required: true
            },
            isCompare: {
                type: Boolean,
                required: true
            },

            block: {
                type: Boolean,
                required: true
            },
            pending: {
                type: Boolean,
                required: true
            }
        },
        data() {
            return {
                taskAssembly: TaskAssembly,
                field: {
                    right: 'rightLens' + (this.isCompare ? '2' : ''),
                    left: 'leftLens' + (this.isCompare ? '2' : ''),
                    pictograms: 'pictograms' + (this.isCompare ? '2' : '')
                }
            }
        },
        methods: {
            update(side, field, value) {
                this.task[side + 'Lens' + (this.isCompare ? '2' : '')][field] = value

                if(side === 'right' && !this.task[this.field.left][field]) {
                    this.task[this.field.left][field] = this.task[this.field.right][field]
                }

                this.$emit('lenses-was-updated')
            }
        },
        computed: {
            existsLenses() {
                return (this.task[this.field.right].partName && this.task[this.field.right].partName !== 'soczewka') ||
                    (this.task[this.field.left].partName && this.task[this.field.left].partName !== 'soczewka')
            }
        }
    }
</script>
