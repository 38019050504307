<template lang="pug">
    .panel.panel-primary(style="position:relative")
        .panel-heading
            i.fa.fa-navicon.mr3
            span {{'settings.card.GENERAL'|translate}}

        .panel-body
            .form-horizontal
                .form-group
                    label.col-xs-12(for="department") {{'settings.general.form.CURRENTDEPARTMENT'|translate}}
                    .col-xs-12
                        OptiSelectDepartment#department(
                            :department-id="general.departmentId"
                            :simple="true"

                            @selected="d => general.departmentId = d.id")
                .form-group
                    label.col-sm-4.control-label {{'settings.general.form.COLOR'|translate}}
                    .col-sm-8
                        .btn-group(:class="{open: open_color}" ref="dropdownButton")
                            button.btn.btn-default.dropdown-toggle(:class="'btn-user-color-' + general.color"
                                @click="open_color = !open_color")
                                span.mr3 {{'settings.general.form.CHOOSE'|translate}}
                                span.caret
                            ul.dropdown-menu.user-color-selection
                                li(v-for="i in colors" :class="'user-color-' + i")
                                    a(style="cursor:pointer" @click="setColor(i)") {{i}}
                .form-group
                    label.col-sm-4.control-label(for="dark" style="padding-top:2px"
                        ) {{'settings.general.form.DARKTHEME'|translate}}
                    .col-sm-8
                        label.switch.switch-lg
                            input#dark(type="checkbox" v-model="general.dark")
                            span

                .form-group
                    label.col-sm-4.control-label(for="lang") {{'settings.general.form.LANGUAGE'|translate}}
                    .col-sm-8
                        select#lang.form-control(name="lang" v-model="general.lang" disabled)
                            option(v-for="language in languages" :value="language.id") {{language.name|translate}}

        .panel-footer.text-right
            OptiButtonSave(:disabled="!changed" @click.native="save")

        OptiDimmer(:active="loading")
</template>

<script>
    import {API} from '@/js/app/vue/api'

    import OptiSelectDepartment from '@/js/app/vue/components/Select/OptiSelectDepartment'
    import OptiButtonSave from '@/js/app/vue/components/Button/OptiButtonSave'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiSettingsGeneralMain',
        components: {
            OptiSelectDepartment,
            OptiButtonSave,
            OptiDimmer,
        },
        data() {
            return {
                general: {
                    departmentId: this.$uac.user.activeDepartmentId,
                    lang: this.$uac.user.lang,
                    color: this.$uac.user.color,
                    dark: !!this.$uac.user.dark
                },

                colors: _.range(0, 23 + 1),
                languages: [{
                    id: 'pl',
                    name: 'default.language.list.POLISH'
                }, {
                    id: 'en',
                    name: 'default.language.list.ENGLISH'
                }],

                open_color: false,
                loading: false
            }
        },
        methods: {
            setColor(i) {
                this.general.color = i

                this.open_color = false
            },
            save() {
                this.loading = true

                API.post('settings/save', this.general).then(res => {
                    if(res.data.user_id) {
                        this.$notify.success('settings.general.notify.SAVE')

                        this.$uac.relogin(res.data.user_id)
                    } else {
                        this.$notify.error('settings.general.notify.error.SAVE')
                    }
                }).finally(() => {
                    this.loading = false
                }).catch(() => {
                    this.$notify.error('settings.general.notify.error.SAVE')
                })
            }
        },
        computed: {
            changed() {
                let current = {
                    departmentId: this.$uac.user.activeDepartmentId,
                    lang: this.$uac.user.lang,
                    color: this.$uac.user.color,
                    dark: !!this.$uac.user.dark
                }

                return current.departmentId !== this.general.departmentId || current.lang !== this.general.lang ||
                    current.color !== this.general.color || current.dark !== this.general.dark
            }
        }
    }
</script>