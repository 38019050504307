<template lang="pug">
    .panel.panel-transparent
        .panel-heading
            i.fa.fa-retweet.text-muted.mr3
            span.text-muted {{'transfers.breadcrumb.TRANSFERS'|translate}}

        .action-div(style="text-align:left;position:relative")
            OptiTransfersLock(v-if="lock"
                :alerts="lock.alerts"
                :toggle="toggle")

            .row.text-center(:class="{'is-central': is_central_department}")
                .col-xs-12
                    div(style="display:flex;justify-content:space-around")
                        .padding-button(v-if="is_central_department")
                            OptiButton(@click.native="$state.go('app.warehouse.transfer.lock')"
                                ) {{'sidebar.TRANSFERS_LOCK'|translate}}
                                template(#icon)
                                    i.fa.fa-ban

                        .padding-button(v-else)
                            OptiButton(@click.native="toggle = !toggle"
                                ) {{'transfers.lock.toggle.' + (toggle ? 'HIDE' : 'SHOW')|translate}}
                                template(#icon)
                                    i.fa(:class="{'fa-angle-double-up' : toggle, 'fa-angle-double-down': !toggle}")

                        .padding-button(v-if="!is_central_department")
                            OptiButtonWikipedia(url="planowanie_sprzedazy:layout#przeniesienie")

                        .padding-button
                            OptiButton(type="default" @click.native="$state.go('app.warehouse.transfer.create')"
                                ) {{'transfers.table.action.NEW'|translate}}
                                template(#icon)
                                    i.fa.fa-plus

            OptiDimmer(:active="loading")
</template>

<script>
    import {API} from '@/js/app/vue/api'

    import OptiTransfersLock from '@/js/app/vue/components/Warehouse/Transfers/Lock/OptiTransfersLock'
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiButtonWikipedia from '@/js/app/vue/components/Button/OptiButtonWikipedia'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiTransferDashboard',
        components: {
            OptiTransfersLock,
            OptiButton,
            OptiButtonWikipedia,
            OptiDimmer,
        },
        data() {
            return {
                lock: null,

                is_central_department: !this.$uac.user.departmentType,
                toggle: false,
                loading: true
            }
        },
        mounted() {
            if(!this.is_central_department) {
                API.get('transfer/lock/' + this.$uac.user.activeDepartmentId).then(res => {
                    this.lock = res.data
                }).finally(() => {
                    this.loading = false
                }).catch(() => {
                    this.$notify.error('transfers.lock.notify.error.LOCK')
                })
            } else {
                this.loading = false
            }
        }
    }
</script>

<style lang="less" scoped>
    .padding-button {
        margin-top: 10px;
    }

    .is-central .padding-button {
        margin-bottom: 10px;
    }
</style>