angular.module('eOpti.app.warehouse.products').config(['$stateProvider', function($stateProvider) {
    $stateProvider
        .state('app.warehouse.tickets', {
            url: '/tickets',
            abstract: true,
            template: '<ui-view/>'
        })
        .state('app.warehouse.tickets.table', {
            url: '/:page/:search',
            params: {
                page: '1',
                pageSize: '10',
                sortBy: 'id',
                sortOrder: 'desc',
                search: {
                    array: true
                },
                columns: {
                    array: true
                }
            },
            templateUrl:  'app/views/warehouse/ticketsTable.html',
            controller: ['$scope', 'breadcrumb', function($scope, breadcrumb) {
                breadcrumb.data = [{
                    name: 'ticket.breadcrumb.WAREHOUSE'
                }, {
                    functions: 'ticketsList',
                    args: [null]
                }]
            }]
        })

        .state('app.warehouse.ticket', {
            url: '/ticket',
            abstract: true,
            template: '<ui-view/>'
        })
        .state('app.warehouse.ticket.add', {
            url: '/add',
            template: '<opti-tickets-add></opti-tickets-add>',
            controller: ['$scope', 'breadcrumb', function($scope, breadcrumb) {
                breadcrumb.data = [{
                    name: 'ticket.breadcrumb.WAREHOUSE'
                }, {
                    functions: 'ticketsList',
                    args: ['state']
                }, {
                    name: 'ticket.breadcrumb.ADD'
                }];
            }]
        })
        .state('app.warehouse.ticket.details', {
            url: '/details/{id:[0-9]+}',
            template: '<opti-tickets-details></opti-tickets-details>',
            controller: ['$scope', '$state', 'breadcrumb', function($scope, $state, breadcrumb) {
                breadcrumb.data = [{
                    name: 'ticket.breadcrumb.WAREHOUSE'
                }, {
                    functions: 'ticketsList',
                    args: ['state']
                }, {
                    name: 'ticket.breadcrumb.TICKETS',
                    id: $state.params.id
                }]
            }]
        })
        .state('app.warehouse.ticket.print', {
            url: '/print',
            template: '<opti-tickets-inventory-print></opti-tickets-inventory-print>',
            controller: ['$scope', 'breadcrumb', function($scope, breadcrumb) {
                breadcrumb.data = [{
                    name: 'ticket.breadcrumb.WAREHOUSE'
                }, {
                    functions: 'ticketsList',
                    args: ['state']
                }, {
                    name: 'ticket.breadcrumb.PRINT'
                }]
            }]
        })
}])